import cn from 'classnames';
import { SyntheticEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useIsScrolledToBottom from '../../hooks/useIsScrolledToBottom';
import { selectUser } from '../../state/user/user.slice';
import Icons from '../Icons/Icons';
import {
  selectIsNotificationsOpen,
  toggleIsNotificationsOpen,
} from '../../state/widgets/widgets.slice';
import {
  NOTIFICATIONS_SCROLL_CONTAINER,
  NO_NEW_ACTIVITY,
  NOTIFICATIONS,
  OVERFLOW_GRADIENT,
  NOTIFICATIONS_TITLE,
} from '../../utils/constants';
import NotificationCards from '../NotificationCards/NotificationCards';
import MarkAllAsReadOrUnreadButton from '../MarkAllAsReadOrUnreadButton/MarkAllAsReadOrUnreadButton';
import MobileDrawer from '../MobileDrawer/MobileDrawer';

export const MOBILE_NOTIFICATIONS_MASKING_CONTAINER =
  'mobile-notifications-masking-container';

/**
 * Mobile notifications drawer
 *
 * @see DesktopNotificationsDropdown for desktop notifications
 */
const MobileNotificationsDrawer = () => {
  const notificationsContainerRef = useRef<HTMLOListElement>(null);
  const dispatch = useAppDispatch();
  const isNotificationsOpen = useAppSelector(selectIsNotificationsOpen);
  const { unreadNotifications, readNotifications } = useAppSelector(selectUser);
  const isScrolledToBottom = useIsScrolledToBottom(
    NOTIFICATIONS_SCROLL_CONTAINER
  );

  // close when click on masking div
  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (
      (e.target as HTMLElement).id === MOBILE_NOTIFICATIONS_MASKING_CONTAINER
    ) {
      dispatch(toggleIsNotificationsOpen(false));
      notificationsContainerRef.current?.scrollTo(0, 0);
    }
  };

  const hasNotifications = !!(
    unreadNotifications.length || readNotifications.length
  );

  return (
    <MobileDrawer
      {...{
        isOpen: isNotificationsOpen,
        containerID: MOBILE_NOTIFICATIONS_MASKING_CONTAINER,
        ariaLabelledBy: NOTIFICATIONS_TITLE,
        handleClose,
      }}
    >
      <>
        <div className='mx-auto mb-6 flex w-full max-w-screen-sm flex-wrap items-center justify-between px-4'>
          <h1 className='mr-4' id={NOTIFICATIONS_TITLE}>
            {NOTIFICATIONS}
          </h1>
          {/* Mark all as read or unread button */}
          {hasNotifications && <MarkAllAsReadOrUnreadButton />}
        </div>
        <ol
          ref={notificationsContainerRef}
          className={cn(
            'thin-scrollbar mx-auto flex w-full max-w-screen-sm grow flex-col gap-4 overflow-y-scroll px-4 pb-4',
            {
              [OVERFLOW_GRADIENT]: hasNotifications && !isScrolledToBottom,
            }
          )}
          id={NOTIFICATIONS_SCROLL_CONTAINER}
        >
          {hasNotifications ? (
            <NotificationCards
              {...{ unreadNotifications, readNotifications }}
            />
          ) : (
            <li className='p-4'>
              <Icons.NoActivity className='mx-auto mb-8 max-w-screen-sm' />
              <p className='text-center text-2xl font-medium text-brand-grey'>
                {NO_NEW_ACTIVITY}
              </p>
            </li>
          )}
        </ol>
      </>
    </MobileDrawer>
  );
};

export default MobileNotificationsDrawer;
