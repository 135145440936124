import { FormEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  TAGS_SEARCH_INPUT,
  SEARCH_FOR_TAGS,
  MEDIA_QUERIES,
  EVENTS,
  FORM,
} from '../../../utils/constants';
import TagSuggestions, {
  NO_TAGS_FOUND,
} from '../../TagSuggestions/TagSuggestions';

interface ControlsProps {
  handleCloseScreenTakeover: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  toggleIsSelected: (tag: string) => void;
  unselectedTags: string[];
}

/**
 * Form controls and Tag suggestions for `FilterByTagsTakeover`
 */
const Controls = ({
  handleCloseScreenTakeover,
  inputValue,
  setInputValue,
  toggleIsSelected,
  unselectedTags,
}: ControlsProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const [isTagSuggestionsOpen, setIsTagSuggestionsOpen] = useState(false);

  useEffect(() => {
    //if mobile, short circuit
    if (!isDesktop) return;
    inputRef.current?.focus();
  }, [isDesktop]);

  /**
   * Set `isTagSuggestionsOpen`
   */
  useEffect(() => {
    if (
      inputValue.length >= 2 &&
      document.activeElement === inputRef.current &&
      !isTagSuggestionsOpen
    )
      setIsTagSuggestionsOpen(true);
    else if (inputValue.length < 2 && isTagSuggestionsOpen)
      setIsTagSuggestionsOpen(false);
  }, [inputValue, isTagSuggestionsOpen]);

  /**
   * Handle blur
   */
  useEffect(() => {
    const handleBlur = (e: MouseEvent) => {
      //if input is blurred, hide suggestions
      if (!(e.target as HTMLElement).closest(FORM)) {
        setInputValue('');
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [handleCloseScreenTakeover, setInputValue]);

  /**
   * Handle suggestion click
   */
  const handleSuggestionClick = (e: SyntheticEvent) => {
    const target = (e.target as HTMLElement).closest('[data-value]');
    if (!target) return;

    setInputValue('');

    const { value } = (target as HTMLElement).dataset;

    //if `No tags found` clicked, return early
    if (value === NO_TAGS_FOUND) return;

    value && toggleIsSelected(value);
  };

  /**
   * Enter on tag suggestion handler
   */
  const handlePressEnterOnSuggestion = (selectedTag: string) => {
    setInputValue('');

    if (selectedTag === NO_TAGS_FOUND) return;

    toggleIsSelected(selectedTag);
  };

  /**
   * Handle submit
   */
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    //make inputValue lowercase in case user has entered input with capitlized letter(s)
    const lowerCasedInputValue = inputValue.toLowerCase();
    if (unselectedTags.includes(lowerCasedInputValue)) {
      setInputValue('');
      toggleIsSelected(lowerCasedInputValue);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='relative' name={SEARCH_FOR_TAGS}>
      <input
        autoComplete='off'
        autoCapitalize='none'
        id={TAGS_SEARCH_INPUT}
        ref={inputRef}
        placeholder={SEARCH_FOR_TAGS}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
        className='mb-4 w-full rounded-3xl border border-brand-grey bg-brand-tint-grey-1 px-8 py-2'
      />
      {isTagSuggestionsOpen && (
        <TagSuggestions
          inputValue={inputValue}
          handleSuggestionClick={handleSuggestionClick}
          handlePressEnterOnSuggestion={handlePressEnterOnSuggestion}
          tags={unselectedTags}
          inputRef={inputRef}
        />
      )}
    </form>
  );
};

export default Controls;
