import { Action } from '@remix-run/router';
import { useLayoutEffect, useRef } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { DEBOUNCE_TIMEOUT, EVENTS } from '../utils/constants';
import debounce from '../utils/debounce';

/**
 * If navigation type is `PUSH`, scroll to top;
 * else restore scroll position
 */
const useScrollRestoration = () => {
  const navigationType = useNavigationType();
  const { pathname } = useLocation();
  const scrollPositions = useRef<Record<string, number>>({});

  useLayoutEffect(() => {
    const debouncedTrackScrollPosition = debounce(() => {
      scrollPositions.current[pathname] = window.scrollY;
    }, DEBOUNCE_TIMEOUT);

    window.addEventListener(EVENTS.SCROLL, debouncedTrackScrollPosition);

    let timer: NodeJS.Timeout;

    if ([Action.Push, Action.Replace].includes(navigationType)) {
      window.scrollTo({ top: 0 });
    } else {
      // Delay scroll restoration using `setTimeout` to guarantee its execution after any asynchronous browser operations, ensuring consistent behavior.
      timer = setTimeout(() => {
        window.scrollTo({ top: scrollPositions.current[pathname] || 0 });
      }, 0);
    }

    return () => {
      debouncedTrackScrollPosition.cancel();
      window.removeEventListener(EVENTS.SCROLL, debouncedTrackScrollPosition);
      clearTimeout(timer);
    };
  }, [navigationType, pathname]);
};

export default useScrollRestoration;
