import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  addReadNotifications,
  removeReadNotifications,
  selectReadNotificationsInFlight,
  selectUnreadNotificationsInFlight,
} from '../../state/user/user.slice';
import { INotification } from '../../types/user.types';
import { MARK_AS_READ, MARK_AS_UNREAD } from '../../utils/constants';
import getFormattedDateTimeStr from '../../utils/getFormattedDateTimeStr';
import getSeverityIconAndClassNames from '../../utils/getSeverityIconAndClassNames';

export interface NotificationCardProps extends INotification {
  isRead: boolean;
  _id: string;
}
/**
 * NotificationCard component
 */
const NotificationCard = ({
  title,
  message,
  severity,
  createdAt,
  isRead,
  _id,
}: NotificationCardProps) => {
  const dispatch = useAppDispatch();
  const readNotificationsInFlight = useAppSelector(
    selectReadNotificationsInFlight
  );
  const unreadNotificationsInFlight = useAppSelector(
    selectUnreadNotificationsInFlight
  );

  const isInFlight =
    !!readNotificationsInFlight[_id] || !!unreadNotificationsInFlight[_id];

  const {
    backgroundClassNames,
    Icon,
    lightClassNames,
    darkClassNames,
    prettySeverity,
  } = getSeverityIconAndClassNames(severity);

  // update the notification as read
  const markAsRead = () => {
    dispatch(addReadNotifications([_id]));
  };

  // update the notification as unread
  const markAsUnread = () => {
    dispatch(removeReadNotifications([_id]));
  };

  return (
    <li
      className={cn('rounded-lg p-4 shadow-md transition-colors', {
        [`${backgroundClassNames} text-brand-white`]: !isRead && !isInFlight,
        'bg-brand-grey text-brand-black': isInFlight,
        'bg-brand-white text-brand-black': isRead && !isInFlight,
      })}
    >
      <div className='mb-2 flex items-center justify-between'>
        {/* icon and title */}
        <div className='mr-4 line-clamp-1 flex items-center'>
          <div className='relative mr-1.5'>
            {/* dark icon */}
            <Icon
              className={cn('transition-opacity', {
                [`opacity-100 ${darkClassNames}`]: isRead || isInFlight,
                [`opacity-0 ${darkClassNames}`]: !isRead && !isInFlight,
              })}
            />
            {/* light icon */}
            <Icon
              className={cn('absolute left-0 top-0 transition-opacity', {
                [`opacity-100 ${lightClassNames}`]: !isRead && !isInFlight,
                [`opacity-0 ${lightClassNames}`]: isRead || isInFlight,
              })}
            />
          </div>
          <p className='mr-2 line-clamp-1 text-sm font-semibold'>
            {prettySeverity.toUpperCase()}
          </p>
        </div>
        {/* mark as read or unread button */}
        <button
          className={cn(
            'whitespace-nowrap py-1 text-xs underline',
            isInFlight && 'cursor-progress'
          )}
          onClick={isRead ? markAsUnread : markAsRead}
          disabled={isInFlight}
        >
          {isRead ? MARK_AS_UNREAD : MARK_AS_READ}
        </button>
      </div>

      {/* title */}
      <p className='mb-4 text-lg font-semibold'>{title}</p>

      {/* message */}
      <p className='mb-4 whitespace-pre-wrap'>{message}</p>

      {/* created at date */}
      <p className='line-clamp-1 text-xs'>
        {getFormattedDateTimeStr(createdAt)}
      </p>
    </li>
  );
};

export default NotificationCard;
