import cn from 'classnames';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../state/user/user.slice';

/**
 * Notifications badge for `NavSlider` nav link
 */
const NotificationsBadge = ({ classNames }: { classNames: string }) => {
  const { unreadNotifications } = useAppSelector(selectUser);

  if (!unreadNotifications.length) return null;

  return (
    <div
      id='notifications-badge'
      className={cn(
        classNames,
        'flex h-6 w-6 items-center justify-center rounded-full'
      )}
    >
      <small className='relative top-px font-semibold'>
        {unreadNotifications.length}
      </small>
    </div>
  );
};

export default NotificationsBadge;
