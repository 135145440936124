import cn from 'classnames';

interface TagProps {
  tag: string;
  classNames: string;
  onClick: () => void;
}

/**
 * Tag for Popular tags Card on Home page and `FilterByTagsTakeover`
 */
const Tag = ({ tag, classNames, onClick }: TagProps) => (
  <button
    id={tag}
    title={tag}
    onClick={onClick}
    className={cn(
      'min-w-[3rem] rounded-3xl px-4 py-3 lg:px-3 lg:py-2',
      classNames
    )}
  >
    <p className='line-clamp-1 text-sm font-semibold'>{tag}</p>
  </button>
);

export default Tag;
