import { hubAPI } from '../../http';
import { ObjectIdString, WithObjectId } from '../../types';
import {
  AdminUpdateNotification,
  AdminStagedNotification,
  AdminNotification,
} from '../../types/adminNotifications.types';
import { AdminNotifications } from './adminNotifications.slice';

/**
 * Fetch all notifications
 */
const fetchAdminNotifications = (signal: AbortSignal) =>
  hubAPI.get<AdminNotifications>('/api/admin/notifications', { signal });

/**
 * create notification
 */
const createNotification = (
  notification: AdminStagedNotification,
  signal: AbortSignal
) =>
  hubAPI.post<WithObjectId<AdminNotification>>(
    '/api/admin/notifications',
    notification,
    {
      signal,
    }
  );

/**
 * update notification
 */
const updateNotification = (
  { notification, notificationId }: AdminUpdateNotification,
  signal: AbortSignal
) =>
  hubAPI.patch<WithObjectId<AdminNotification>>(
    `/api/admin/notifications/${notificationId}`,
    notification,
    {
      signal,
    }
  );

/**
 * delete notification
 *
 * @returns 204
 */
const deleteNotifications = (
  notifications: { notifications: ObjectIdString[] },
  signal: AbortSignal
) =>
  hubAPI.delete('/api/admin/notifications', {
    data: notifications,
    signal,
  });

const adminNotificationsAPI = {
  fetchAdminNotifications,
  createNotification,
  updateNotification,
  deleteNotifications,
};

export default adminNotificationsAPI;
