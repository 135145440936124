import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import {
  PATHS,
  WILDCARD,
  BASE_PATHS,
  ROLES,
  PAGE_TITLES,
} from '../utils/constants';
import Redirect from '../components/Redirect/Redirect';
import { isProd } from '../utils/isEnv';
import { NavFeatureFlags, selectConfig } from '../state/config/config.slice';
import { selectUser } from '../state/user/user.slice';
import { WithTitle } from '../types';
import HomePage from '../pages/HomePage';
import SearchResultsPage from '../pages/SearchResultsPage';
import FavoritesPage from '../pages/FavoritesPage';
import BrowsePage from '../pages/BrowsePage/BrowsePage';
import NotificationsPage from '../pages/NotificationsPage';
import FAQsPage from '../pages/FAQsPage';
import ContentPage from '../pages/ContentPage';
import { useAppSelector } from './redux';

/* istanbul ignore next */
const Pages = {
  HomePage,
  SearchResultsPage,
  FavoritesPage,
  BrowsePage,
  NotificationsPage,
  FAQsPage,
  ContentPage,
  StyleguidePage: lazy(() => import('../pages/StyleguidePage')),
  DSATeamsPage: lazy(() => import('../pages/DSATeamsPage')),
  DSAInsightsFeedPage: lazy(() => import('../pages/DSAInsightsFeedPage')),
  DSAInsightsPostPage: lazy(() => import('../pages/DSAInsightsPostPage')),
  Dev: {
    DSAInsights: lazy(() => import('../pages/dev/DSAInsights')),
  },
  AdminPortalPage: lazy(() => import('../pages/AdminPortalPage')),
  SettingsPage: lazy(() => import('../pages/SettingsPage')),
  AIChatPage: lazy(() => import('../pages/AIChatPage')),
};

const basePaths: Record<keyof typeof BASE_PATHS, WithTitle<RouteObject>> = {
  home: {
    path: PATHS.home,
    element: <Pages.HomePage />,
    title: PAGE_TITLES.HOME,
  },
  searchResults: {
    path: PATHS.searchResults,
    element: <Pages.SearchResultsPage />,
    title: 'Search Results',
  },
  favorites: {
    path: PATHS.favorites,
    element: <Pages.FavoritesPage />,
    title: PAGE_TITLES.FAVORITES,
  },
  browse: {
    path: PATHS.browse,
    element: <Pages.BrowsePage />,
    title: PAGE_TITLES.BROWSE,
  },
  notifications: {
    path: PATHS.notifications,
    element: <Pages.NotificationsPage />,
    title: PAGE_TITLES.NOTIFICATIONS,
  },
  faqs: {
    path: PATHS.faqs,
    element: <Pages.FAQsPage />,
    title: PAGE_TITLES.FAQS,
  },
  content: {
    path: PATHS.content,
    element: <Pages.ContentPage />,
    title: 'Content',
  },
};

const featureFlaggedRoutes: Record<
  keyof NavFeatureFlags,
  WithTitle<RouteObject>[]
> = {
  adminPortal: [
    {
      path: PATHS.adminPortal,
      element: <Pages.AdminPortalPage />,
      title: PAGE_TITLES.ADMIN_PORTAL,
    },
  ],
  aiChat: [
    {
      path: PATHS.aiChat,
      element: <Pages.AIChatPage />,
      title: PAGE_TITLES.AI_CHAT,
    },
  ],
  dsaInsights: [
    {
      path: PATHS.dsaInsightsFeed,
      element: <Pages.DSAInsightsFeedPage />,
      title: PAGE_TITLES.DSA_INSIGHTS,
    },
    {
      path: PATHS.dsaInsightsPost,
      element: <Pages.DSAInsightsPostPage />,
      title: PAGE_TITLES.DSA_INSIGHTS,
    },
  ],
  settings: [
    {
      path: PATHS.settings,
      element: <Pages.SettingsPage />,
      title: PAGE_TITLES.SETTINGS,
    },
  ],
};

const dsaTeams = {
  path: PATHS.dsaTeams,
  element: <Pages.DSATeamsPage />,
  title: PAGE_TITLES.DSA_TEAMS,
};

const defaultRedirect: WithTitle<RouteObject> = {
  path: WILDCARD,
  element: <Redirect to={PATHS.home} />,
  title: 'Redirecting...',
};

const devAdminAndCorporateRoutes = [
  ...Object.values(basePaths),
  dsaTeams,
  defaultRedirect,
];

/**
 * Hook to get routes by:
 *
 * - Most privileged role
 * - `navigation` feature flags
 * - If user is an `AICHAT` user
 */
const useAllowedRoutes = (role: ROLES): WithTitle<RouteObject>[] => {
  const {
    featureFlags: { navigation },
  } = useAppSelector(selectConfig);
  const { isAIChatUser } = useAppSelector(selectUser);

  switch (role) {
    case ROLES.DEVELOPER:
      return developerRoutes(navigation, isAIChatUser);
    case ROLES.ADMIN:
      return adminRoutes(navigation, isAIChatUser);
    case ROLES.CORPORATE:
      return corporateRoutes(navigation, isAIChatUser);
    case ROLES.FIELD_COMPANY:
    case ROLES.FIELD_FRANCHISE:
      return fieldRoutes(navigation);
    default:
      return noReportingRoutes();
  }
};

export default useAllowedRoutes;

/*
 ********************
 * Helpers
 ********************
 */

/**
 * Admin routes
 */
const adminRoutes = (navFeatureFlags: NavFeatureFlags, isAIChatUser: boolean) =>
  devAdminAndCorporateRoutes.concat(
    getFeatureFlaggedRoutes(navFeatureFlags, isAIChatUser)
  );

/**
 * Developer routes
 */
const developerRoutes = (
  navFeatureFlags: NavFeatureFlags,
  isAIChatUser: boolean
) => {
  const developerRoutes = adminRoutes(navFeatureFlags, isAIChatUser);
  if (!isProd()) {
    developerRoutes.push(
      {
        path: PATHS.styleguide,
        element: <Pages.StyleguidePage />,
        title: 'Styleguide',
      },
      {
        path: PATHS.dev.dsaInsights,
        element: <Pages.Dev.DSAInsights />,
        title: `Dev ${PAGE_TITLES.DSA_INSIGHTS}`,
      }
    );
  }
  return developerRoutes;
};

/**
 * Corporate routes
 */
const corporateRoutes = (
  navFeatureFlags: Partial<NavFeatureFlags>,
  isAIChatUser: boolean
) => {
  const { adminPortal, ...corporateNavFeatureFlags } = navFeatureFlags;
  return devAdminAndCorporateRoutes.concat(
    getFeatureFlaggedRoutes(corporateNavFeatureFlags, isAIChatUser)
  );
};

/**
 * Field routes
 */
const fieldRoutes = (navFeatureFlags: Partial<NavFeatureFlags>) => {
  const { adminPortal, aiChat, dsaInsights, ...fieldNavFeatureFlags } =
    navFeatureFlags;
  return [...Object.values(basePaths), defaultRedirect].concat(
    getFeatureFlaggedRoutes(fieldNavFeatureFlags)
  );
};

/**
 * No reporting routes
 */
const noReportingRoutes = () => [
  dsaTeams,
  {
    path: WILDCARD,
    element: <Redirect to={PATHS.dsaTeams} />,
    title: defaultRedirect.title,
  },
];

/**
 * Routes for feature flags
 */
const getFeatureFlaggedRoutes = (
  navFeatureFlags: Partial<NavFeatureFlags>,
  isAIChatUser = false
) =>
  (Object.keys(navFeatureFlags) as (keyof Partial<NavFeatureFlags>)[]).reduce(
    (acc, cur) => {
      if (navFeatureFlags[cur]) {
        // if `aiChat` is flagged on,
        // but user does not have `AICHAT` role,
        // then short circuit
        if (cur === 'aiChat' && !isAIChatUser) return acc;

        featureFlaggedRoutes[cur].forEach(routeObj => acc.push(routeObj));
      }

      return acc;
    },
    [] as WithTitle<RouteObject>[]
  );
