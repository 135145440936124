import { Link } from 'react-router-dom';
import NavSliderButton from '../NavSliderButton/NavSliderButton';
import NotificationsButton from '../NotificationsButton/NotificationsButton';
import Searchbar from '../Searchbar/Searchbar';
import Icons from '../Icons/Icons';
import { DSA_HUB, TUTORIAL_TARGETS } from '../../utils/constants';
import SiteBanner from '../SiteBanner/SiteBanner';
import HeaderBanner from '../../types/headerBanner.types';

/**
 * Desktop header
 */
const DesktopHeader = ({ shouldShowBanner, bannerConfig }: HeaderBanner) => (
  <header className='sticky top-0 z-20 bg-brand-white shadow-lg'>
    <div
      data-testid='desktop-header-container'
      className='relative flex items-center justify-between py-2'
    >
      <div className='flex items-center'>
        <NavSliderButton />
        <Link className='mr-4 flex items-center' to='/'>
          <div className='flex items-center'>
            <Icons.HRBlockIcon className='mr-4' />
            <h1
              id={TUTORIAL_TARGETS.INTRO}
              className='whitespace-nowrap text-2xl'
            >
              {DSA_HUB}
            </h1>
          </div>
        </Link>
      </div>
      <div className='absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2'>
        <Searchbar />
      </div>
      <NotificationsButton />
    </div>
    {shouldShowBanner && <SiteBanner {...bannerConfig} />}
  </header>
);

export default DesktopHeader;
