import { WithObjectId } from '../types';
import { Content } from '../types/content.types';
import { PATH_SEGMENTS } from './constants';
import encode from './encode';

/**
 * Get nested content based on the current pathname
 * @note If the pathname is `/browse`, the function returns all content.
 * Otherwise, it filters the content array based on the pathname's subfolders.
 */
const getNestedContent = (
  pathname: string,
  contentArray: WithObjectId<Content>[]
) => {
  //   if path name is `/browse`, return all content
  if (pathname === PATH_SEGMENTS.browse) {
    return contentArray;
  }

  const pathnameWithoutBrowseSegment = pathname.slice(
    PATH_SEGMENTS.browse.length
  );

  return contentArray.reduce((acc, cur) => {
    //construct the current item's folder path
    const curItemFolderPath =
      '/' + cur.folderPath.map(pathSegment => encode(pathSegment)).join('/');

    //if the current item's folder path starts with the pathname excluding the `/browse` segment, add to accumulator
    if (curItemFolderPath.startsWith(pathnameWithoutBrowseSegment)) {
      acc.push(cur);
    }

    return acc;
  }, [] as WithObjectId<Content>[]);
};

export default getNestedContent;
