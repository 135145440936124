import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import getSortedContentArray from '../pages/BrowsePage/utils/getSortedContentArray';
import { selectContentArray } from '../state/content/content.slice';
import { WithObjectId } from '../types';
import { Content } from '../types/content.types';
import { SEARCHPARAM_KEYS, fuseQueryAndTagsOptions } from '../utils/constants';
import filterContentArrayByTags from '../utils/filterContentArrayByTags';
import { SEARCH_SORT_OPTIONS, SORT_OPTIONS } from '../types/sort.types';
import { useAppSelector } from './redux';
import useContentURLParams from './useContentURLParams';
import useValidateContentURLParams from './useValidateContentURLParams';

const useSearchResults = () => {
  const contentArray = useAppSelector(selectContentArray);
  const [searchResults, setSearchResults] = useState<
    WithObjectId<Content>[] | null
  >(null);
  const {
    searchParams: { querySearchParam, sortSearchParam, searchParams },
  } = useContentURLParams();
  const [
    isQuerySearchParamValid,
    isSortSearchParamValid,
    isTagSearchParamsValid,
  ] = useValidateContentURLParams();

  useEffect(() => {
    if (
      !isQuerySearchParamValid ||
      !isSortSearchParamValid ||
      !isTagSearchParamsValid
    )
      return;

    const array = searchParams.getAll(SEARCHPARAM_KEYS.TAG).length
      ? filterContentArrayByTags(
          contentArray,
          // assumes that all tags exist by this point using `tagsMap`
          searchParams.getAll(SEARCHPARAM_KEYS.TAG)
        )
      : contentArray;

    let results = new Fuse(array, fuseQueryAndTagsOptions)
      .search(querySearchParam as string)
      .map(result => result.item);

    if (sortSearchParam !== SEARCH_SORT_OPTIONS.RELEVANCE) {
      results = getSortedContentArray(sortSearchParam as SORT_OPTIONS, results);
    }

    setSearchResults(results);
  }, [
    contentArray,
    isQuerySearchParamValid,
    isSortSearchParamValid,
    isTagSearchParamsValid,
    querySearchParam,
    searchParams,
    sortSearchParam,
  ]);

  return searchResults;
};
export default useSearchResults;
