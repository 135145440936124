import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import useMediaQuery from '../hooks/useMediaQuery';
import { selectContentMap } from '../state/content/content.slice';
import { selectHomeData } from '../state/home/home.slice';
import { selectUser, selectUserDataMaps } from '../state/user/user.slice';
import { CardsContainerProps } from '../types/home.types';
import { MEDIA_QUERIES } from '../utils/constants';
import { selectConfig } from '../state/config/config.slice';
import DesktopCardsContainer from '../components/DesktopCardsContainer/DesktopCardsContainer';
import MobileCardsContainer from '../components/MobileCardsContainer/MobileCardsContainer';

/**
 * Home page
 */
const HomePage = () => {
  const {
    featureFlags: { navigation: navFeatureFlags },
  } = useAppSelector(selectConfig);
  const { firstName, lastName, favorites, pinnedFavorites } =
    useAppSelector(selectUser);
  const { allFavoritesMap, pinnedFavoritesMap } =
    useAppSelector(selectUserDataMaps);
  const { popularContent, dsaInsights, popularTags } =
    useAppSelector(selectHomeData);
  const contentMap = useAppSelector(selectContentMap);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const navigate = useNavigate();

  const props: CardsContainerProps = {
    navFeatureFlags,
    allFavoritesMap,
    pinnedFavoritesMap,
    favorites,
    pinnedFavorites,
    popularContent,
    dsaInsights,
    popularTags,
    contentMap,
    navigate,
  };

  return (
    <>
      {isDesktop ? (
        <>
          <h1 className='mb-8'>Welcome, {firstName + ' ' + lastName}!</h1>
          <DesktopCardsContainer {...props} />
        </>
      ) : (
        <MobileCardsContainer {...props} />
      )}
    </>
  );
};

export default HomePage;
