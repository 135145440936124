import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface CardProps extends PropsWithChildren {
  classNames?: string;
}

/**
 * A generic card
 */
const Card = ({ classNames, children }: CardProps) => {
  return (
    <div
      className={cn(
        classNames,
        'flex grow flex-col rounded-3xl bg-brand-white shadow'
      )}
    >
      {children}
    </div>
  );
};

export default Card;
