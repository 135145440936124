import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  DashboardLayoutOptions,
  User,
  UserInFlightRequests,
} from '../../types/user.types';
import {
  APPLICATION_STATE_UPDATES_INTERVAL,
  EPOCH,
  ROLES,
  STATE_STATUSES,
} from '../../utils/constants';
import { AppDispatch, RootState } from '../store';
import { reconcileToasts } from '../toast/toast.slice';
import getFavoritesMap from '../../utils/getFavoritesMap';
import { ObjectIdString } from '../../types';
import userAPI from './user.api';

export interface UserState {
  status: STATE_STATUSES;
  data: User;
  inFlightRequests: UserInFlightRequests;
  dataMaps: {
    favoritesMap: Record<ObjectIdString, true>;
    pinnedFavoritesMap: Record<ObjectIdString, true>;
    allFavoritesMap: Record<ObjectIdString, true>;
  };
  aiChatData: {
    profileImageBlob: string;
  };
}

export const initialState: UserState = {
  status: STATE_STATUSES.INITIAL,
  data: {
    firstName: '',
    lastName: '',
    email: '',
    settings: {
      dashboardLayout: [
        [
          DashboardLayoutOptions.FAVORITES,
          DashboardLayoutOptions.POPULAR_CONTENT,
        ],
        [
          DashboardLayoutOptions.DSA_INSIGHTS,
          DashboardLayoutOptions.POPULAR_TAGS,
        ],
      ],
    },
    favorites: [],
    pinnedFavorites: [],
    unreadNotifications: [],
    readNotifications: [],
    role: ROLES.NO_REPORTING,
    isAIChatUser: false,
    isTutorialComplete: false,
    lastLoggedIn: EPOCH,
    banner: {
      dateAcknowledged: EPOCH,
    },
  },
  inFlightRequests: {
    favorites: {},
    pinnedFavorites: {},
    readNotifications: {},
    unreadNotifications: {},
  },
  dataMaps: {
    favoritesMap: {},
    pinnedFavoritesMap: {},
    allFavoritesMap: {},
  },
  aiChatData: {
    profileImageBlob: '',
  },
};

/**
 * Fetch me
 */
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, thunkAPI) => {
    const response = await userAPI.fetchUser(thunkAPI.signal);

    return response.data;
  }
);

/**
 * Add user favorite
 */
export const addFavorite = createAsyncThunk(
  'user/addFavorite',
  async (favorite: ObjectIdString, thunkAPI) => {
    const response = await userAPI.addFavorite(favorite, thunkAPI.signal);

    return response.data;
  }
);

/**
 * Remove user favorite
 */
export const removeFavorite = createAsyncThunk(
  'user/removeFavorite',
  async (favorite: ObjectIdString, thunkAPI) => {
    const response = await userAPI.removeFavorite(favorite, thunkAPI.signal);

    return response.data;
  }
);

/**
 * Add pinned user favorite
 */
export const addPinnedFavorite = createAsyncThunk(
  'user/addPinnedFavorite',
  async (pinnedFavorite: ObjectIdString, thunkAPI) => {
    const response = await userAPI.addPinnedFavorite(
      pinnedFavorite,
      thunkAPI.signal
    );

    return response.data;
  }
);

/**
 * Remove pinned user favorite
 */
export const removePinnedFavorite = createAsyncThunk(
  'user/removePinnedFavorite',
  async (pinnedFavorite: ObjectIdString, thunkAPI) => {
    const response = await userAPI.removePinnedFavorite(
      pinnedFavorite,
      thunkAPI.signal
    );

    return response.data;
  }
);

/**
 * Check for new notifications
 */
export const fetchUnreadNotifications = createAsyncThunk(
  'user/fetchUnreadNotifications',
  async (_, thunkAPI) => {
    const response = await userAPI.fetchUnreadNotifications(thunkAPI.signal);
    thunkAPI.dispatch(
      reconcileToasts(
        thunkAPI.getState() as RootState,
        response.data.unreadNotifications
      )
    );

    return response.data;
  }
);

/**
 * Interval request to check for application state updates
 *
 * @see initFetchApplicationStateUpdates
 */
export const fetchApplicationStateUpdates = createAsyncThunk(
  'user/fetchApplicationStateUpdates',
  async (_, thunkAPI) => {
    const response = await userAPI.fetchApplicationStateUpdates(
      thunkAPI.signal
    );
    const incomingUnreadNotifications =
      response.data.notifications?.unreadNotifications;

    if (incomingUnreadNotifications) {
      thunkAPI.dispatch(
        reconcileToasts(
          thunkAPI.getState() as RootState,
          incomingUnreadNotifications
        )
      );
    }

    return response.data;
  }
);

/**
 * Initialize fetching application state updates
 */
export const initFetchApplicationStateUpdates = (dispatch: AppDispatch) =>
  setInterval(
    () => dispatch(fetchApplicationStateUpdates()),
    APPLICATION_STATE_UPDATES_INTERVAL
  );

/**
 * Mark notification(s) as read
 */
export const addReadNotifications = createAsyncThunk(
  'user/addReadNotifications',
  async (notifications: ObjectIdString[], thunkAPI) => {
    const response = await userAPI.addReadNotifications(
      notifications,
      thunkAPI.signal
    );

    return response.data;
  }
);

/**
 * Mark notification(s) as unread
 */
export const removeReadNotifications = createAsyncThunk(
  'user/removeReadNotifications',
  async (notifications: ObjectIdString[], thunkAPI) => {
    const response = await userAPI.removeReadNotifications(
      notifications,
      thunkAPI.signal
    );

    return response.data;
  }
);

/**
 * Patch `isTutorialComplete`
 */
export const patchIsTutorialComplete = createAsyncThunk(
  'user/patchIsTutorialComplete',
  async (isTutorialComplete: boolean, thunkAPI) => {
    await userAPI.patchIsTutorialComplete(isTutorialComplete, thunkAPI.signal);
  }
);

/**
 * Patch `banner.dateAcknowledged`
 */
export const dismissBanner = createAsyncThunk(
  'user/dismissBanner',
  async (date: Date, thunkAPI) => {
    await userAPI.dismissBanner(date, thunkAPI.signal);
  }
);

/**
 * Fetch headshot image
 */
export const fetchHeadshot = createAsyncThunk(
  'user/fetchHeadshot',
  async (size: string, thunkAPI) => {
    const data = await userAPI.fetchHeadshot(size, thunkAPI.signal);
    return data && URL.createObjectURL(data);
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleIsTutorialComplete: (state, action: PayloadAction<boolean>) => {
      state.data.isTutorialComplete = action.payload;
    },
    setProfileImageBlob: (state, action) => {
      URL.revokeObjectURL(state.aiChatData.profileImageBlob);
      state.aiChatData.profileImageBlob = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // `fetchUser`
      .addCase(fetchUser.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const favoritesMap = getFavoritesMap(action.payload.favorites);
        const pinnedFavoritesMap = getFavoritesMap(
          action.payload.pinnedFavorites
        );
        const allFavoritesMap = getFavoritesMap([
          ...action.payload.pinnedFavorites,
          ...action.payload.favorites,
        ]);

        const newState: UserState = {
          ...initialState,
          status: STATE_STATUSES.FULFILLED,
          data: action.payload,
          dataMaps: {
            favoritesMap,
            pinnedFavoritesMap,
            allFavoritesMap,
          },
        };

        return newState;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        if (!action.meta.aborted) {
          // update status for `error.slice`
          state.status = STATE_STATUSES.REJECTED;
        }
      })

      // `addReadNotifications`
      .addCase(addReadNotifications.pending, (state, action) => {
        const { arg } = action.meta;
        arg.forEach(_id => {
          state.inFlightRequests.readNotifications[_id] = true;
        });
      })
      .addCase(addReadNotifications.fulfilled, (state, action) => {
        const { arg } = action.meta;
        arg.forEach(_id => {
          delete state.inFlightRequests.readNotifications[_id];
        });
      })
      .addCase(addReadNotifications.rejected, (state, action) => {
        if (!action.meta.aborted) {
          const { arg } = action.meta;
          arg.forEach(_id => {
            delete state.inFlightRequests.readNotifications[_id];
          });
        }
      })
      // `removeReadNotifications`
      .addCase(removeReadNotifications.pending, (state, action) => {
        const { arg } = action.meta;
        arg.forEach(_id => {
          state.inFlightRequests.unreadNotifications[_id] = true;
        });
      })
      .addCase(removeReadNotifications.fulfilled, (state, action) => {
        const { arg } = action.meta;
        arg.forEach(_id => {
          delete state.inFlightRequests.unreadNotifications[_id];
        });
      })
      .addCase(removeReadNotifications.rejected, (state, action) => {
        if (!action.meta.aborted) {
          const { arg } = action.meta;
          arg.forEach(_id => {
            delete state.inFlightRequests.unreadNotifications[_id];
          });
        }
      })
      // `addFavorite`
      .addCase(addFavorite.fulfilled, (state, action) => {
        const { arg } = action.meta;
        delete state.inFlightRequests.favorites[arg];

        //update `favorites` and `favoritesMap`
        state.data.favorites = action.payload.favorites;
        state.dataMaps.favoritesMap = getFavoritesMap(action.payload.favorites);

        //update `allFavoritesMap`
        state.dataMaps.allFavoritesMap = getFavoritesMap([
          ...state.data.pinnedFavorites,
          ...action.payload.favorites,
        ]);
      })
      // `removeFavorite`
      .addCase(removeFavorite.fulfilled, (state, action) => {
        const { arg } = action.meta;
        delete state.inFlightRequests.favorites[arg];

        //update `favorites` and `favoritesMap`
        state.data.favorites = action.payload.favorites;
        state.dataMaps.favoritesMap = getFavoritesMap(action.payload.favorites);

        //update `pinnedFavorites` and `pinnedFavoritesMap`
        state.data.pinnedFavorites = action.payload.pinnedFavorites;
        state.dataMaps.pinnedFavoritesMap = getFavoritesMap(
          action.payload.pinnedFavorites
        );

        //update `allFavoritesMap`
        state.dataMaps.allFavoritesMap = getFavoritesMap([
          ...action.payload.pinnedFavorites,
          ...action.payload.favorites,
        ]);
      })
      //update application state
      .addCase(fetchApplicationStateUpdates.fulfilled, (state, action) => {
        const { notifications } = action.payload;

        if (notifications) {
          state.data.unreadNotifications = notifications.unreadNotifications;
          state.data.readNotifications = notifications.readNotifications;
        }
      })
      // dismiss banner
      .addCase(dismissBanner.pending, (state, action) => {
        state.data.banner.dateAcknowledged = action.meta.arg.toISOString();
      })

      // fetch headshot image
      .addCase(fetchHeadshot.fulfilled, (state, action) => {
        if (action.payload) state.aiChatData.profileImageBlob = action.payload;
      })

      // matchers
      .addMatcher(
        action =>
          [
            fetchUnreadNotifications.fulfilled.type,
            addReadNotifications.fulfilled.type,
            removeReadNotifications.fulfilled.type,
          ].includes(action.type),
        (state, action) => {
          state.data.unreadNotifications = action.payload.unreadNotifications;
          state.data.readNotifications = action.payload.readNotifications;
        }
      )
      .addMatcher(
        action =>
          [addFavorite.pending.type, removeFavorite.pending.type].includes(
            action.type
          ),
        (state, action) => {
          const { arg } = action.meta;
          state.inFlightRequests.favorites[arg] = true;
        }
      )
      .addMatcher(
        action =>
          [addFavorite.rejected.type, removeFavorite.rejected.type].includes(
            action.type
          ),
        (state, action) => {
          if (!action.meta.aborted) {
            const { arg } = action.meta;
            delete state.inFlightRequests.favorites[arg];
          }
        }
      )
      .addMatcher(
        action =>
          [
            addPinnedFavorite.pending.type,
            removePinnedFavorite.pending.type,
          ].includes(action.type),
        (state, action) => {
          const { arg } = action.meta;
          state.inFlightRequests.pinnedFavorites[arg] = true;
        }
      )
      .addMatcher(
        action =>
          [
            addPinnedFavorite.fulfilled.type,
            removePinnedFavorite.fulfilled.type,
          ].includes(action.type),
        (state, action) => {
          const { arg } = action.meta;
          delete state.inFlightRequests.pinnedFavorites[arg];

          //update `pinnedFavorites` and `pinnedFavoritesMap`
          state.data.pinnedFavorites = action.payload.pinnedFavorites;
          state.dataMaps.pinnedFavoritesMap = getFavoritesMap(
            action.payload.pinnedFavorites
          );

          //update `favorites` and `favoritesMap`
          state.data.favorites = action.payload.favorites;
          state.dataMaps.favoritesMap = getFavoritesMap(
            action.payload.favorites
          );
        }
      )
      .addMatcher(
        action =>
          [
            addPinnedFavorite.rejected.type,
            removePinnedFavorite.rejected.type,
          ].includes(action.type),
        (state, action) => {
          if (!action.meta.aborted) {
            const { arg } = action.meta;
            delete state.inFlightRequests.pinnedFavorites[arg];
          }
        }
      );
  },
});

export const userReducer = userSlice.reducer;

export const { toggleIsTutorialComplete, setProfileImageBlob } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user.data;

export const selectUserDataMaps = (state: RootState) => state.user.dataMaps;

export const selectUserStatus = (state: RootState) => state.user.status;

export const selectFavoritesInFlight = (state: RootState) =>
  state.user.inFlightRequests.favorites;

export const selectPinnedFavoritesInFlight = (state: RootState) =>
  state.user.inFlightRequests.pinnedFavorites;

export const selectReadNotificationsInFlight = (state: RootState) =>
  state.user.inFlightRequests.readNotifications;

export const selectUnreadNotificationsInFlight = (state: RootState) =>
  state.user.inFlightRequests.unreadNotifications;

export const selectProfileImageBlob = (state: RootState) =>
  state.user.aiChatData.profileImageBlob;
