import { hubAPI } from '../../http';
import { WithObjectId } from '../../types/';
import {
  AdminUpdateTag,
  ContentItems,
  Content,
} from '../../types/content.types';

const fetchAllContentByRole = (signal: AbortSignal) =>
  hubAPI.get<ContentItems>('/api/content', { signal });

const updateContent = (
  { content, contentId }: AdminUpdateTag,
  signal: AbortSignal
) =>
  hubAPI.patch<WithObjectId<Content>>(`/api/content/${contentId}`, content, {
    signal,
  });

const syncLooker = (signal: AbortSignal) =>
  hubAPI.get('/api/content/looker/sync', { signal });

const contentAPI = {
  fetchAllContentByRole,
  updateContent,
  syncLooker,
};

export default contentAPI;
