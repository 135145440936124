import { Link } from 'react-router-dom';
import NavSliderButton from '../NavSliderButton/NavSliderButton';
import NotificationsButton from '../NotificationsButton/NotificationsButton';
import Searchbar from '../Searchbar/Searchbar';
import { DSA_HUB, TUTORIAL_TARGETS } from '../../utils/constants';
import SiteBanner from '../SiteBanner/SiteBanner';
import HeaderBanner from '../../types/headerBanner.types';

/**
 * Mobile header
 */
const MobileHeader = ({ shouldShowBanner, bannerConfig }: HeaderBanner) => (
  <header className='z-20 bg-brand-white'>
    <div
      data-testid='mobile-header-container'
      className='flex flex-col items-center'
    >
      <div className='flex w-full items-center justify-between'>
        <NavSliderButton />
        <Link to='/'>
          <h1 id={TUTORIAL_TARGETS.INTRO} className='text-lg'>
            {DSA_HUB}
          </h1>
        </Link>
        <NotificationsButton />
      </div>
      <div className='px-[--layout-padding] pb-[--layout-padding]'>
        <Searchbar />
      </div>
    </div>
    {shouldShowBanner && <SiteBanner {...bannerConfig} />}
  </header>
);

export default MobileHeader;
