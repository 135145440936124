import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchConfig } from '../config/config.slice';
import { fetchAllContentByRole } from '../content/content.slice';
import { setErrorMessage } from '../error/error.slice';
import { fetchHome } from '../home/home.slice';
import { RootState } from '../store';
import { fetchUser } from '../user/user.slice';

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: true,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => action.type === setErrorMessage.type,
        state => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        action =>
          [
            fetchConfig.rejected.type,
            fetchUser.rejected.type,
            fetchHome.rejected.type,
            fetchAllContentByRole.rejected.type,
          ].includes(action.type),
        (state, action) => {
          // not rejected via AbortController (i.e., legitimate error)
          if (!action.meta.aborted) {
            state.isLoading = false;
          }
        }
      );
  },
});

export const loadingReducer = loadingSlice.reducer;

export const { toggleLoading } = loadingSlice.actions;

export const selectLoading = (state: RootState) => state.loading.isLoading;
