import cn from 'classnames';
import { MutableRefObject } from 'react';
import useToggleFavoriteOrPin from '../../hooks/useToggleFavoriteOrPin';
import { useAppSelector } from '../../hooks/redux';
import {
  selectUserDataMaps,
  selectFavoritesInFlight,
} from '../../state/user/user.slice';
import FavoriteButton from '../FavoriteButton/FavoriteButton';

/**
 * Local constants
 */
export const IFRAME_TITLE = 'Content';
export const CONTENT_ITEM = 'content-item';
const fullHeightAndWidth = 'h-full w-full';

interface ContentItemProps {
  embedLink: string;
  contentId: string;
  isHidden?: boolean;
  iframeRef?: MutableRefObject<HTMLIFrameElement | null>;
}

const ContentItem = ({
  embedLink,
  contentId,
  isHidden,
  iframeRef,
}: ContentItemProps) => {
  const { allFavoritesMap } = useAppSelector(selectUserDataMaps);
  const favoritesInFlight = useAppSelector(selectFavoritesInFlight);
  const handleFavorite = useToggleFavoriteOrPin();

  return (
    <div
      data-testid={CONTENT_ITEM}
      className={cn(fullHeightAndWidth, {
        hidden: isHidden,
      })}
    >
      <iframe
        ref={iframeRef}
        src={embedLink}
        title={IFRAME_TITLE}
        className={fullHeightAndWidth}
      />
      <div
        onClick={handleFavorite}
        className='absolute bottom-12 right-4 h-11 w-11 rounded-3xl bg-brand-white shadow-md transition-shadow hover:shadow-lg'
      >
        <FavoriteButton
          {...{
            isFavorite: !!allFavoritesMap[contentId],
            isDisabled: !!favoritesInFlight[contentId],
            _id: contentId,
            classNames: 'p-3 hover:scale-[115%] transition-transform',
          }}
        />
      </div>
    </div>
  );
};

export default ContentItem;
