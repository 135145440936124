import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATE_STATUSES } from '../../utils/constants';
import { ObjectIdString, WithObjectId, NameObject } from '../../types';
import { RootState } from '../store';
import { fetchAllContentByRole } from '../content/content.slice';
import adminTagsAPI from './adminTags.api';

export interface AdminTags {
  tags: WithObjectId<NameObject>[];
}

export interface AdminTagsState {
  statuses: {
    fetchTags: STATE_STATUSES;
    deleteTag: STATE_STATUSES;
  };
  data: AdminTags;
}

export const initialState: AdminTagsState = {
  statuses: {
    fetchTags: STATE_STATUSES.INITIAL,
    deleteTag: STATE_STATUSES.INITIAL,
  },
  data: {
    tags: [],
  },
};

export const fetchTags = createAsyncThunk(
  'adminTags/fetchTags',
  async (_, thunkAPI) => {
    const response = await adminTagsAPI.fetchTags(thunkAPI.signal);
    return response.data;
  }
);

export const deleteTag = createAsyncThunk(
  'adminTags/deleteTag',
  async (tagId: ObjectIdString, thunkAPI) => {
    const response = await adminTagsAPI.deleteTag(tagId, thunkAPI.signal);
    await thunkAPI.dispatch(fetchAllContentByRole());
    return response.data;
  }
);

export const adminTagsSlice = createSlice({
  name: 'adminTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTags.pending, state => {
        state.statuses.fetchTags = STATE_STATUSES.PENDING;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.statuses.fetchTags = STATE_STATUSES.FULFILLED;
        state.data = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.statuses.fetchTags = STATE_STATUSES.REJECTED;
        }
      })
      .addCase(deleteTag.pending, state => {
        state.statuses.deleteTag = STATE_STATUSES.PENDING;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        const { arg } = action.meta;
        state.data.tags = state.data.tags.filter(tag => tag._id !== arg);
        state.statuses.deleteTag = STATE_STATUSES.FULFILLED;
      })
      .addCase(deleteTag.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.statuses.deleteTag = STATE_STATUSES.REJECTED;
        }
      });
  },
});
export const adminTagsReducer = adminTagsSlice.reducer;

export const selectAdminTags = (state: RootState) => state.adminTags.data;

export const selectIsDeletingTag = (state: RootState) =>
  state.adminTags.statuses.deleteTag === STATE_STATUSES.PENDING;

export const selectIsFetchTagsRejected = (state: RootState) =>
  state.adminTags.statuses.fetchTags === STATE_STATUSES.REJECTED;

export const selectIsDeleteTagRejected = (state: RootState) =>
  state.adminTags.statuses.deleteTag === STATE_STATUSES.REJECTED;
