import {
  EventType,
  AccountInfo,
  EventMessage,
  AuthenticationResult,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import AppInsights from '../applicationInsights';

export class PCA {
  private static _instance: PublicClientApplication;

  static get instance() {
    return PCA._instance;
  }

  static async init() {
    if (!PCA._instance) {
      PCA._instance = new PublicClientApplication({
        auth: {
          authority: process.env.REACT_APP_AUTH_LOGIN_ENDPOINT,
          clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
          redirectUri: window.location.origin,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
        telemetry: {
          application: {
            appName: 'DSA Hub UI',
            appVersion: '2.0.0',
          },
        },
      });
      await PCA._instance.initialize();
    }
  }
}

export const scopes = ['openid', 'email', 'User.Read', 'profile'];
const silentRequestDefaults: SilentRequest = {
  scopes,
};

export const getAccessToken = async (account: AccountInfo | null) => {
  if (account) {
    const request: SilentRequest = {
      ...silentRequestDefaults,
      account,
    };

    return PCA.instance
      .acquireTokenSilent(request)
      .then(response => {
        return response.accessToken;
      })
      .catch(error => {
        // Do not fallback to interaction when running outside the context of MsalProvider.
        // Interaction should always be done inside context.
        AppInsights.trackException(error);
        return null;
      });
  }

  return null;
};

export const getIdToken = async (account: AccountInfo | null) => {
  if (account) {
    // open MSAL library issue for acquiring idTokens:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
    const exp = account.idTokenClaims?.exp || 0;

    const request: SilentRequest = {
      ...silentRequestDefaults,
      account,
      forceRefresh: new Date(exp * 1000) <= new Date(),
    };

    return PCA.instance
      .acquireTokenSilent(request)
      .then(response => {
        return response.idToken;
      })
      .catch(error => {
        // Do not fallback to interaction when running outside the context of MsalProvider.
        // Interaction should always be done inside context.
        AppInsights.trackException(error);
        return null;
      });
  }

  return null;
};

export const getAccount = () => PCA.instance.getActiveAccount();

export const getIsUserLoggedIn = () => {
  const account = getAccount();

  if (!account) return false;

  return true;
};

export const login = async () => {
  try {
    await PCA.instance.loginRedirect({ scopes });
  } catch (error) {
    //caught error does not prevent user from being redirected to login; do not log in App Insights
  }
};

// make user logout
export const logout = () => {
  return PCA.instance.logoutRedirect({
    account: getAccount(),
    postLogoutRedirectUri: '/',
  });
};

export const msalInit = async () => {
  await PCA.init();

  const accounts = PCA.instance.getAllAccounts();
  if (!PCA.instance.getActiveAccount() && accounts.length) {
    PCA.instance.setActiveAccount(accounts[0]);
  }

  PCA.instance.addEventCallback((event: EventMessage) => {
    const isAuthEvent = event.eventType === EventType.LOGIN_SUCCESS;
    // ||
    // event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    // event.eventType === EventType.SSO_SILENT_SUCCESS;
    if (isAuthEvent && event.payload) {
      const payload = event.payload as AuthenticationResult;
      // TODO: consider dispatching `roles` to redux store
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/migration-guide.md#updating-redux-store-integration--reacting-to-events
      PCA.instance.setActiveAccount(payload.account);
    }
  });
};
