import { lazy, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import cn from 'classnames';
import Routes from '../Routes/Routes';
import { PATHS, ROLES } from '../../utils/constants';
import Layout from '../Layout/Layout';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectUser,
  initFetchApplicationStateUpdates,
} from '../../state/user/user.slice';
import useGlobalEscape from '../../hooks/useGlobalEscape';
import { toggleLoading } from '../../state/loading/loading.slice';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import useInformUserOfUpdatesOrForceRefresh from '../../hooks/useInformUserOfUpdatesOrForceRefresh';
import LookerHiddenIframeListener from '../LookerHiddenIframeListener/LookerHiddenIframeListener';

const Tutorial = lazy(() => import('../Tutorial/Tutorial'));

const App = () => {
  useGlobalEscape();
  useScrollRestoration();
  useInformUserOfUpdatesOrForceRefresh();
  const dispatch = useAppDispatch();
  const { role, isTutorialComplete } = useAppSelector(selectUser);
  const isNoReportingUser = role === ROLES.NO_REPORTING;
  const isStyleGuideRoute = useMatch(PATHS.styleguide);

  useEffect(() => {
    dispatch(toggleLoading(false));

    let intervalId: NodeJS.Timer;

    // if user is a reporting user, start application state updates interval fetch
    if (!isNoReportingUser) {
      intervalId = initFetchApplicationStateUpdates(dispatch);
    }

    return () => clearInterval(intervalId);
  }, [dispatch, isNoReportingUser]);

  const routes = <Routes role={role} />;

  if (isNoReportingUser || isStyleGuideRoute) {
    return (
      <Layout.Container>
        <Layout.Main classNames={cn(isNoReportingUser && Layout.classNames)}>
          {routes}
        </Layout.Main>
      </Layout.Container>
    );
  }

  return (
    <>
      {!isTutorialComplete && <Tutorial />}
      <Layout>{routes}</Layout>
      <LookerHiddenIframeListener />
    </>
  );
};

export default App;
