import { WithObjectId } from '../../../types';
import { Content } from '../../../types/content.types';
import { SORT_PROPERTIES } from '../types';
import SORTBY from '../../../utils/sortBy';
import { SORT_OPTIONS, SortByMeta } from '../../../types/sort.types';

/**
 * Sort by sort option, then by secondary sort property
 */
const getSortedContentArray = (
  sort: SORT_OPTIONS,
  contentArray: WithObjectId<Content>[]
) => {
  const primarySortFunc = SORTBY[sort].sort;
  const isAlphaSort = [SORT_OPTIONS.AZ, SORT_OPTIONS.ZA].includes(sort);

  let secondarySortFunc: SortByMeta['sort'], primarySortBy: SORT_PROPERTIES;
  // if alpha sort, then secondarily sort content with same titles in descending date order
  if (isAlphaSort) {
    secondarySortFunc = SORTBY.newest.sort;
    primarySortBy = SORT_PROPERTIES.TITLE;
    // otherwise, if sort by date, then secondarily sort content with same date in ascending title order
  } else {
    secondarySortFunc = SORTBY.az.sort;
    primarySortBy = SORT_PROPERTIES.DATE_ADDED;
  }

  // sort by primary sort option (the selected sort option)
  const sorted = [...contentArray].sort(primarySortFunc);

  // sort by secondary sort option
  for (let i = 0; i < sorted.length - 1; i++) {
    let j;
    let areSameValue = false;

    for (j = i + 1; j < sorted.length; j++) {
      if (sorted[i][primarySortBy] === sorted[j][primarySortBy]) {
        areSameValue = true;
      } else {
        break;
      }
    }

    if (areSameValue) {
      const sliced = sorted.slice(i, j).sort(secondarySortFunc);
      sorted.splice(i, sliced.length, ...sliced);
      i += sliced.length - 1;
    }
  }

  return sorted;
};

export default getSortedContentArray;
