import { useEffect } from 'react';
import api from '../../http';
import ContentItem from '../ContentItem/ContentItem';

interface MSStreamContentItemProps {
  contentId: string;
  idFromSource: string;
}

const MSStreamContentItem = ({
  contentId,
  idFromSource,
}: MSStreamContentItemProps) => {
  // handle `addView`
  useEffect(() => {
    const controller = new AbortController();

    api.addView(contentId, controller.signal);
    return () => controller.abort();
  }, [contentId]);

  return (
    <ContentItem
      {...{
        embedLink: idFromSource,
        contentId,
      }}
    />
  );
};

export default MSStreamContentItem;
