import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  fetchConfig,
  selectConfigStatus,
} from '../../state/config/config.slice';
import {
  fetchAllContentByRole,
  selectFetchAllContentByRoleStatus,
} from '../../state/content/content.slice';
import { selectErrorMessage } from '../../state/error/error.slice';
import { fetchHome, selectHomeStatus } from '../../state/home/home.slice';
import { ROLES, STATE_STATUSES } from '../../utils/constants';
import { fetchUser, selectUserStatus } from '../../state/user/user.slice';
import Oops from '../Oops/Oops';
import getMostPrivilegedRole from '../../utils/getMostPrivilegedRole';
import { fetchDSATeams } from '../../state/dsaTeams/dsaTeams.slice';

interface AppInitProps {
  children: JSX.Element;
}

/**
 * Initialize the app
 *
 * For example:
 * - Fetch config
 * - Fetch user
 * - Etc.
 */
const AppInit = ({ children }: AppInitProps) => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const dispatch = useAppDispatch();
  const configStatus = useAppSelector(selectConfigStatus);
  const userStatus = useAppSelector(selectUserStatus);
  const homeStatus = useAppSelector(selectHomeStatus);
  const contentStatus = useAppSelector(selectFetchAllContentByRoleStatus);
  const errorMessage = useAppSelector(selectErrorMessage);

  // Fetch data
  useEffect(() => {
    if (getMostPrivilegedRole() === ROLES.NO_REPORTING) {
      // `NO_REPORTING` users only get DSA Teams page, so fetch data
      const fetchDSATeamsPromise = dispatch(fetchDSATeams());
      fetchDSATeamsPromise.then(
        ({ payload }) => payload && setIsAppInitialized(true)
      );

      return () => fetchDSATeamsPromise.abort();
    }

    const fetchConfigPromise = dispatch(fetchConfig());
    const fetchUserPromise = dispatch(fetchUser());
    const fetchHomePromise = dispatch(fetchHome());
    const fetchAllContentByRolePromise = dispatch(fetchAllContentByRole());

    return () => {
      fetchConfigPromise.abort();
      fetchUserPromise.abort();
      fetchHomePromise.abort();
      fetchAllContentByRolePromise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    const areAllSettled = [
      configStatus,
      userStatus,
      homeStatus,
      contentStatus,
    ].every(
      status =>
        ![STATE_STATUSES.INITIAL, STATE_STATUSES.PENDING].includes(status)
    );

    if (areAllSettled) {
      setIsAppInitialized(true);
    }
  }, [configStatus, userStatus, homeStatus, contentStatus, dispatch]);

  if (errorMessage) {
    return <Oops errorMessage={errorMessage} />;
  }

  return isAppInitialized ? children : null;
};

export default AppInit;
