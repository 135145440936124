import { Link } from 'react-router-dom';
import { WithObjectId } from '../../types';
import { INotification } from '../../types/user.types';
import { PATHS, DATA_DISMISS_BTN, MARK_AS_READ } from '../../utils/constants';
import { preventDefaultOnNestedClick } from '../../utils/preventDefaultOnNestedClick';
import getSeverityIconAndClassNames from '../../utils/getSeverityIconAndClassNames';
import getFormattedDateTimeStr from '../../utils/getFormattedDateTimeStr';

/**
 * Individual toast element
 */
const Toast = ({
  _id,
  title,
  message,
  severity,
  createdAt,
}: WithObjectId<INotification>) => {
  const { backgroundClassNames, Icon, lightClassNames, prettySeverity } =
    getSeverityIconAndClassNames(severity);

  return (
    <Link
      title='Navigate to notifications page'
      to={PATHS.notifications}
      data-toast={_id}
      onClick={preventDefaultOnNestedClick(DATA_DISMISS_BTN)}
      className={`relative block overflow-hidden rounded-lg p-4 text-brand-white shadow-lg focus:ring focus:ring-inset focus:ring-offset-2 ${backgroundClassNames}`}
    >
      {/* title and message */}
      <div role='alert'>
        <div className='relative mb-2 flex items-center'>
          <div className='mr-6 flex items-center'>
            {/* icon */}
            <div className='mr-1.5'>
              <Icon className={lightClassNames} />
            </div>
            {/* severity */}
            <p className='mr-2 text-sm font-semibold'>
              {prettySeverity.toUpperCase()}{' '}
            </p>
            {/* created at date */}
            <p className='line-clamp-1 text-xs'>
              {getFormattedDateTimeStr(createdAt)}
            </p>
          </div>
          <button
            title={MARK_AS_READ}
            data-dismissbtn={_id}
            className='absolute -right-4 px-4 py-3 text-2xl'
          >
            &times;
          </button>
        </div>
        <p className='line-clamp-1 text-lg font-semibold'>{title}</p>
        <p className='line-clamp-1 text-lg'>{message}</p>
      </div>
    </Link>
  );
};

export default Toast;
