import { hubAPI } from '../../http';
import { Config } from './config.slice';

const fetchConfig = (signal: AbortSignal) =>
  hubAPI.get<Config>('/api/config', { signal });

const configAPI = {
  fetchConfig,
};

export default configAPI;
