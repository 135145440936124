/**
 * Day of Week, Month Day, Year @ Time
 *
 * @example
 * 'Fri, Feb 10, 2023 @ 7:29 AM'
 */
const getFormattedDateTimeStr = (isoDateStr: string) => {
  const date = new Date(isoDateStr);
  const dateStr = date.toDateString();

  return `${dateStr
    .split(' ')
    .map((str, i) => {
      if (i === 0 || i === 2) {
        return str + ',';
      }
      return str;
    })
    .join(' ')} @ ${date
    .toLocaleTimeString()
    .split(' ')
    .map((str, i) => {
      if (i === 0) {
        const time = str.split(':');
        time.pop();
        return time.join(':');
      }
      return str;
    })
    .join(' ')}`;
};

export default getFormattedDateTimeStr;
