import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ERROR_CODES, STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import { syncLooker } from '../content/content.slice';
import statusesAPI from './statuses.api';

export interface Statuses {
  looker: {
    isSyncing: boolean;
  };
}

export interface StatusesState {
  status: STATE_STATUSES;
  data: Statuses;
}

export const initialState: StatusesState = {
  status: STATE_STATUSES.INITIAL,
  data: {
    looker: {
      isSyncing: true,
    },
  },
};

export const fetchStatuses = createAsyncThunk(
  'statuses/fetchStatuses',
  async (_, thunkAPI) => {
    const response = await statusesAPI.fetchStatuses(thunkAPI.signal);
    return response.data;
  }
);

export const statusesSlice = createSlice({
  name: 'statuses',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStatuses.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        return {
          status: STATE_STATUSES.FULFILLED,
          data: action.payload,
        };
      })
      .addCase(fetchStatuses.rejected, (state, action) => {
        if (!action.meta.aborted) {
          // update status for `error.slice`
          state.status = STATE_STATUSES.REJECTED;
        }
      })
      .addCase(syncLooker.pending, state => {
        state.data.looker.isSyncing = true;
      })
      .addCase(syncLooker.fulfilled, state => {
        state.data.looker.isSyncing = false;
      })
      .addCase(syncLooker.rejected, (state, action) => {
        if (action.error.code === ERROR_CODES.SERVICE_UNAVAILABLE) {
          state.data.looker.isSyncing = true;
        } else {
          state.data.looker.isSyncing = false;
        }
      });
  },
});

export const statusesReducer = statusesSlice.reducer;

export const selectStatusesStatus = (state: RootState) => state.statuses.status;

export const selectStatuses = (state: RootState) => state.statuses.data;
