import { hubAPI } from '../../http';
import { DSATeamsData } from '../../types/dsaTeams.types';

/**
 * Fetch DSA Teams
 */
const fetchDSATeams = (signal: AbortSignal) =>
  hubAPI.get<DSATeamsData>('/api/dsa-teams', { signal });

const dsaTeamsAPI = {
  fetchDSATeams,
};

export default dsaTeamsAPI;
