import { NavLink } from 'react-router-dom';
import cn from 'classnames';

interface BreadcrumbsProps {
  breadcrumbs: string[];
  pathname: string;
  searchParams: string;
}

/**
 * Breadcrumb trail for browse route
 */
const Breadcrumbs = ({
  breadcrumbs,
  pathname,
  searchParams,
}: BreadcrumbsProps) => (
  <div className='mb-4 lg:mb-6'>
    {pathname
      .slice(1)
      .split('/')
      .filter(Boolean)
      .reduce((acc, _, i, arr) => {
        // forward slash
        if (i) {
          acc.push(
            <span key={`span-${i}`} className='text-brand-shade-grey'>
              {' '}
              /{' '}
            </span>
          );
        }

        // link
        acc.push(
          <NavLink
            key={`link-${i}`}
            to={'/' + arr.slice(0, i + 1).join('/') + searchParams}
            className={cn({
              'text-brand-shade-grey underline': i < arr.length - 1,
            })}
          >
            {breadcrumbs[i]}
          </NavLink>
        );
        return acc;
      }, [] as ReturnType<typeof NavLink>[])}
  </div>
);

export default Breadcrumbs;
