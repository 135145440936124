import { hubAPI } from '../../http';
import { DSAInsights } from './dsaInsights.slice';

const fetchDSAInsights = (skip: number, signal: AbortSignal) =>
  hubAPI.get<DSAInsights>('/api/dsa-insights', { params: { skip }, signal });

const dsaInsightsAPI = {
  fetchDSAInsights,
};

export default dsaInsightsAPI;
