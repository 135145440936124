import { useAppSelector } from '../../hooks/redux';
import { selectLoading } from '../../state/loading/loading.slice';
import Spinner from '../Spinner/Spinner';

interface GlobalLoadingProps {
  isSuspenseFallback?: boolean;
}

const GlobalLoading = ({ isSuspenseFallback = false }: GlobalLoadingProps) => {
  const isLoading = useAppSelector(selectLoading);
  const spinner = <Spinner.LargeFullScreen />;

  if (isSuspenseFallback) {
    return isLoading ? null : spinner;
  }

  return isLoading ? spinner : null;
};
export default GlobalLoading;
