import { ReactNode, SyntheticEvent, useRef, useState } from 'react';
import Icons from '../Icons/Icons';

interface AccordionProps {
  title: string;
  body: ReactNode;
  index: number;
}

type IsOpen = { isOpen: boolean };
interface AccordionHeaderProps extends Omit<AccordionProps, 'body'>, IsOpen {}
interface AccordionPanelProps extends Omit<AccordionProps, 'title'>, IsOpen {}

/**
 * Accordion Component
 */
const Accordion = ({ title, body, index }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // click handler
  const handleClick = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const activeElement = document.activeElement as HTMLElement;

    if (
      target.closest('[role="region"]') ||
      activeElement.closest('[data-accordionbody]')
    ) {
      return;
    }

    setIsOpen(prev => !prev);
  };

  return (
    <button
      aria-expanded={isOpen}
      aria-controls={`accordion-panel-${index}`}
      className='rounded-2xl bg-brand-white p-4 text-left shadow-md transition-shadow hover:shadow-lg'
      onClick={handleClick}
    >
      <Accordion.Header {...{ title, isOpen, index }} />
      <Accordion.Panel {...{ body, isOpen, index }} />
    </button>
  );
};

export default Accordion;

/**
 * Accordion Header Component
 */
const AccordionHeader = ({ title, isOpen, index }: AccordionHeaderProps) => (
  <div className='flex w-full items-baseline justify-between'>
    <p
      id={`accordion-title-${index}`}
      className='mr-4 basis-11/12 font-semibold'
    >
      {title}
    </p>
    {!isOpen ? (
      <Icons.ChevronDown className='w-5 fill-brand-shade-grey' />
    ) : (
      <Icons.ChevronUp className='w-5 fill-brand-shade-grey' />
    )}
  </div>
);

/**
 * Accordion Panel Component
 */
const AccordionPanel = ({ body, isOpen, index }: AccordionPanelProps) => {
  const panelRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={panelRef}
      id={`accordion-panel-${index}`}
      role='region'
      style={
        isOpen ? { height: panelRef.current?.scrollHeight } : { height: '0px' }
      }
      aria-labelledby={`accordion-title-${index}`}
      className='overflow-hidden transition-all'
    >
      <div className='cursor-text select-text py-2 text-brand-shade-grey'>
        {body}
      </div>
    </div>
  );
};

Accordion.Header = AccordionHeader;
Accordion.Panel = AccordionPanel;
