import { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Folders from '../Folders/Folders';
import MobileFilterButton from '../MobileFilterButton/MobileFilterButton';
import MobileSortAndFilterTakeover from '../MobileSortAndFilterTakeover/MobileSortAndFilterTakeover';
import { BrowseContainerProps } from '../../pages/BrowsePage/types';
import ContentCardsContainer from '../ContentCardsContainer/ContentCardsContainer';

/**
 * Mobile browse component
 */
const MobileBrowseContainer = ({
  pathname,
  searchParams,
  breadcrumbs,
  folders,
  content,
  nestedContent,
}: BrowseContainerProps) => {
  const [
    isMobileSortAndFilterTakeoverOpen,
    setIsMobileSortAndFilterTakeoverOpen,
  ] = useState(false);

  return (
    <>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        pathname={pathname}
        searchParams={`?${searchParams.toString()}`}
      />

      <h1 className='mb-4'>Browse</h1>
      <div className='mb-4 flex justify-end'>
        <MobileFilterButton
          handleOpenScreenTakeover={() =>
            setIsMobileSortAndFilterTakeoverOpen(true)
          }
        />
      </div>

      {/* folders */}
      <Folders
        folders={folders}
        {...(content.length && { containerClassNames: 'mb-4' })}
      />

      {/* content */}
      <ContentCardsContainer content={content} />

      {isMobileSortAndFilterTakeoverOpen && (
        <MobileSortAndFilterTakeover
          handleCloseScreenTakeover={() =>
            setIsMobileSortAndFilterTakeoverOpen(false)
          }
          content={nestedContent}
          isDropdownDisabled={!content.length}
        />
      )}
    </>
  );
};

export default MobileBrowseContainer;
