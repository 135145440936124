import { useAppSelector } from '../../hooks/redux';
import useHandleFavoriteOrPinOrAppOrContentClick from '../../hooks/useHandleFavoriteOrPinOrAppOrContentClick';
import { selectContentMap } from '../../state/content/content.slice';
import {
  selectFavoritesInFlight,
  selectPinnedFavoritesInFlight,
  selectUser,
  selectUserDataMaps,
} from '../../state/user/user.slice';
import { EMPTY_STATE_MESSAGES, PAGE_TITLES } from '../../utils/constants';
import Card from '../Card/Card';
import CardRow from '../CardRow/CardRow';

/**
 * FavoritesContainer component
 */
const FavoritesContainer = () => {
  const favoritesInFlight = useAppSelector(selectFavoritesInFlight);
  const pinnedFavoritesInFlight = useAppSelector(selectPinnedFavoritesInFlight);
  const { pinnedFavorites, favorites } = useAppSelector(selectUser);
  const { pinnedFavoritesMap } = useAppSelector(selectUserDataMaps);
  const contentMap = useAppSelector(selectContentMap);
  const handleFavoriteOrPinOrAppOrContentClick =
    useHandleFavoriteOrPinOrAppOrContentClick();

  return (
    <div className='mx-auto max-w-screen-md'>
      <h1 className='mb-8'>{PAGE_TITLES.FAVORITES}</h1>
      {pinnedFavorites.length || favorites.length ? (
        <section
          onClick={handleFavoriteOrPinOrAppOrContentClick}
          className='grid gap-4'
        >
          {[...pinnedFavorites, ...favorites].map(_id => (
            <Card key={_id} classNames='p-2'>
              <CardRow
                {...{
                  contentItem: contentMap[_id],
                  isFavorite: true,
                  isFavoriteButtonDisabled:
                    !!favoritesInFlight[_id] || !!pinnedFavoritesInFlight[_id],
                  isPinnable: true,
                  isPinned: !!pinnedFavoritesMap[_id],
                  isPinnedButtonDisabled:
                    !!pinnedFavoritesInFlight[_id] || !!favoritesInFlight[_id],
                  isFavoritesPage: true,
                }}
              />
            </Card>
          ))}
        </section>
      ) : (
        <div>{EMPTY_STATE_MESSAGES.NO_FAVORITES}</div>
      )}
    </div>
  );
};

export default FavoritesContainer;
