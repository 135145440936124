import { FunctionComponent, SVGProps } from 'react';
import Icons from '../components/Icons/Icons';
import { SYSLOG_SEVERITY, PRETTY_SYSLOG_SEVERITY } from '../types/syslog.types';

interface ISeverityIconAndClassNames {
  backgroundClassNames: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  darkClassNames: string;
  lightClassNames: string;
  prettySeverity: PRETTY_SYSLOG_SEVERITY;
}

const issueOrInfoClassNamesDark =
  '[&>circle.icon-dot]:fill-brand-black [&>circle.icon-outline]:stroke-brand-black [&>rect]:fill-brand-black';
const issueOrInfoClassNamesLight =
  '[&>circle.icon-dot]:fill-brand-white [&>circle.icon-outline]:stroke-brand-white [&>rect]:fill-brand-white';

/**
 * Get an object for severity icon and class names
 */
const getSeverityIconAndClassNames = (
  severity: SYSLOG_SEVERITY
): ISeverityIconAndClassNames => {
  const data: Partial<ISeverityIconAndClassNames> = {
    backgroundClassNames: '',
    darkClassNames: '',
    lightClassNames: '',
    prettySeverity:
      severity === SYSLOG_SEVERITY.RESOLVED
        ? PRETTY_SYSLOG_SEVERITY.RESOLVED
        : severity === SYSLOG_SEVERITY.ISSUE
        ? PRETTY_SYSLOG_SEVERITY.ISSUE
        : PRETTY_SYSLOG_SEVERITY.INFO,
  };

  // background class names
  data.backgroundClassNames =
    severity === SYSLOG_SEVERITY.RESOLVED
      ? 'bg-brand-shade-green'
      : severity === SYSLOG_SEVERITY.ISSUE
      ? 'bg-brand-red'
      : 'bg-brand-shade-blue';

  // icon and dark/light class names
  if (severity === SYSLOG_SEVERITY.RESOLVED) {
    data.Icon = Icons.CheckboxTickCircle;
    data.darkClassNames = 'stroke-brand-black';
    data.lightClassNames = 'stroke-brand-white';
  } else if (severity === SYSLOG_SEVERITY.ISSUE) {
    data.Icon = Icons.IssueOrInfo;
    data.darkClassNames = issueOrInfoClassNamesDark;
    data.lightClassNames = issueOrInfoClassNamesLight;
  } else {
    data.Icon = Icons.IssueOrInfo;
    data.darkClassNames = `${issueOrInfoClassNamesDark} rotate-180`;
    data.lightClassNames = `${issueOrInfoClassNamesLight} rotate-180`;
  }

  return data as ISeverityIconAndClassNames;
};

export default getSeverityIconAndClassNames;
