import cn from 'classnames';
import { FolderMeta } from '../../pages/BrowsePage/types';
import Folder from '../Folder/Folder';

interface FoldersProps {
  folders: FolderMeta[];
  containerClassNames?: string;
}

/**
 * Folders section
 */
const Folders = ({ folders, containerClassNames }: FoldersProps) => {
  return (
    <section
      className={cn(
        'grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4',
        containerClassNames
      )}
    >
      {folders.map(({ name, itemCount }) => (
        <Folder key={name} name={name} itemCount={itemCount} />
      ))}
    </section>
  );
};
export default Folders;
