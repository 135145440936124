import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

export enum DSA_INSIGHTS_CARD_SIZE {
  LARGE = 'large',
  SMALL = 'small',
}

interface DSAInsightsCardProps {
  to: string;
  image: string;
  subtitle?: string; // e.g. the team's name
  date: string;
  title: string;
  size?: DSA_INSIGHTS_CARD_SIZE;
}

/**
 * A card specific to DSA Insights
 */
const DSAInsightsCard = ({
  to,
  image,
  subtitle,
  date,
  title,
}: DSAInsightsCardProps) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={to}
      className={cn(
        'overflow-hidden rounded-2xl bg-brand-white shadow-sm transition-shadow hover:shadow-lg',

        pathname !== PATHS.home && 'h-56'
      )}
    >
      <img
        src={image}
        alt={`FIXME - ${title}`}
        className='h-20 w-full object-cover'
      />
      <div
        className={cn(
          'h-[7.5rem]  p-4',
          pathname === PATHS.home && 'bg-brand-tint-grey-1'
        )}
      >
        <div>
          {subtitle && <p className='line-clamp-1'>{subtitle}</p>}
          <p className='line-clamp-1 text-sm text-brand-shade-grey'>{date}</p>
        </div>
        <p className='line-clamp-3 font-semibold'>{title}</p>
      </div>
    </Link>
  );
};

export default DSAInsightsCard;
