import { Content } from './content.types';
import { WithObjectId } from '.';

/**
 * Search param keys for Browse and Favorites pages
 */
export enum SORT_OPTIONS {
  AZ = 'az',
  ZA = 'za',
  NEWEST = 'newest',
  OLDEST = 'oldest',
}

/**
 * Search param keys for Search Results page
 * @note includes relevance
 */
export enum SEARCH_SORT_OPTIONS {
  AZ = 'az',
  ZA = 'za',
  NEWEST = 'newest',
  OLDEST = 'oldest',
  RELEVANCE = 'relevance',
}

const sortOptions = Object.freeze(Object.values(SORT_OPTIONS));
export type SortBy = (typeof sortOptions)[number];

/**
 * Sort by metadata
 */
export interface SortByMeta {
  label: string;
  value: SORT_OPTIONS;
  sort(a: WithObjectId<Content>, b: WithObjectId<Content>): number;
}

export enum SORT_BY_LABELS {
  AZ = 'A-Z',
  ZA = 'Z-A',
  NEWEST = 'Newest',
  OLDEST = 'Oldest',
  RELEVANCE = 'Relevance',
}

export const SEARCH_SORT_BY_OPTIONS = [
  { title: SORT_BY_LABELS.RELEVANCE, value: SEARCH_SORT_OPTIONS.RELEVANCE },
  { title: SORT_BY_LABELS.AZ, value: SEARCH_SORT_OPTIONS.AZ },
  { title: SORT_BY_LABELS.ZA, value: SEARCH_SORT_OPTIONS.ZA },
  { title: SORT_BY_LABELS.NEWEST, value: SEARCH_SORT_OPTIONS.NEWEST },
  { title: SORT_BY_LABELS.OLDEST, value: SEARCH_SORT_OPTIONS.OLDEST },
];

export const BROWSE_AND_FAVORITES_SORT_BY_OPTIONS = [
  { title: SORT_BY_LABELS.AZ, value: SEARCH_SORT_OPTIONS.AZ },
  { title: SORT_BY_LABELS.ZA, value: SEARCH_SORT_OPTIONS.ZA },
  { title: SORT_BY_LABELS.NEWEST, value: SEARCH_SORT_OPTIONS.NEWEST },
  { title: SORT_BY_LABELS.OLDEST, value: SEARCH_SORT_OPTIONS.OLDEST },
];
