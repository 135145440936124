import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { ReactElement, lazy, useLayoutEffect } from 'react';
import { reactPlugin } from '../../applicationInsights';
import { isProd } from '../../utils/isEnv';
import Oops from '../Oops/Oops';
import { ERROR_BOUNDARY_EVENT } from '../../utils/constants';

const LocalErrorBoundary = lazy(() => import('./LocalErrorBoundary'));

/**
 * Dynamic error boundary based on local vs cloud
 */
const ErrorBoundary = ({ children }: { children: ReactElement }) => {
  useLayoutEffect(() => {
    // create custom event
    const errorBoundaryEvent = new CustomEvent(ERROR_BOUNDARY_EVENT);
    // dispatch the event
    window.dispatchEvent(errorBoundaryEvent);
  }, []);

  return isProd() ? (
    <AppInsightsErrorBoundary onError={Oops} appInsights={reactPlugin}>
      {children}
    </AppInsightsErrorBoundary>
  ) : (
    <LocalErrorBoundary fallbackUI={Oops}>{children}</LocalErrorBoundary>
  );
};

export default ErrorBoundary;
