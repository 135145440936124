const encode = (str: string) =>
  str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, ' ')
    .split(' ')
    .reduce((acc, cur) => {
      let str;
      if ((str = cur.trim())) {
        acc.push(str);
      }
      return acc;
    }, [] as string[])
    .join('-');

export default encode;
