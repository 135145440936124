import { PropsWithChildren } from 'react';
import cn from 'classnames';
import Icons from '../Icons/Icons';

interface FavoriteButtonProps extends PropsWithChildren {
  isFavorite: boolean | undefined;
  _id: string;
  classNames: string;
  isDisabled: boolean;
}

/**
 * Favorite button for `ContentCard` and `CardRow`
 */
const FavoriteButton = ({
  isFavorite,
  _id,
  classNames,
  isDisabled,
}: FavoriteButtonProps) => {
  return (
    <button
      className={cn(classNames, isDisabled && 'cursor-progress')}
      data-id={_id}
      title={isFavorite ? 'Remove favorite' : 'Add favorite'}
      disabled={isDisabled}
    >
      <Icons.Star
        data-testid='star'
        className={cn(
          isDisabled
            ? 'fill-brand-grey stroke-brand-grey'
            : isFavorite
            ? 'fill-brand-green stroke-brand-green'
            : 'stroke-brand-black'
        )}
      />
    </button>
  );
};

export default FavoriteButton;
