import { Link } from 'react-router-dom';

import cn from 'classnames';
import { Content } from '../../types/content.types';
import { WithObjectId } from '../../types';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import encode from '../../utils/encode';
import getContentTypeIcon from '../../utils/getContentTypeIcon';
import PinButton from '../PinButton/PinButton';
import Icons from '../Icons/Icons';
import { CONTENT_TYPES } from '../../utils/constants';
import getJoinedFolderPath from '../../utils/getJoinedFolderPath';

interface CardRowProps {
  contentItem: WithObjectId<Content>;
  isFavorite: boolean;
  isFavoriteButtonDisabled: boolean;
  isPinnable?: boolean;
  isPinned?: boolean;
  isPinnedButtonDisabled?: boolean;
  isFavoritesPage?: boolean;
}
const cardRowClassNames = 'flex grow flex-col py-4 pl-4';
/**
 * Rows for Favorites and Popular content Cards on HomePage and for FavoritesContainer on Favorites Page
 */
const CardRow = ({
  contentItem,
  isFavorite,
  isFavoriteButtonDisabled,
  isPinnable = false,
  isPinned = false,
  isPinnedButtonDisabled = false,
  isFavoritesPage = false,
}: CardRowProps) => {
  const { _id, title, type } = contentItem;

  return (
    <div
      className={cn(
        'flex h-16 items-center',
        !isFavoritesPage && 'border-b-2 border-brand-tint-grey-2'
      )}
    >
      {type.name === CONTENT_TYPES.APP ? (
        <div
          className={cn(cardRowClassNames, 'cursor-pointer')}
          data-appid={_id}
          data-testid='app-card'
          role='link'
        >
          <CardRow.Child {...{ isFavoritesPage, contentItem }} />
        </div>
      ) : (
        <Link
          id={_id}
          to={`/content/${_id}-${encode(title)}`}
          className={cardRowClassNames}
        >
          <CardRow.Child {...{ isFavoritesPage, contentItem }} />
        </Link>
      )}
      {isPinnable && (
        <PinButton
          {...{
            isPinned,
            isDisabled: isPinnedButtonDisabled,
            _id,
            classNames: 'py-4 pl-4 pr-2 lg:pr-4',
          }}
        />
      )}
      <FavoriteButton
        {...{
          isFavorite,
          isDisabled: isFavoriteButtonDisabled,
          _id,
          classNames: 'py-4 pl-2 pr-4 lg:pl-4',
        }}
      />
    </div>
  );
};

export default CardRow;

interface CardRowChildProps {
  contentItem: WithObjectId<Content>;
  isFavoritesPage: boolean;
}
/**
 * CardRowChild component
 */
const CardRowChild = ({
  isFavoritesPage,
  contentItem: { title, type, folderPath },
}: CardRowChildProps) => {
  const joinedFolderPath = getJoinedFolderPath(folderPath);
  return (
    <>
      {/* Content type icon */}
      <div className='mb-1 flex items-center'>
        <div className='mr-3 hidden lg:block'>
          {getContentTypeIcon(type.name)}
        </div>
        <p className='line-clamp-1 font-medium'>{title}</p>
      </div>

      {/* Folder path for Favorites page */}
      {isFavoritesPage && (
        <div className='relative lg:ml-1' title={joinedFolderPath}>
          <Icons.FolderIcon className='absolute top-px h-3 w-3 stroke-brand-shade-grey' />
          <p className='ml-5 line-clamp-1 text-xs text-brand-shade-grey lg:ml-7'>
            {joinedFolderPath}
          </p>
        </div>
      )}
    </>
  );
};

CardRow.Child = CardRowChild;
