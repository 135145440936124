import { hubAPI } from '../../http';
import { ObjectIdString } from '../../types';
import { AdminTags } from './adminTags.slice';

/**
 * Fetch all tags
 */
const fetchTags = (signal: AbortSignal) =>
  hubAPI.get<AdminTags>('/api/admin/tags', { signal });

/**
 * Delete single tag
 */
const deleteTag = (tagId: ObjectIdString, signal: AbortSignal) =>
  hubAPI.delete<AdminTags>(`/api/admin/tags/${tagId}`, {
    signal,
  });

const adminTagsAPI = {
  fetchTags,
  deleteTag,
};
export default adminTagsAPI;
