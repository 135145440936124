import {
  ApplicationInsights,
  IConfiguration,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { AxiosError } from 'axios';
import {
  X_MS_TOKEN_AAD_ID_TOKEN,
  X_MS_TOKEN_AAD_ACCESS_TOKEN,
} from '../utils/constants';
import localOverride from './localOverride';

// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
export const reactPlugin = new ReactPlugin();

/**
 * Application Insights wrapper
 *
 * - Config docs: https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-sdk-advanced#sdk-configuration
 */
class AppInsights {
  private static instance: ApplicationInsights;

  static init() {
    if (AppInsights.instance) return;

    AppInsights.instance = new ApplicationInsights({
      config: {
        // setup
        connectionString:
          process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        appId: process.env.REACT_APP_AUTH_CLIENT_ID,
        namePrefix: 'applicationInsights',
        extensions: [reactPlugin],

        // tracking and correlation
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
        enableCorsCorrelation: true,
        ignoreHeaders: [X_MS_TOKEN_AAD_ID_TOKEN, X_MS_TOKEN_AAD_ACCESS_TOKEN],

        // exclusions
        correlationHeaderExcludedDomains: [
          'localhost',
          'microsoftonline',
          'looker',
        ],

        // misc
        autoTrackPageVisitTime: true,
        enableAjaxErrorStatusText: true,
      } as IConfiguration,
    });

    AppInsights.instance.loadAppInsights();
    AppInsights.instance.trackPageView();
  }

  @localOverride
  static trackException(exception: Error | AxiosError) {
    AppInsights.instance.trackException({ exception });
  }
}

export default AppInsights;
