import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  MEDIA_QUERIES,
  SORT_AND_FILTER_TITLES,
} from '../../../utils/constants';
import ClearFiltersButton from '../../ClearFiltersButton/ClearFiltersButton';

interface ButtonsProps {
  handleCloseButton: () => void;
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  setStagedSelectedTags:
    | React.Dispatch<React.SetStateAction<string[]>>
    | undefined;
}

/**
 * Apply/Done and Clear filters buttons for `FilterByTagsTakeover`
 */
const Buttons = ({
  handleCloseButton,
  setSelectedTags,
  setStagedSelectedTags,
}: ButtonsProps) => {
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);

  const applyButtonTitle = isDesktop
    ? SORT_AND_FILTER_TITLES.APPLY
    : SORT_AND_FILTER_TITLES.DONE;

  return (
    <div className='mt-auto flex w-full gap-4'>
      <button
        title={applyButtonTitle}
        onClick={handleCloseButton}
        className='h-14 w-1/2 grow rounded-full bg-brand-green font-medium text-brand-white'
      >
        {applyButtonTitle}
      </button>
      <ClearFiltersButton
        handleClearClick={() => {
          setSelectedTags([]);
          setStagedSelectedTags && setStagedSelectedTags([]);
        }}
        title={SORT_AND_FILTER_TITLES.CLEAR_TAGS}
      />
    </div>
  );
};

export default Buttons;
