import { useCallback, useEffect, useRef, useState } from 'react';
import {
  SORT_AND_FILTER_TITLES,
  EVENTS,
  FITLER_BY_TAGS_TAKEOVER,
  TAGS_SEARCH_INPUT,
  MEDIA_QUERIES,
  KEYS,
  SCROLL_CONTAINER,
} from '../../utils/constants';
import getUnselectedTagsFromContentArr from '../../utils/getUnselectedTagsFromContentArr';
import ScreenTakeover from '../ScreenTakeover/ScreenTakeover';
import BodyLock from '../BodyLock/BodyLock';
import useContentURLParams from '../../hooks/useContentURLParams';
import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import filterContentArrayByTags from '../../utils/filterContentArrayByTags';
import tabTrapCurried from '../../utils/tabTrapCurried';
import useMediaQuery from '../../hooks/useMediaQuery';
import useIsScrolledToBottom from '../../hooks/useIsScrolledToBottom';
import { useAppDispatch } from '../../hooks/redux';
import { toggleIsFilterByTagsTakeoverOpen } from '../../state/widgets/widgets.slice';
// nested child components exclusive to this parent component
import Controls from './Controls/Controls';
import SelectedTags from './SelectedTags/SelectedTags';
import UnselectedTags from './UnselectedTags/UnselectedTags';
import Buttons from './Buttons/Buttons';

interface FilterByTagsTakeoverProps {
  content: WithObjectId<Content>[];
  setStagedSelectedTags?: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * Filter by tags takeover for `DesktopSortAndFilterContainer` and `MobileSortAndFilterTakeover`
 * @note when open in mobile this component overlays its parent component `MobileSortAndFilterTakeover`
 * @note in mobile, optional prop `setStagedSelectedTags` is used to stage selected tags in parent component `MobileSortAndFilterTakeover`
 */
const FilterByTagsTakeover = ({
  content,
  setStagedSelectedTags,
}: FilterByTagsTakeoverProps) => {
  const dispatch = useAppDispatch();
  const {
    searchParams: { tagSearchParams },
    setters: { setTagSearchParams },
  } = useContentURLParams();
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState(tagSearchParams);
  const [unselectedTags, setUnselectedTags] = useState<string[]>([]);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const isLandscape = useMediaQuery(MEDIA_QUERIES.LANDSCAPE);
  const filterByTagsRef = useRef<HTMLDivElement>(null);
  const isScrolledToBottom = useIsScrolledToBottom(
    SCROLL_CONTAINER.UNSELECTED_TAGS
  );

  /**
   * Close this screen takeover
   */
  const closeFilterByTagsTakeover = useCallback(
    () => dispatch(toggleIsFilterByTagsTakeoverOpen(false)),
    [dispatch]
  );

  /**
   * Handle blur
   */
  useEffect(() => {
    //if mobile, short circuit
    if (!isDesktop) return;

    const handleBlur = (e: MouseEvent) => {
      // if takeover is blurred in desktop, close
      if (
        (e.target as HTMLElement).id === SORT_AND_FILTER_TITLES.FILTER_BY_TAGS
      ) {
        closeFilterByTagsTakeover();
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [closeFilterByTagsTakeover, isDesktop]);

  /**
   * Tab trap
   */
  useEffect(() => {
    //if mobile, short circuit
    if (!isDesktop) return;

    const tabTrap = tabTrapCurried(`#${FITLER_BY_TAGS_TAKEOVER}`);

    const clickFocusedElementOnEnter = (e: KeyboardEvent) => {
      if (e.key === KEYS.ENTER) {
        const activeElID = document.activeElement?.id;

        if (activeElID && activeElID !== TAGS_SEARCH_INPUT)
          document.getElementById(activeElID)?.click();
      }
    };

    const { current: filterByTagsRefCurrent } = filterByTagsRef;
    filterByTagsRefCurrent?.addEventListener(EVENTS.KEYDOWN, tabTrap);
    filterByTagsRefCurrent?.addEventListener(
      EVENTS.KEYDOWN,
      clickFocusedElementOnEnter
    );

    return () => {
      filterByTagsRefCurrent?.removeEventListener(EVENTS.KEYDOWN, tabTrap);
      filterByTagsRefCurrent?.removeEventListener(
        EVENTS.KEYDOWN,
        clickFocusedElementOnEnter
      );
    };
  }, [isDesktop]);

  /**
   * Update unselected tags to only include tags found in search results content items
   */
  useEffect(() => {
    const contentWithSelectedTags = filterContentArrayByTags(
      content,
      selectedTags
    );

    const updatedUnselectedTags = getUnselectedTagsFromContentArr(
      contentWithSelectedTags,
      selectedTags
    );
    setUnselectedTags(updatedUnselectedTags);
  }, [content, selectedTags]);

  /**
   * Toggle whether a tag is selected
   */
  const toggleIsSelected = (tag: string) => {
    //if tag is not in the selected tags array
    if (!selectedTags.includes(tag)) {
      //remove tag from unselected tags array
      const updatedTags = [...unselectedTags];
      updatedTags.splice(unselectedTags.indexOf(tag), 1);
      setUnselectedTags(updatedTags);

      //add tag to selected tags array
      setSelectedTags([...selectedTags, tag].sort());
    } else {
      //remove tag from selected tags array
      const updatedSelectedTags = [...selectedTags];
      updatedSelectedTags.splice(selectedTags.indexOf(tag), 1);
      setSelectedTags(updatedSelectedTags);

      //add tag to unselected tags array
      setUnselectedTags([...unselectedTags, tag].sort());
    }
  };

  /**
   * Done (mobile) or Apply (desktop) handler
   */
  const handleCloseButton = () => {
    if (isDesktop) {
      //if desktop, immediately apply selected tags as tag search params
      setTagSearchParams(selectedTags);
    } else {
      //if mobile, stage selected tags until `Apply` button is clicked in parent
      setStagedSelectedTags && setStagedSelectedTags(selectedTags);
    }
    closeFilterByTagsTakeover();
  };

  return (
    <div ref={filterByTagsRef}>
      <ScreenTakeover
        id={FITLER_BY_TAGS_TAKEOVER}
        handleCloseScreenTakeover={closeFilterByTagsTakeover}
        title={SORT_AND_FILTER_TITLES.FILTER_BY_TAGS}
        zIndex='z-30'
        {...((isDesktop || !isLandscape) && { classNames: 'flex flex-col' })}
      >
        <FilterByTagsTakeover.Controls
          handleCloseScreenTakeover={closeFilterByTagsTakeover}
          inputValue={inputValue}
          setInputValue={setInputValue}
          toggleIsSelected={toggleIsSelected}
          unselectedTags={unselectedTags}
        />
        {/* Selected tags */}
        <FilterByTagsTakeover.SelectedTags
          selectedTags={selectedTags}
          toggleIsSelected={toggleIsSelected}
        />
        {/* Unselected tags */}
        <FilterByTagsTakeover.UnselectedTags
          unselectedTags={unselectedTags}
          toggleIsSelected={toggleIsSelected}
          isScrolledToBottom={isScrolledToBottom}
        />
        {/* Done/Apply and Clear filters buttons */}
        <FilterByTagsTakeover.Buttons
          handleCloseButton={handleCloseButton}
          setSelectedTags={setSelectedTags}
          setStagedSelectedTags={setStagedSelectedTags}
        />
      </ScreenTakeover>
      <BodyLock />
    </div>
  );
};

FilterByTagsTakeover.Controls = Controls;
FilterByTagsTakeover.SelectedTags = SelectedTags;
FilterByTagsTakeover.UnselectedTags = UnselectedTags;
FilterByTagsTakeover.Buttons = Buttons;

export default FilterByTagsTakeover;
