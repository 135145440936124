import { SyntheticEvent } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import useMediaQuery from '../../hooks/useMediaQuery';
import {
  selectIsNavSliderOpen,
  toggleIsNavSliderOpen,
} from '../../state/widgets/widgets.slice';
import { EVENTS, MEDIA_QUERIES, NAV } from '../../utils/constants';
import Icons from '../Icons/Icons';

/**
 * Hamburger button for `NavSlider`
 */
const NavSliderButton = () => {
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const isNavSliderOpen = useAppSelector(selectIsNavSliderOpen);
  const dispatch = useAppDispatch();

  const handleButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    const ev = new Event(EVENTS.CLICK);
    window.dispatchEvent(ev);

    !isDesktop && window.scrollTo({ top: 0 });
    dispatch(toggleIsNavSliderOpen(!isNavSliderOpen));
  };

  return (
    <button
      id={NAV.SLIDER_BUTTON}
      title={`${isNavSliderOpen ? 'Close' : 'Open'} navigation`}
      onClick={handleButtonClick}
      className='p-[--layout-padding] lg:mr-8 lg:px-[--lg-layout-padding]'
    >
      {isNavSliderOpen ? (
        <Icons.Close className='h-5 w-5 fill-brand-black' />
      ) : (
        <Icons.HamburgerMenu className='h-5 w-5 fill-brand-black' />
      )}
    </button>
  );
};

export default NavSliderButton;
