import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { toggleIsNotificationsOpen } from '../../state/widgets/widgets.slice';
import BodyLock from '../BodyLock/BodyLock';
import { selectUser } from '../../state/user/user.slice';
import {
  NO_NEW_ACTIVITY,
  NOTIFICATIONS,
  NOTIFICATIONS_SCROLL_CONTAINER,
  OVERFLOW_GRADIENT,
  NOTIFICATIONS_TITLE,
  EVENTS,
} from '../../utils/constants';
import NotificationCards from '../NotificationCards/NotificationCards';
import Icons from '../Icons/Icons';
import useIsScrolledToBottom from '../../hooks/useIsScrolledToBottom';
import MarkAllAsReadOrUnreadButton from '../MarkAllAsReadOrUnreadButton/MarkAllAsReadOrUnreadButton';

export const DESKTOP_MASKING_CONTAINER = 'desktop-masking-container';
export const BUTTON_FACADE = 'button-facade';

/**
 * Desktop notifications dropdown
 *
 * @see MobileNotificationsDrawer for mobile notifications
 */
const DesktopNotificationsDropdown = () => {
  const notificationsContainerRef = useRef<HTMLOListElement>(null);
  const { unreadNotifications, readNotifications } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const isScrolledToBottom = useIsScrolledToBottom(
    NOTIFICATIONS_SCROLL_CONTAINER
  );

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      const maybeDropdown = (e.target as HTMLElement).closest?.(
        '[role="dialog"]'
      );

      if (maybeDropdown) return;

      dispatch(toggleIsNotificationsOpen(false));
    };

    window.addEventListener(EVENTS.CLICK, clickHandler);

    return () => window.removeEventListener(EVENTS.CLICK, clickHandler);
  }, [dispatch]);

  const hasNotifications = !!(
    unreadNotifications.length || readNotifications.length
  );

  return (
    <>
      <div
        role='dialog'
        aria-labelledby={NOTIFICATIONS_TITLE}
        className={cn(
          'absolute right-1.5 top-[4.5rem] z-20 flex h-120 w-[30rem] flex-col rounded-3xl bg-brand-tint-grey-1 px-4 pb-4 pt-8 shadow-center-xl',
          {
            [`${OVERFLOW_GRADIENT} after:rounded-b-3xl`]:
              hasNotifications && !isScrolledToBottom,
          }
        )}
      >
        {/* notch */}
        <div className='absolute -top-2 right-3.5 h-11 w-11 rotate-45 bg-brand-tint-grey-1' />

        <div
          className={cn(
            'flex items-center justify-between',
            hasNotifications ? 'mb-6' : 'mb-12'
          )}
        >
          <h1 id={NOTIFICATIONS_TITLE} className='ml-2'>
            {NOTIFICATIONS}
          </h1>
          {/* Mark all as read or unread button */}
          {hasNotifications && <MarkAllAsReadOrUnreadButton />}
        </div>

        {hasNotifications ? (
          <ol
            ref={notificationsContainerRef}
            className='thin-scrollbar flex flex-col gap-4 overflow-y-auto px-8 pb-4'
            id={NOTIFICATIONS_SCROLL_CONTAINER}
          >
            <NotificationCards
              {...{ unreadNotifications, readNotifications }}
            />
          </ol>
        ) : (
          <div className='px-12'>
            <Icons.NoActivity className='mb-10' />
            <p className='text-center text-2xl font-medium text-brand-grey'>
              {NO_NEW_ACTIVITY}
            </p>
          </div>
        )}
      </div>
      <BodyLock />
    </>
  );
};

export default DesktopNotificationsDropdown;
