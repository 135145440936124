import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import useContentURLParams from '../../hooks/useContentURLParams';
import { ReactComponent as GreenFilterIcon } from '../../images/green-filter-icon.svg';
import { ReactComponent as WhiteFilterIcon } from '../../images/white-filter-icon.svg';
import { PATHS, SORT_AND_FILTER_TITLES } from '../../utils/constants';
import SORTBY from '../../utils/sortBy';
import { SEARCH_SORT_OPTIONS } from '../../types/sort.types';

export const OPEN_FILTER_OPTIONS = 'Open filter options';
export const FILTER_BUTTON_ICON = 'filter-btn-icon';

/**
 * Mobile filter button for Search results, Browse and Favorites pages
 */
const MobileFilterButton = ({
  handleOpenScreenTakeover,
}: {
  handleOpenScreenTakeover: () => void;
}) => {
  const {
    searchParams: { sortSearchParam, tagSearchParams },
  } = useContentURLParams();
  const [isActive, setIsActive] = useState(false);
  const isSearchResultsMatch = useMatch(PATHS.searchResults);

  useEffect(() => {
    if (!sortSearchParam) return;

    if (isSearchResultsMatch) {
      setIsActive(
        !!tagSearchParams.length ||
          Object.keys(SORTBY).includes(sortSearchParam)
      );
    } else {
      const sortOptions: string[] = [
        SEARCH_SORT_OPTIONS.ZA,
        SEARCH_SORT_OPTIONS.OLDEST,
        SEARCH_SORT_OPTIONS.NEWEST,
      ];
      setIsActive(
        !!tagSearchParams.length || sortOptions.includes(sortSearchParam)
      );
    }
  }, [isSearchResultsMatch, sortSearchParam, tagSearchParams.length]);

  return (
    <button
      title={OPEN_FILTER_OPTIONS}
      onClick={handleOpenScreenTakeover}
      className={cn(
        'flex items-center justify-self-end rounded-full p-4 shadow-sm transition-shadow hover:shadow-lg',
        isActive ? 'bg-brand-green' : 'bg-brand-white'
      )}
    >
      <div className='mr-2' data-testid={FILTER_BUTTON_ICON}>
        {isActive ? <WhiteFilterIcon /> : <GreenFilterIcon />}
      </div>
      <p className={cn('font-medium', isActive && 'text-brand-white')}>
        {SORT_AND_FILTER_TITLES.FILTER}
      </p>
    </button>
  );
};

export default MobileFilterButton;
