import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WithObjectId } from '../../types';
import { DSAInsightModel } from '../../types/home.types';
import { STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import { fetchApplicationStateUpdates } from '../user/user.slice';
import homeAPI from './home.api';

export interface Home {
  popularContent: string[];
  popularTags: string[];
  dsaInsights: WithObjectId<DSAInsightModel>[];
}

export interface HomeState {
  status: STATE_STATUSES;
  data: Home;
}

export const initialState: HomeState = {
  status: STATE_STATUSES.INITIAL,
  data: {
    popularContent: [],
    popularTags: [],
    dsaInsights: [
      {
        _id: '',
        author: '',
        content: '',
        createdAt: '',
        hero: '',
        publishDate: '',
        slug: '',
        title: '',
        updatedAt: '',
      },
    ],
  },
};

export const fetchHome = createAsyncThunk(
  'home/fetchHome',
  async (_, thunkAPI) => {
    const response = await homeAPI.fetchHome(thunkAPI.signal);

    return response.data;
  }
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchHome.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchHome.fulfilled, (state, action) => {
        return {
          status: STATE_STATUSES.FULFILLED,
          data: action.payload,
        };
      })
      .addCase(fetchHome.rejected, (state, action) => {
        if (!action.meta.aborted) {
          // update status for `error.slice`
          state.status = STATE_STATUSES.REJECTED;
        }
      })
      //update based on application state updates interval request
      .addCase(fetchApplicationStateUpdates.fulfilled, (state, action) => {
        const { popularContent, popularTags } = action.payload;

        if (popularContent) {
          state.data.popularContent = popularContent;
        }

        if (popularTags) {
          state.data.popularTags = popularTags;
        }
      });
  },
});

export const homeReducer = homeSlice.reducer;

export const selectHomeStatus = (state: RootState) => state.home.status;

export const selectHomeData = (state: RootState) => state.home.data;
