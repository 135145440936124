import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Folders from '../Folders/Folders';
import DesktopSortAndFilterContainer from '../DesktopSortAndFilterContainer/DesktopSortAndFilterContainer';
import { BrowseContainerProps } from '../../pages/BrowsePage/types';
import ContentCardsContainer from '../ContentCardsContainer/ContentCardsContainer';

/**
 * Desktop browse component
 */
const DesktopBrowseContainer = ({
  pathname,
  searchParams,
  breadcrumbs,
  folders,
  content,
  nestedContent,
}: BrowseContainerProps) => (
  <>
    <Breadcrumbs
      breadcrumbs={breadcrumbs}
      pathname={pathname}
      searchParams={`?${searchParams.toString()}`}
    />

    <h1 className='mb-4'>Browse</h1>

    <div className='mb-4 flex justify-end'>
      <DesktopSortAndFilterContainer
        {...{ content: nestedContent, isDropdownDisabled: !content.length }}
      />
    </div>

    {/* folders */}
    <Folders
      folders={folders}
      {...(content.length && { containerClassNames: 'mb-4' })}
    />

    {/* content */}
    <ContentCardsContainer content={content} />
  </>
);

export default DesktopBrowseContainer;
