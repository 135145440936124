import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';

/**
 * Properties by which to sort content items
 */
export enum SORT_PROPERTIES {
  DATE_ADDED = 'dateAdded',
  TITLE = 'title',
}

/**
 * @property {WithObjectId<Content>} content - Content for current folder
 * @property {string} name - Current folder name
 * @property {Record<string, Folder>} `[folderName]` - Nested folder
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Folder = Record<string, any>;

/**
 * Folder metadata
 */
export interface FolderMeta {
  name: string;
  itemCount: number;
}

/**
 * Current folder
 */
export interface CurrentFolder {
  breadcrumbs: string[];
  folders: FolderMeta[];
  content: WithObjectId<Content>[];
}

/**
 * interface for DesktopBrowseContainer and MobileBrowseContainer
 */
export interface BrowseContainerProps {
  pathname: string;
  searchParams: URLSearchParams;
  breadcrumbs: string[];
  folders: FolderMeta[];
  content: WithObjectId<Content>[];
  nestedContent: WithObjectId<Content>[];
}
