/**
 * Sort folders and content
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const alphaSortByProperty = (arr: any[], propertyName: string) =>
  arr.sort((a, b) => {
    const aLower = a[propertyName].toLowerCase();
    const bLower = b[propertyName].toLowerCase();

    return aLower < bLower ? -1 : aLower > bLower ? 1 : 0;
  });

export default alphaSortByProperty;
