import { WithObjectId } from '../../../types';
import { Content } from '../../../types/content.types';
import { BROWSE_ROUTE_ROOT_FOLDER } from '../../../utils/constants';
import encode from '../../../utils/encode';
import { Folder } from '../types';

/**
 * Nested folder structure for root browse route
 */
const createFolderStructure = (contentArray: WithObjectId<Content>[]) => {
  // root folder
  const folderStructure: Folder = {
    content: [] as WithObjectId<Content>[],
    name: BROWSE_ROUTE_ROOT_FOLDER,
  };

  // for each item
  contentArray.forEach(item => {
    let curFolder = folderStructure;

    // iterate over `folderPath`
    item.folderPath.forEach(folder => {
      const encodedFolder = encode(folder);
      // if folder doesn't exist yet, add it
      if (!curFolder[encodedFolder]) {
        curFolder[encodedFolder] = {
          content: [] as WithObjectId<Content>[],
          name: folder,
        };
      }

      // traverse into nested object
      curFolder = curFolder[encodedFolder];
    });

    // add content item where it belongs in nested folder structure
    curFolder.content.push(item);
  });

  // root folder
  return folderStructure;
};

export default createFolderStructure;
