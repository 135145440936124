import FavoriteButton from '../FavoriteButton/FavoriteButton';
import Icons from '../Icons/Icons';
import ContentCardWrapper from '../ContentCardWrapper/ContentCardWrapper';

interface ContentCardWithoutLookerImageProps {
  title: string;
  type: string;
  to: string;
  isFavorite: boolean;
  isFavoriteButtonDisabled: boolean;
  _id: string;
  folderPath?: string;
}

/**
 * A card specific to content such as Looker, without image
 * @note for when Looker images are feature flagged off
 */
const ContentCardWithoutImage = ({
  title,
  type,
  to,
  isFavorite,
  isFavoriteButtonDisabled,
  _id,
  folderPath,
}: ContentCardWithoutLookerImageProps) => {
  return (
    <ContentCardWrapper {...{ to, _id, type }}>
      <div className='relative h-32 p-4'>
        <p
          className={`${
            folderPath ? 'line-clamp-2' : ' line-clamp-3'
          } font-semibold`}
          title={title}
        >
          {title}
        </p>
        <div className='absolute bottom-4 left-4 mr-12'>
          <p className='mb-0.5 line-clamp-1 text-sm text-brand-shade-grey'>
            {type}
          </p>
          {folderPath && (
            <div className='relative' title={folderPath}>
              <Icons.FolderIcon className='absolute top-px h-3 w-3 stroke-brand-shade-grey' />
              <p className='ml-5 line-clamp-1 text-xs text-brand-shade-grey'>
                {folderPath}
              </p>
            </div>
          )}
        </div>
        <FavoriteButton
          {...{
            isFavorite,
            isDisabled: isFavoriteButtonDisabled,
            _id,
            classNames:
              'absolute bottom-0 right-0 p-4 hover:scale-[115%] transition-transform',
          }}
        />
      </div>
    </ContentCardWrapper>
  );
};

export default ContentCardWithoutImage;
