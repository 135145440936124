import useBodyLock from '../../hooks/useBodyLock';

/**
 * Component to lock body to `overflow-y-hidden`
 */
const BodyLock = () => {
  useBodyLock();
  return null;
};
export default BodyLock;
