import { PropsWithChildren } from 'react';
import { CLOSE } from '../../utils/constants';
import Icons from '../Icons/Icons';

interface ScreenTakeoverProps extends PropsWithChildren {
  id?: string;
  handleCloseScreenTakeover: () => void;
  title: string;
  zIndex: string;
  classNames?: string;
}

/**
 * Screen takeover
 */
const ScreenTakeover = ({
  id,
  handleCloseScreenTakeover,
  title,
  zIndex,
  classNames,
  children,
}: ScreenTakeoverProps) => (
  <div
    id={title}
    data-testid={title}
    className={`fixed inset-0 flex items-center justify-center lg:bg-brand-black lg:bg-opacity-50 ${zIndex}`}
  >
    <div
      id={id}
      className={`h-full w-full overflow-y-auto bg-brand-white px-6 py-4 lg:h-[36rem] lg:w-[40rem] lg:rounded-2xl ${classNames}`}
    >
      {/* Title and Close Button */}
      <div className='mb-4 flex items-center justify-between'>
        <h1>{title}</h1>
        <CloseButton {...{ handleCloseScreenTakeover }} />
      </div>
      {children}
    </div>
  </div>
);

/**
 * Close button for `ScreenTakeover`
 */
const CloseButton = ({
  handleCloseScreenTakeover,
}: {
  handleCloseScreenTakeover: () => void;
}) => (
  <button title={CLOSE} className='p-4' onClick={handleCloseScreenTakeover}>
    <Icons.Close className='h-5 w-5 fill-brand-black' />
  </button>
);

export default ScreenTakeover;
