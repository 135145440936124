const debounce = (func: () => void, duration: number) => {
  let timeout: NodeJS.Timeout;
  const closure = () => {
    clearTimeout(timeout);
    timeout = setTimeout(func, duration);
  };

  closure.cancel = () => clearTimeout(timeout);

  return closure;
};

export default debounce;
