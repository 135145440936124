import cn from 'classnames';
import Tag from '../Tag/Tag';
import CardRow from '../CardRow/CardRow';
import {
  HomePageCardProps,
  HomePageCardsWithGradientProps,
  CardsContainerProps,
} from '../../types/home.types';
import {
  CARD_TITLES,
  PATHS,
  EMPTY_STATE_MESSAGES,
  SCROLL_CONTAINER,
  SEARCHPARAM_KEYS,
  PATH_SEGMENTS,
  MEDIA_QUERIES,
  TUTORIAL_TARGETS,
} from '../../utils/constants';
import Card from '../Card/Card';
import EmptyCardStateMessage from '../EmptyCardStateMessage/EmptyCardStateMessage';
import SeeAllButton from '../SeeAllButton/SeeAllButton';
import getFormattedDateStr from '../../utils/getFormattedDateStr';
import useIsScrolledToBottom from '../../hooks/useIsScrolledToBottom';
import useHandleFavoriteOrPinOrAppOrContentClick from '../../hooks/useHandleFavoriteOrPinOrAppOrContentClick';
import DSAInsightsCard from '../DSAInsightsCard/DSAInsightsCard';
import { SEARCH_SORT_OPTIONS } from '../../types/sort.types';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useAppSelector } from '../../hooks/redux';
import {
  selectFavoritesInFlight,
  selectPinnedFavoritesInFlight,
} from '../../state/user/user.slice';

const cardPadding = 'px-8 py-3';

/**
 * Main container for desktop cards
 */
const DesktopCardsContainer = ({
  navFeatureFlags,
  allFavoritesMap,
  pinnedFavoritesMap,
  favorites,
  pinnedFavorites,
  popularContent,
  dsaInsights,
  popularTags,
  contentMap,
  navigate,
}: CardsContainerProps) => {
  const favoritesInFlight = useAppSelector(selectFavoritesInFlight);
  const pinnedFavoritesInFlight = useAppSelector(selectPinnedFavoritesInFlight);
  const isDesktopXl = useMediaQuery(MEDIA_QUERIES.DESKTOP_XL);
  const handleFavoriteOrPinOrAppOrContentClick =
    useHandleFavoriteOrPinOrAppOrContentClick();

  return (
    <div
      onClick={handleFavoriteOrPinOrAppOrContentClick}
      className='flex flex-col'
    >
      {/* top row */}
      <div className='mb-6 flex h-96 gap-8'>
        <FavoritesDesktopCard
          hasContent={favorites.length + pinnedFavorites.length > 0}
          showOverflowGradient={favorites.length + pinnedFavorites.length > 5}
          navigate={navigate}
        >
          {[...pinnedFavorites, ...favorites].map(_id => (
            <CardRow
              {...{
                contentItem: contentMap[_id],
                isFavorite: true,
                isFavoriteButtonDisabled:
                  !!favoritesInFlight[_id] || !!pinnedFavoritesInFlight[_id],
                key: _id,
                isPinnable: true,
                isPinned: !!pinnedFavoritesMap[_id],
                isPinnedButtonDisabled:
                  !!pinnedFavoritesInFlight[_id] || !!favoritesInFlight[_id],
              }}
            />
          ))}
        </FavoritesDesktopCard>

        <PopularContentDesktopCard
          hasContent={popularContent.length > 0}
          showOverflowGradient={popularContent.length > 5}
        >
          {popularContent.map(_id => (
            <CardRow
              {...{
                contentItem: contentMap[_id],
                isFavorite: allFavoritesMap[_id],
                isFavoriteButtonDisabled:
                  !!favoritesInFlight[_id] || !!pinnedFavoritesInFlight[_id],
                key: _id,
              }}
            />
          ))}
        </PopularContentDesktopCard>
      </div>
      {/* bottom row */}
      <div className='flex h-72 gap-8'>
        {navFeatureFlags.dsaInsights && (
          <DSAInsightsDesktopCard
            hasContent={dsaInsights.length > 0}
            navigate={navigate}
          >
            {dsaInsights.slice(0, isDesktopXl ? 4 : 3).map((insight, i) => (
              <DSAInsightsCard
                key={i}
                to={`${PATH_SEGMENTS.dsaInsights}/${insight.slug}`}
                image={insight.hero}
                date={getFormattedDateStr(insight.publishDate)}
                title={insight.title}
              />
            ))}
          </DSAInsightsDesktopCard>
        )}

        <PopularTagsDesktopCard
          hasContent={popularTags.length > 0}
          showOverflowGradient={popularTags.length > 5}
        >
          {popularTags.map(tag => (
            <Tag
              {...{
                key: tag,
                tag,
                classNames: 'bg-brand-tint-grey-1',
                onClick: () =>
                  navigate(
                    `${PATHS.searchResults}?${SEARCHPARAM_KEYS.QUERY}=${tag}&${SEARCHPARAM_KEYS.SORT}=${SEARCH_SORT_OPTIONS.RELEVANCE}`
                  ),
              }}
            />
          ))}
        </PopularTagsDesktopCard>
      </div>
    </div>
  );
};

/**
 * Favorites card
 */
export const FavoritesDesktopCard = ({
  hasContent,
  children,
  showOverflowGradient,
  navigate,
}: HomePageCardsWithGradientProps) => {
  const isScrolledToBottom = useIsScrolledToBottom(SCROLL_CONTAINER.FAVORITES);

  return (
    <Card classNames={cn('w-3/5 card-with-gradient', cardPadding)}>
      {/* header */}
      <div className='flex items-center justify-between'>
        <h2 className='ml-2 text-2xl' id={TUTORIAL_TARGETS.FAVORITES_CARD}>
          {CARD_TITLES.FAVORITES}
        </h2>
        {hasContent && (
          <SeeAllButton onClick={() => navigate(PATHS.favorites)} />
        )}
      </div>
      {/* content */}
      {hasContent ? (
        <>
          <div
            id={SCROLL_CONTAINER.FAVORITES}
            className='thin-scrollbar flex flex-col overflow-y-auto'
          >
            {children}
          </div>
          {showOverflowGradient && !isScrolledToBottom && (
            <div className='gradient' />
          )}
        </>
      ) : (
        <EmptyCardStateMessage message={EMPTY_STATE_MESSAGES.NO_FAVORITES} />
      )}
    </Card>
  );
};

/**
 * Popular content card
 */
export const PopularContentDesktopCard = ({
  hasContent,
  children,
  showOverflowGradient,
}: Omit<HomePageCardsWithGradientProps, 'navigate'>) => {
  const isScrolledToBottom = useIsScrolledToBottom(
    SCROLL_CONTAINER.POPULAR_CONTENT
  );

  return (
    <Card classNames={cn('w-2/5 card-with-gradient', cardPadding)}>
      {/* header */}
      <h2 className='ml-2 text-2xl'>{CARD_TITLES.POPULAR_CONTENT}</h2>
      {/* content */}
      {hasContent ? (
        <>
          <div
            id={SCROLL_CONTAINER.POPULAR_CONTENT}
            className='thin-scrollbar flex flex-col overflow-y-auto'
          >
            {children}
          </div>
          {showOverflowGradient && !isScrolledToBottom && (
            <div className='gradient' />
          )}
        </>
      ) : (
        <EmptyCardStateMessage
          message={EMPTY_STATE_MESSAGES.NO_POPULAR_CONTENT}
        />
      )}
    </Card>
  );
};

/**
 * DSA insights card
 */
export const DSAInsightsDesktopCard = ({
  hasContent,
  children,
  navigate,
}: HomePageCardProps) => (
  <Card classNames={cn('w-3/5', cardPadding)}>
    {/* header */}
    <div className='mb-3 flex items-center justify-between'>
      <h2 className='ml-2 text-2xl'>{CARD_TITLES.DSA_INSIGHTS}</h2>
      {hasContent && (
        <SeeAllButton onClick={() => navigate(PATHS.dsaInsightsFeed)} />
      )}
    </div>
    {/* content */}
    {hasContent ? (
      <div className='grid grid-cols-3 gap-4 xl:grid-cols-4'>{children}</div>
    ) : (
      <EmptyCardStateMessage message={EMPTY_STATE_MESSAGES.NO_DSA_INSIGHTS} />
    )}
  </Card>
);

/**
 * Popular tags card
 */
export const PopularTagsDesktopCard = ({
  hasContent,
  children,
  showOverflowGradient,
}: Omit<HomePageCardsWithGradientProps, 'navigate'>) => {
  const isScrolledToBottom = useIsScrolledToBottom(
    SCROLL_CONTAINER.POPULAR_TAGS
  );

  return (
    <Card classNames={cn('w-2/5 card-with-gradient', cardPadding)}>
      {/* header */}
      <h2 className='mb-3 ml-2 text-2xl'>{CARD_TITLES.POPULAR_TAGS}</h2>
      {/* content */}
      {hasContent ? (
        <>
          <div
            id={SCROLL_CONTAINER.POPULAR_TAGS}
            className='thin-scrollbar flex flex-wrap gap-2 overflow-y-auto'
          >
            {children}
          </div>
          {showOverflowGradient && !isScrolledToBottom && (
            <div className='gradient' />
          )}
        </>
      ) : (
        <EmptyCardStateMessage message={EMPTY_STATE_MESSAGES.NO_POPULAR_TAGS} />
      )}
    </Card>
  );
};

export default DesktopCardsContainer;
