import { SyntheticEvent } from 'react';

/**
 * Curried intercepter to prevent default on `anchor` element when nested item is clicked
 *
 * - Useful when `button` is nested in `anchor` element,
 * user clicks on `button`, and you don't want to navigate
 */
export const preventDefaultOnNestedClick =
  (selector: string) => (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const maybeDismissBtn = target.closest(selector);
    if (maybeDismissBtn) {
      // don't navigate
      e.preventDefault();
    }
  };
