import Icons from '../components/Icons/Icons';

export const CONTENT_TYPES_IDS = {
  DASHBOARD: 'dashboard-icon',
  VIDEO: 'video-icon',
  APP: 'app-icon',
};

/**
 * Helper to get icon based on content type name
 */
const getContentTypeIcon = (type: string, className?: string) =>
  type === 'Dashboard' ? (
    <Icons.Dashboard
      data-testid={CONTENT_TYPES_IDS.DASHBOARD}
      className={className}
    />
  ) : type === 'Video' ? (
    <Icons.Video data-testid={CONTENT_TYPES_IDS.VIDEO} className={className} />
  ) : (
    <Icons.App data-testid={CONTENT_TYPES_IDS.APP} className={className} />
  );

export default getContentTypeIcon;
