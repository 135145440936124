/**
 * Month Day, Year
 *
 * @example
 * 'Feb 10, 2023'
 */
const getFormattedDateStr = (publishDate: string) =>
  new Date(publishDate).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export default getFormattedDateStr;
