import cn from 'classnames';
import { SyntheticEvent } from 'react';
import BodyLock from '../BodyLock/BodyLock';

interface MobileDrawerProps {
  isOpen: boolean;
  containerID: string;
  ariaLabelledBy: string;
  handleClose: (e: SyntheticEvent) => void;
  children: JSX.Element;
}

/**
 * Mobile drawer
 */
const MobileDrawer = ({
  isOpen,
  containerID,
  ariaLabelledBy,
  handleClose,
  children,
}: MobileDrawerProps) => {
  return (
    <>
      <div
        className={cn(
          'fixed inset-0 flex flex-col transition-all',
          isOpen ? 'translate-y-0' : 'translate-y-full'
        )}
        id={containerID}
        onClick={handleClose}
      >
        <div
          role='dialog'
          aria-labelledby={ariaLabelledBy}
          className='relative mt-auto flex h-5/6 flex-col rounded-t-3xl bg-brand-tint-grey-1 pb-4 pt-8 shadow-t-2xl'
        >
          {children}
        </div>
      </div>
      {isOpen && <BodyLock />}
    </>
  );
};

export default MobileDrawer;
