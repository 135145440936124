import { WithObjectId } from '../types';
import { Content } from '../types/content.types';

/**
 * For every input array item, determine if all input `tags` are in `item.tags` array
 */
const filterContentArrayByTags = (
  array: WithObjectId<Content>[],
  tags: string[]
) =>
  array.filter(item =>
    tags.every(tag => item.tags.find(({ name }) => name === tag))
  );

export default filterContentArrayByTags;
