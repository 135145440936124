import { WithObjectId } from '../types';
import { Content } from '../types/content.types';

const getUnselectedTagsFromContentArr = (
  contentArray: WithObjectId<Content>[],
  selectedTags: string[]
) => {
  const set = new Set<string>();

  for (let i = 0; i < contentArray.length; i++) {
    const { tags } = contentArray[i];
    for (let j = 0; j < tags.length; j++) {
      const tag = tags[j];
      if (!selectedTags.includes(tag.name)) set.add(tag.name);
    }
  }

  return Array.from(set).sort();
};

export default getUnselectedTagsFromContentArr;
