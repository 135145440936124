import { useRef, useState } from 'react';
import cn from 'classnames';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import Icons from '../Icons/Icons';
import useHasIntersected from '../../hooks/useHasIntersected';
import ContentCardWrapper from '../ContentCardWrapper/ContentCardWrapper';

export const DASHBOARD_IMAGE = 'dashboard-image';
export const CONTENT_CARD_IMAGE = 'content-card-image';

const contentCardHeight = 'h-24';

interface ContentCardProps {
  title: string;
  type: string;
  to: string;
  isFavorite: boolean;
  isFavoriteButtonDisabled: boolean;
  _id: string;
  isDashboard: boolean;
  icon: string;
  idFromSource: string;
  folderPath?: string;
}

/**
 * A card specific to content such as Looker
 */
const ContentCard = ({
  title,
  type,
  to,
  isFavorite,
  isFavoriteButtonDisabled,
  _id,
  isDashboard,
  icon,
  idFromSource,
  folderPath,
}: ContentCardProps) => {
  return (
    <ContentCardWrapper {...{ to, _id, type }}>
      <div className='relative'>
        {isDashboard ? (
          <ContentCard.DashboardImage
            {...{
              icon,
              title,
              idFromSource,
            }}
          />
        ) : (
          <ContentCard.Image {...{ icon, title }} />
        )}
      </div>

      <div className='h-32 p-4'>
        <div className={`relative ${folderPath ? 'mb-3' : 'mb-2'}`}>
          <p className='mb-0.5 mr-10 line-clamp-1 text-sm text-brand-shade-grey'>
            {type}
          </p>
          {folderPath && (
            <div className='relative' title={folderPath}>
              <Icons.FolderIcon className='absolute top-px h-3 w-3 stroke-brand-shade-grey' />
              <p className='ml-5 mr-10 line-clamp-1 text-xs text-brand-shade-grey'>
                {folderPath}
              </p>
            </div>
          )}
          <FavoriteButton
            {...{
              isFavorite,
              isDisabled: isFavoriteButtonDisabled,
              _id,
              classNames:
                'absolute -top-4 -right-4 p-4 hover:scale-[115%] transition-transform',
            }}
          />
        </div>
        <p
          className={`${
            folderPath ? 'line-clamp-2' : 'line-clamp-3'
          } font-semibold`}
          title={title}
        >
          {title}
        </p>
      </div>
    </ContentCardWrapper>
  );
};

export default ContentCard;

interface ContentCardDashboardImageProps {
  title: string;
  icon: string;
  idFromSource: string;
}

/**
 * Image component for dashboard
 */
const ContentCardDashboardImage = ({
  title,
  icon,
  idFromSource,
}: ContentCardDashboardImageProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const hasIntersected = useHasIntersected(containerRef);

  return (
    <div ref={containerRef}>
      {isError || !hasIntersected ? (
        <ContentCard.Image {...{ icon, title }} />
      ) : (
        <img
          data-testid={DASHBOARD_IMAGE}
          src={`${process.env.REACT_APP_LOOKER_URL}/api/internal/core/4.0/content_thumbnail/dashboard/${idFromSource}`}
          alt={title}
          onLoad={() => setIsLoading(false)}
          onError={() => setIsError(true)}
          className={cn(
            `w-full object-cover ${contentCardHeight}`,
            isLoading &&
              'animate-pulse rounded-t-2xl border-x border-t border-brand-tint-grey-3 bg-brand-tint-grey-2'
          )}
        />
      )}
    </div>
  );
};

interface ContentCardImageProps {
  icon: string;
  title: string;
}

/**
 * Image for content card. Used for:
 *
 * - Video and app content
 * - Fallback image for dashboard if there is an error fetching image from Looker
 */
const ContentCardImage = ({ icon, title }: ContentCardImageProps) => (
  <div
    className={cn(
      'flex items-center justify-center bg-brand-tint-grey-2',
      contentCardHeight
    )}
  >
    <img data-testid={CONTENT_CARD_IMAGE} src={icon} alt={title} />
  </div>
);

ContentCard.DashboardImage = ContentCardDashboardImage;
ContentCard.Image = ContentCardImage;
