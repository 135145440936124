import { useEffect } from 'react';
import { matchRoutes, useRoutes } from 'react-router-dom';
import { DSA_HUB, ROLES } from '../../utils/constants';
import useAllowedRoutes from '../../hooks/useAllowedRoutes';

interface RoutesProps {
  role: ROLES;
}

/**
 * Routes component to get allowed routes
 */
const Routes = ({ role }: RoutesProps) => {
  const allowedRoutes = useAllowedRoutes(role);
  const matches = matchRoutes(allowedRoutes, window.location.pathname);
  const routes = useRoutes(allowedRoutes);

  // update HTML `title` element based on route
  useEffect(() => {
    if (!matches) return;

    const title = document.querySelector('title');
    if (!title) return;

    title.innerText = `${DSA_HUB} | ${matches[0].route.title}`;
  }, [matches]);

  return routes;
};

export default Routes;
