import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface WidgetsState {
  isNavSliderOpen: boolean;
  isSearchSuggestionsOpen: boolean;
  isNotificationsOpen: boolean;
  isDropdownMenuOpen: boolean;
  isFilterByTagsTakeoverOpen: boolean;
  isAdminNotificationsFormOpen: boolean;
  isAdminTagsAllTagsOpen: boolean;
  isAdminTagsContentFormOpen: boolean;
  isAIChatsOpen: boolean;
  isAdminFavoritesContentUsersOpen: boolean;
  isAIChatsInfoOpen: boolean;
  isSiteBannerOpen: boolean;
  isAdminAnalyticsSearchFilterOpen: boolean;
  isAdminAnalyticsSearchTakeoverOpen: boolean;
}

export const initialState: WidgetsState = {
  isNavSliderOpen: false,
  isSearchSuggestionsOpen: false,
  isNotificationsOpen: false,
  isDropdownMenuOpen: false,
  isFilterByTagsTakeoverOpen: false,
  isAdminNotificationsFormOpen: false,
  isAdminTagsAllTagsOpen: false,
  isAdminTagsContentFormOpen: false,
  isAIChatsOpen: false,
  isAdminFavoritesContentUsersOpen: false,
  isAIChatsInfoOpen: false,
  isSiteBannerOpen: false,
  isAdminAnalyticsSearchFilterOpen: false,
  isAdminAnalyticsSearchTakeoverOpen: false,
};

export const widgetsSlice = createSlice({
  name: 'widgetsSlice',
  initialState,
  reducers: {
    toggleIsNavSliderOpen: (state, action: PayloadAction<boolean>) => {
      state.isNavSliderOpen = action.payload;
    },
    toggleIsSearchSuggestionsOpen: (state, action: PayloadAction<boolean>) => {
      state.isSearchSuggestionsOpen = action.payload;
    },
    toggleIsNotificationsOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotificationsOpen = action.payload;
    },
    toggleIsDropdownMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isDropdownMenuOpen = action.payload;
    },
    toggleIsAdminNotificationsFormOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAdminNotificationsFormOpen = action.payload;
    },
    toggleIsAdminTagsAllTagsOpen: (state, action: PayloadAction<boolean>) => {
      state.isAdminTagsAllTagsOpen = action.payload;
    },
    toggleIsAdminTagsContentFormOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAdminTagsContentFormOpen = action.payload;
    },
    toggleIsFilterByTagsTakeoverOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isFilterByTagsTakeoverOpen = action.payload;
    },
    toggleIsAIChatsOpen: (state, action: PayloadAction<boolean>) => {
      state.isAIChatsOpen = action.payload;
    },
    toggleIsAdminFavoritesContentUsersOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAdminFavoritesContentUsersOpen = action.payload;
    },
    toggleIsAIChatsInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.isAIChatsInfoOpen = action.payload;
    },
    toggleIsSiteBannerOpen: (state, action: PayloadAction<boolean>) => {
      state.isSiteBannerOpen = action.payload;
    },
    toggleIsAdminAnalyticsSearchFilterOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAdminAnalyticsSearchFilterOpen = action.payload;
    },
    toggleIsAdminAnalyticsSearchTakeoverOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAdminAnalyticsSearchTakeoverOpen = action.payload;
    },
    closeWidgets: () => initialState,
  },
});

export const widgetsReducer = widgetsSlice.reducer;

export const {
  toggleIsNavSliderOpen,
  toggleIsSearchSuggestionsOpen,
  toggleIsNotificationsOpen,
  toggleIsDropdownMenuOpen,
  toggleIsFilterByTagsTakeoverOpen,
  toggleIsAdminNotificationsFormOpen,
  toggleIsAdminTagsAllTagsOpen,
  toggleIsAdminTagsContentFormOpen,
  toggleIsAIChatsOpen,
  toggleIsAdminFavoritesContentUsersOpen,
  toggleIsAIChatsInfoOpen,
  toggleIsSiteBannerOpen,
  toggleIsAdminAnalyticsSearchFilterOpen,
  toggleIsAdminAnalyticsSearchTakeoverOpen,
  closeWidgets,
} = widgetsSlice.actions;

export const selectIsNavSliderOpen = (state: RootState) =>
  state.widgets.isNavSliderOpen;

export const selectIsSearchSuggestionsOpen = (state: RootState) =>
  state.widgets.isSearchSuggestionsOpen;

export const selectIsNotificationsOpen = (state: RootState) =>
  state.widgets.isNotificationsOpen;

export const selectIsDropdownMenuOpen = (state: RootState) =>
  state.widgets.isDropdownMenuOpen;

export const selectIsFilterByTagsTakeoverOpen = (state: RootState) =>
  state.widgets.isFilterByTagsTakeoverOpen;

export const selectIsAdminNotificationsFormOpen = (state: RootState) =>
  state.widgets.isAdminNotificationsFormOpen;

export const selectIsAdminTagsAllTagsOpen = (state: RootState) =>
  state.widgets.isAdminTagsAllTagsOpen;

export const selectIsAdminTagsContentFormOpen = (state: RootState) =>
  state.widgets.isAdminTagsContentFormOpen;

export const selectIsAIChatsOpen = (state: RootState) =>
  state.widgets.isAIChatsOpen;

export const selectIsAdminFavoritesContentUsersOpen = (state: RootState) =>
  state.widgets.isAdminFavoritesContentUsersOpen;

export const selectIsAIChatsInfoOpen = (state: RootState) =>
  state.widgets.isAIChatsInfoOpen;

export const selectIsSiteBannerOpen = (state: RootState) =>
  state.widgets.isSiteBannerOpen;

export const selectIsAdminAnalyticsSearchFilterOpen = (state: RootState) =>
  state.widgets.isAdminAnalyticsSearchFilterOpen;

export const selectIsAdminAnalyticsSearchTakeoverOpen = (state: RootState) =>
  state.widgets.isAdminAnalyticsSearchTakeoverOpen;
