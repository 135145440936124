/**
 * Adapted from Syslog
 * @see https://datatracker.ietf.org/doc/html/rfc5424#page-11
 */
export enum PRETTY_SYSLOG_SEVERITY {
  INFO = 'Info',
  ISSUE = 'Issue',
  RESOLVED = 'Resolved',
}

export enum SYSLOG_SEVERITY {
  INFO = 6, // For Syslog this means "Informational", but for the Hub it means "Info"
  ISSUE = 4, // For Syslog this means "Warning", but for the Hub it means "Issue"
  RESOLVED = -1, // For Syslog this does not exist, but for the Hub it means "Resolved"
}
