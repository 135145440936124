import { useRef } from 'react';
import cn from 'classnames';
import { MEDIA_QUERIES, SCROLL_CONTAINER } from '../../../utils/constants';
import Tag from '../../Tag/Tag';
import useMediaQuery from '../../../hooks/useMediaQuery';

interface UnselectedTagsProps {
  unselectedTags: string[];
  toggleIsSelected: (tag: string) => void;
  isScrolledToBottom: boolean;
}

/**
 * Unselected tags for `FilterByTagsTakeover`
 */
const UnselectedTags = ({
  unselectedTags,
  toggleIsSelected,
  isScrolledToBottom,
}: UnselectedTagsProps) => {
  const unselectedTagsContainerRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  return (
    <div
      className={cn(
        'filter-by-tags-scroll-container mb-4 flex grow flex-col overflow-hidden rounded-2xl bg-brand-tint-grey-1',
        isDesktop ? 'h-72' : 'h-48'
      )}
    >
      <div
        ref={unselectedTagsContainerRef}
        id={SCROLL_CONTAINER.UNSELECTED_TAGS}
        className='thin-scrollbar flex flex-wrap gap-1 overflow-y-auto px-4 py-2 lg:py-4'
      >
        {unselectedTags.map(tag => (
          <Tag
            key={tag}
            tag={tag}
            classNames='bg-brand-white'
            onClick={() => {
              toggleIsSelected(tag);
              unselectedTagsContainerRef.current?.scrollTo(0, 0);
            }}
          />
        ))}
      </div>
      {!isScrolledToBottom && <div className='gradient' />}
    </div>
  );
};

export default UnselectedTags;
