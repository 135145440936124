import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WithObjectId } from '../../types';
import { DSAInsightModel } from '../../types/home.types';
import { STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import dsaInsightsAPI from './dsaInsights.api';

export interface DSAInsights {
  dsaInsights: WithObjectId<DSAInsightModel>[];
  totalByRole: number;
  skip: number;
}

export interface DSAInsightsState {
  status: STATE_STATUSES;
  data: DSAInsights;
}

export const initialState: DSAInsightsState = {
  status: STATE_STATUSES.INITIAL,
  data: {
    dsaInsights: [],
    totalByRole: 0,
    skip: 0,
  },
};

export const fetchDSAInsights = createAsyncThunk(
  'dsaInsights/fetchDSAInsights',
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const { skip } = rootState.dsaInsights.data;

    const response = await dsaInsightsAPI.fetchDSAInsights(
      skip,
      thunkAPI.signal
    );

    return response.data;
  }
);

export const dsaInsightsSlice = createSlice({
  name: 'dsaInsights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDSAInsights.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchDSAInsights.fulfilled, (state, action) => {
        const updatedDSAInsights = [
          ...state.data.dsaInsights,
          ...action.payload.dsaInsights,
        ];

        return {
          status: STATE_STATUSES.FULFILLED,
          data: {
            ...action.payload,
            dsaInsights: updatedDSAInsights,
            skip: updatedDSAInsights.length,
          },
        };
      })
      .addCase(fetchDSAInsights.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = STATE_STATUSES.REJECTED;
        }
      });
  },
});

export const dsaInsightsReducer = dsaInsightsSlice.reducer;

export const selectIsDSAInsightsInitializing = (state: RootState) =>
  state.dsaInsights.status === STATE_STATUSES.INITIAL ||
  (state.dsaInsights.status === STATE_STATUSES.PENDING &&
    !state.dsaInsights.data.dsaInsights.length);

export const selectIsDSAInsightsLoading = (state: RootState) =>
  state.dsaInsights.status === STATE_STATUSES.PENDING;

export const selectIsDSAInsightsRejected = (state: RootState) =>
  state.dsaInsights.status === STATE_STATUSES.REJECTED;

export const selectDSAInsights = (state: RootState) =>
  state.dsaInsights.data.dsaInsights;

export const selectDSAInsightsTotalByRole = (state: RootState) =>
  state.dsaInsights.data.totalByRole;

export const selectDSAInsightsSkip = (state: RootState) =>
  state.dsaInsights.data.skip;
