import { isProd } from '../utils/isEnv';
import AppInsights from '.';

/**
 * Decorator to override Application Insights when developing locally
 */
const localOverride = (
  target: typeof AppInsights,
  key: string,
  descriptor: PropertyDescriptor
) => {
  const originalFunc = descriptor.value;
  descriptor.value = (...args: unknown[]) => {
    isProd() && originalFunc(...args);
    console.error(...args);
  };
};

export default localOverride;
