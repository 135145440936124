import { hubAPI } from '../../http';
import { WithTimestamps, WithObjectId } from '../../types';
import { AIChatUpdateBody } from '../../types/aiChats.types';
import {
  AIChat,
  AIChatMessage,
  AIMessageWithChatID,
  AIChatFeedbackPayload,
} from './aiChats.slice';

export type AIChatDoc = WithObjectId<WithTimestamps<AIChat>>;
export type AIChatMessageDoc = WithObjectId<WithTimestamps<AIChatMessage>>;
export interface AIChatNewMessagesDoc {
  userMessage: AIChatMessageDoc;
  llmMessage: AIChatMessageDoc;
  newChat?: AIChatDoc;
}

const fetchAIChats = (signal: AbortSignal) =>
  hubAPI.get<{ userChats: AIChatDoc[] }>('/api/ai-chat/chats', {
    signal,
  });

const updateAIChat = (chat: AIChatUpdateBody, signal: AbortSignal) =>
  hubAPI.patch<AIChatDoc>(`/api/ai-chat/chats/${chat._id}`, chat, {
    signal,
  });

const deleteAllAIChats = (signal: AbortSignal) =>
  hubAPI.patch('/api/ai-chat/chats', {
    signal,
  });

const fetchAIChatMessages = (chatID: AIChatDoc['_id'], signal: AbortSignal) =>
  hubAPI.get<{ chatMessages: AIChatMessageDoc[] }>(
    `/api/ai-chat/chats/${chatID}/messages`,
    {
      signal,
    }
  );

const postAIChatMessage = (data: AIMessageWithChatID, signal: AbortSignal) =>
  hubAPI.post<AIChatNewMessagesDoc>(
    `/api/ai-chat/chats/${data.chatID}/messages`,
    { message: data.message },
    {
      signal,
    }
  );

const updateAIChatMessageFeedback = (
  data: AIChatFeedbackPayload,
  signal: AbortSignal
) =>
  hubAPI.patch<AIChatMessageDoc>(
    `/api/ai-chat/chats/${data.chatID}/messages/${data.messageId}`,
    data.feedback,
    {
      signal,
    }
  );

const aiChatsAPI = {
  fetchAIChats,
  updateAIChat,
  deleteAllAIChats,
  fetchAIChatMessages,
  postAIChatMessage,
  updateAIChatMessageFeedback,
};

export default aiChatsAPI;
