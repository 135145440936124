import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { BannerContent } from '../../state/config/config.slice';
import { dismissBanner } from '../../state/user/user.slice';
import Icons from '../Icons/Icons';
import getSeverityIconAndClassNames from '../../utils/getSeverityIconAndClassNames';
import ScreenTakeover from '../ScreenTakeover/ScreenTakeover';
import {
  selectIsSiteBannerOpen,
  toggleIsSiteBannerOpen,
} from '../../state/widgets/widgets.slice';
import {
  CANCEL,
  CONFIRM,
  DEBOUNCE_TIMEOUT,
  EVENTS,
} from '../../utils/constants';
import BodyLock from '../BodyLock/BodyLock';
import debounce from '../../utils/debounce';

/**
 * SiteBanner component
 */
const SiteBanner = ({ severity, message, isDismissible }: BannerContent) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSiteBannerOpen = useAppSelector(selectIsSiteBannerOpen);
  const previewRef = useRef<HTMLParagraphElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const mdContainerRef = useRef<HTMLDivElement>(null);
  const [isConfirmingClose, setIsConfirmingClose] = useState(false);

  const { Icon, backgroundClassNames, lightClassNames, prettySeverity } =
    getSeverityIconAndClassNames(severity);

  // click on modal backdrop
  useEffect(() => {
    const handleBlur = (e: MouseEvent) => {
      const { id } = e.target as HTMLElement;

      if (id === prettySeverity) {
        dispatch(toggleIsSiteBannerOpen(false));
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [dispatch, prettySeverity]);

  // for preview: hydrating, centering, and ellipsis
  useEffect(() => {
    /* istanbul ignore next */
    if (!previewRef.current || !mdContainerRef.current) return;

    // this needs to happen regardless of next condition
    const previewEl = previewRef.current;
    previewEl.textContent = mdContainerRef.current.innerText;

    const closeBtnEl = closeBtnRef.current;

    if (!isDismissible || !closeBtnEl) return;

    const resize = () => {
      if (
        previewEl.scrollWidth >
        previewEl.clientWidth - closeBtnEl.clientWidth
      ) {
        previewEl.classList.add('mr-10');
      } else {
        previewEl.classList.remove('mr-10');
      }
    };

    resize();
    const debounced = debounce(resize, DEBOUNCE_TIMEOUT);
    window.addEventListener(EVENTS.RESIZE, debounced);

    return () => {
      debounced.cancel();
      window.removeEventListener(EVENTS.RESIZE, debounced);
    };
  }, [message, isDismissible]);

  // reset confirmation after timeout
  useEffect(() => {
    let timeoutID: NodeJS.Timeout;
    if (isConfirmingClose) {
      timeoutID = setTimeout(() => setIsConfirmingClose(false), 3000);
    }

    return () => clearTimeout(timeoutID);
  }, [isConfirmingClose]);

  // intercept links in `Markdown` component
  const handleInterceptLinks = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      e.preventDefault();
      dispatch(toggleIsSiteBannerOpen(false));
      const { href, pathname } = target as HTMLAnchorElement;
      href.startsWith(window.location.origin)
        ? navigate(pathname)
        : window.open(href, '_blank');
    }
  };

  return (
    <>
      <div
        className={`${backgroundClassNames} relative flex h-[--site-banner-height] items-center justify-center px-[--layout-padding] text-brand-white lg:px-[--lg-layout-padding]`}
      >
        {/* preview */}
        <div
          onClick={() => dispatch(toggleIsSiteBannerOpen(true))}
          className={`flex h-full cursor-pointer items-center overflow-hidden transition-opacity ${
            isConfirmingClose ? 'opacity-50' : 'opacity-100'
          }`}
        >
          <div className='mr-2'>
            <Icon className={lightClassNames} />
          </div>
          <p ref={previewRef} role='alert' className='truncate'>
            ...Loading
          </p>
        </div>

        {/* buttons */}
        {isDismissible && (
          <div className='absolute right-0 lg:mr-4'>
            {isConfirmingClose ? (
              <div
                className={`flex items-center font-semibold ${backgroundClassNames}`}
              >
                <button
                  className='p-4'
                  onClick={() => setIsConfirmingClose(false)}
                >
                  {CANCEL}
                </button>
                <button
                  className='p-4'
                  onClick={() => dispatch(dismissBanner(new Date()))}
                >
                  {CONFIRM}
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsConfirmingClose(true)}
                className='p-4'
                title='Close'
                ref={closeBtnRef}
              >
                <Icons.Close className='h-5 w-5 fill-brand-white' />
              </button>
            )}
          </div>
        )}
      </div>

      {/* screentakeover */}
      {/* NOTE: using this approach to query the message and hydrate the preview */}
      <div
        className={isSiteBannerOpen ? 'block' : 'hidden'}
        onClick={handleInterceptLinks}
      >
        <ScreenTakeover
          handleCloseScreenTakeover={() =>
            dispatch(toggleIsSiteBannerOpen(false))
          }
          title={prettySeverity}
          zIndex='z-20'
          classNames='flex flex-col'
        >
          <div className='overflow-y-scroll' ref={mdContainerRef}>
            <Markdown
              remarkPlugins={[remarkGfm]}
              children={message}
              className='prose prose-base prose-a:text-brand-green [&_*]:my-2'
            />
          </div>
        </ScreenTakeover>
        {isSiteBannerOpen && <BodyLock />}
      </div>
    </>
  );
};

export default SiteBanner;
