import { WithObjectId } from '../../../types';
import { Content } from '../../../types/content.types';

/**
 * Get content tag names in current folder and all nested folders
 *
 * @param {WithObjectId<Content>[]} filteredContentArray - Content array filtered by tags
 * @param {string} breadcrumbs - Starting with top-level subfolders (not root)
 */
const getCurrentFolderTags = (
  filteredContentArray: WithObjectId<Content>[],
  breadcrumbs: string
) => {
  const tagsSet = new Set<string>();

  // if item starts with same breadcrumb trail
  // then we know it's on the same level or nested
  filteredContentArray.forEach(({ folderPath, tags }) => {
    if (folderPath.join(',').startsWith(breadcrumbs)) {
      tags.forEach(({ name }) => tagsSet.add(name));
    }
  });

  return Array.from(tagsSet).sort();
};

export default getCurrentFolderTags;
