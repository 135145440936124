import Icons from '../Icons/Icons';

interface SeeAllButtonProps {
  onClick: () => void;
}

/**
 * See All button for HomePage Cards
 */
const SeeAllButton = ({ onClick }: SeeAllButtonProps) => {
  return (
    <button
      title='See all'
      onClick={onClick}
      className='flex items-center rounded-full bg-brand-green py-3 pl-4 pr-3 text-xs font-medium text-brand-white'
    >
      <span className='relative top-px mr-3'>See all</span>
      <Icons.ChevronRight className='stroke-brand-white' />
    </button>
  );
};
export default SeeAllButton;
