import { MutableRefObject } from 'react';
import { KEYS } from './constants';

/**
 * Search suggestions handler for up and down arrow keys to cycle through suggestions
 */
const curriedHandleUpAndDownArrowKeys =
  (
    ulRef: MutableRefObject<HTMLUListElement | null> | null,
    inputRef: MutableRefObject<HTMLInputElement | null> | null,
    hasInput: boolean,
    handleOnEnter: ((value: string) => void) | null,
    queryingElement: string
  ) =>
  // arrow key event handler
  (e: KeyboardEvent) => {
    if (
      e.key !== KEYS.ARROW_DOWN &&
      e.key !== KEYS.ARROW_UP &&
      e.key !== KEYS.ENTER
    )
      return;

    const isArrowDown = e.key === KEYS.ARROW_DOWN;
    const isEnter = e.key === KEYS.ENTER;
    const listItems = Array.from(
      ulRef?.current?.querySelectorAll(queryingElement) || []
    );
    const linksLength = listItems.length;
    if (!linksLength) return;

    // helper to focus input and put cursor at end
    const focusInput = () => {
      inputRef?.current?.focus();
      inputRef?.current?.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    };

    // index of currently focused link inside `ul`
    let curFocusIndex = listItems.indexOf(
      // document.activeElement as HTMLAnchorElement
      document.activeElement as HTMLAnchorElement | HTMLLIElement
    );

    if (isEnter && handleOnEnter) {
      const selectedOption = (
        listItems[curFocusIndex] as HTMLAnchorElement | HTMLLIElement
      )?.dataset.value;
      selectedOption && handleOnEnter(selectedOption);
      return;
    }

    let currentFocus;

    // input is focused
    if (document.activeElement === inputRef?.current) {
      if (isArrowDown) {
        currentFocus = listItems[0];
      } else {
        currentFocus = listItems[linksLength - 1];
      }
      // first element is focused (considers if `linksLength` === 1)
    } else if (document.activeElement === listItems[0]) {
      if (isArrowDown) {
        linksLength === 1 ? focusInput() : (currentFocus = listItems[1]);
      } else {
        inputRef?.current
          ? focusInput()
          : (currentFocus = listItems[linksLength - 1]);
      }

      // last element is focused
    } else if (document.activeElement === listItems[linksLength - 1]) {
      if (isArrowDown) {
        inputRef?.current ? focusInput() : (currentFocus = listItems[0]);
      } else {
        currentFocus = listItems[linksLength - 2];
      }
      // inner element is focused (other than first or last)
    } else if (curFocusIndex > -1) {
      if (isArrowDown) {
        currentFocus = listItems[curFocusIndex + 1];
      } else {
        currentFocus = listItems[curFocusIndex - 1];
      }
    } // if nothing focused
    else if (!hasInput) {
      if (isArrowDown) {
        currentFocus = listItems[0];
      } else {
        currentFocus = listItems[linksLength - 1];
      }
    }
    currentFocus && (currentFocus as HTMLAnchorElement | HTMLLIElement).focus();
  };

export default curriedHandleUpAndDownArrowKeys;
