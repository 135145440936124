import { MutableRefObject, SyntheticEvent, useEffect, useRef } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import {
  EVENTS,
  NAV,
  PAGE_TITLES,
  PATHS,
  PATH_SEGMENTS,
  ROLES,
  TUTORIAL_TARGETS,
} from '../../utils/constants';
import Icons from '../Icons/Icons';
import NotificationsBadge from '../NotificationsBadge/NotificationsBadge';
import tabTrapCurried from '../../utils/tabTrapCurried';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import * as auth from '../../auth';
import {
  selectIsNavSliderOpen,
  toggleIsNavSliderOpen,
} from '../../state/widgets/widgets.slice';
import {
  selectUser,
  toggleIsTutorialComplete,
} from '../../state/user/user.slice';
import BodyLock from '../BodyLock/BodyLock';
import { NavFeatureFlags, selectConfig } from '../../state/config/config.slice';
import styles from './NavSlider.module.css';

/**
 * First `NavLink` focus classes
 */
const focus =
  'focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-brand-shade-blue';

/**
 * `NavLink` class helper
 */
const getClasses = (isActive: boolean) => {
  const classes =
    'relative flex items-center rounded-lg mb-1 px-4 py-2 hover:bg-brand-green hover:text-brand-white md:py-3';
  return cn(classes, isActive && styles.activeNavLink);
};

/**
 * Main application navigation with body lock
 */
const NavSlider = () => {
  const isNavSliderOpen = useAppSelector(selectIsNavSliderOpen);
  const { role, isAIChatUser } = useAppSelector(selectUser);
  const {
    featureFlags: { navigation: navFeatureFlags },
  } = useAppSelector(selectConfig);
  const dispatch = useAppDispatch();
  const firstLinkRef = useRef<HTMLAnchorElement | null>(null);

  const isCorporateAdminOrDev = [
    ROLES.CORPORATE,
    ROLES.ADMIN,
    ROLES.DEVELOPER,
  ].includes(role);
  const isAdminOrDev = [ROLES.ADMIN, ROLES.DEVELOPER].includes(role);

  // event listeners
  useEffect(() => {
    if (!isNavSliderOpen) return;

    const handleBlur = (e: MouseEvent) => {
      if (
        !(
          (e.target as HTMLElement).closest?.('#' + NAV.SLIDER) ||
          (e.target as HTMLElement).closest?.('#' + NAV.SLIDER_BUTTON)
        )
      )
        dispatch(toggleIsNavSliderOpen(false));
    };

    const tabTrap = tabTrapCurried(`#${NAV.SLIDER}`);

    (firstLinkRef.current as HTMLAnchorElement)?.focus();
    window.addEventListener(EVENTS.KEYDOWN, tabTrap);
    window.addEventListener(EVENTS.CLICK, handleBlur);

    return () => {
      window.removeEventListener(EVENTS.KEYDOWN, tabTrap);
      window.removeEventListener(EVENTS.CLICK, handleBlur);
    };
  }, [isNavSliderOpen, dispatch]);

  // short circuit if not open
  if (!isNavSliderOpen) {
    return null;
  }

  const handleNavSliderClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    const target = (e.target as HTMLElement).closest('a');
    if (!target) return;

    if (target.title === PAGE_TITLES.LOGOUT) {
      return auth.logout();
    }

    if (target.title === PAGE_TITLES.TUTORIAL) {
      // no db update; just toggle local state
      dispatch(toggleIsTutorialComplete(false));
    }

    dispatch(toggleIsNavSliderOpen(false));
  };

  return (
    <>
      <Nav
        {...{
          navFeatureFlags,
          isAIChatUser,
          isCorporateAdminOrDev,
          isAdminOrDev,
          handleNavSliderClick,
          firstLinkRef,
        }}
      />
      <BodyLock />
    </>
  );
};

interface NavProps {
  navFeatureFlags: NavFeatureFlags;
  isAIChatUser: boolean;
  isCorporateAdminOrDev: boolean;
  isAdminOrDev: boolean;
  handleNavSliderClick: (e: SyntheticEvent) => void;
  firstLinkRef?: MutableRefObject<HTMLAnchorElement | null>;
}

/**
 * Nav component for NavSlider
 */
export const Nav = ({
  navFeatureFlags,
  isAIChatUser,
  isCorporateAdminOrDev,
  isAdminOrDev,
  handleNavSliderClick,
  firstLinkRef,
}: NavProps) => (
  <nav
    id={NAV.SLIDER}
    className={cn(
      styles.nav,
      'thin-scrollbar fixed bottom-0 z-20 w-full overflow-y-auto bg-brand-white px-5 py-3 lg:w-80 lg:shadow-lg'
    )}
  >
    <div onClick={handleNavSliderClick} className='mb-28 lg:mb-0'>
      <div className='mb-3' id={TUTORIAL_TARGETS.NAV_CONTENT}>
        <p className={styles.navSectionHeading}>Content</p>
        {/* Home */}
        <NavLink
          title={PAGE_TITLES.HOME}
          to={PATHS.home}
          ref={firstLinkRef}
          className={({ isActive }) => cn(getClasses(isActive), focus)}
        >
          <Icons.Home className={styles.icon} />
          <p>{PAGE_TITLES.HOME}</p>
        </NavLink>

        {/* Browse */}
        <NavLink
          title={PAGE_TITLES.BROWSE}
          to={PATH_SEGMENTS.browse}
          className={({ isActive }) => cn(getClasses(isActive))}
        >
          <Icons.Browse className={styles.icon} />
          <p>{PAGE_TITLES.BROWSE}</p>
        </NavLink>

        {/* Favorites */}
        <NavLink
          title={PAGE_TITLES.FAVORITES}
          to={PATHS.favorites}
          className={({ isActive }) => cn(getClasses(isActive))}
        >
          <Icons.Favorites className={styles.favorite} />
          <p>{PAGE_TITLES.FAVORITES}</p>
        </NavLink>

        {/* AI chat */}
        {navFeatureFlags.aiChat && isCorporateAdminOrDev && isAIChatUser && (
          <NavLink
            title={PAGE_TITLES.AI_CHAT}
            to={PATHS.aiChat}
            className={({ isActive }) => cn(getClasses(isActive))}
          >
            <Icons.Chat
              className={`${styles.aiChat} relative top-0.5 h-6 w-6`}
            />
            <p style={{ paddingLeft: '0.75rem' }}>{PAGE_TITLES.AI_CHAT}</p>
          </NavLink>
        )}

        {/* DSA insights */}
        {navFeatureFlags.dsaInsights && isCorporateAdminOrDev && (
          <NavLink
            title={PAGE_TITLES.DSA_INSIGHTS}
            to={PATHS.dsaInsightsFeed}
            className={({ isActive }) => cn(getClasses(isActive))}
          >
            <Icons.DSAInsights className={styles.icon} />
            <p>{PAGE_TITLES.DSA_INSIGHTS}</p>
          </NavLink>
        )}

        <hr />
      </div>

      <div className='mb-3' id={TUTORIAL_TARGETS.NAV_SUPPORT}>
        <p className={styles.navSectionHeading}>Support</p>

        {/* Notifications */}
        <NavLink
          title={PAGE_TITLES.NOTIFICATIONS}
          to={PATHS.notifications}
          className={({ isActive }) => cn(getClasses(isActive))}
        >
          <Icons.Notifications className={styles.icon} />
          <p>{PAGE_TITLES.NOTIFICATIONS}</p>
          <NotificationsBadge classNames={styles.notificationsBadge} />
        </NavLink>

        {/* FAQs */}
        <NavLink
          title={PAGE_TITLES.FAQS}
          to={PATHS.faqs}
          className={({ isActive }) => cn(getClasses(isActive))}
        >
          <Icons.FAQ className={styles.icon} />
          <p>{PAGE_TITLES.FAQS}</p>
        </NavLink>

        {/* DSA teams */}
        {isCorporateAdminOrDev && (
          <NavLink
            title={PAGE_TITLES.DSA_TEAMS}
            to={PATHS.dsaTeams}
            className={({ isActive }) => cn(getClasses(isActive))}
          >
            <Icons.DSATeams className={styles.icon} />
            <p>{PAGE_TITLES.DSA_TEAMS}</p>
          </NavLink>
        )}

        {/* Tutorial */}
        <NavLink
          title={PAGE_TITLES.TUTORIAL}
          to={PATHS.home}
          {...(window.location.pathname === PATHS.home && { replace: true })}
          className={() => cn(getClasses(false))}
        >
          <Icons.Tutorial className={styles.icon} />
          <p>{PAGE_TITLES.TUTORIAL}</p>
        </NavLink>
      </div>

      {navFeatureFlags.adminPortal && isAdminOrDev && (
        <div className='mb-3'>
          <hr />
          <p className={styles.navSectionHeading}>Admin</p>

          {/* Admin portal */}
          <NavLink
            title={PAGE_TITLES.ADMIN_PORTAL}
            to={PATHS.adminPortal}
            className={({ isActive }) => cn(getClasses(isActive))}
          >
            <Icons.AdminPortal className={styles.icon} />
            <p>{PAGE_TITLES.ADMIN_PORTAL}</p>
          </NavLink>
        </div>
      )}
      <hr />

      {/* Settings */}
      {navFeatureFlags.settings && (
        <NavLink
          title={PAGE_TITLES.SETTINGS}
          to={PATHS.settings}
          className={({ isActive }) => cn(getClasses(isActive))}
        >
          <Icons.Settings className={styles.icon} />
          <p>{PAGE_TITLES.SETTINGS}</p>
        </NavLink>
      )}

      {/* Logout */}
      <NavLink
        title={PAGE_TITLES.LOGOUT}
        to={PATHS.home}
        className={() => cn(getClasses(false), focus)}
      >
        <Icons.Logout className={styles.icon} />
        <p>{PAGE_TITLES.LOGOUT}</p>
      </NavLink>
    </div>
  </nav>
);

export default NavSlider;
