import { Navigate } from 'react-router-dom';
import useMediaQuery from '../hooks/useMediaQuery';
import { MEDIA_QUERIES, PATHS, SEARCHPARAM_KEYS } from '../utils/constants';
import useSearchResults from '../hooks/useSearchResults';
import useContentURLParams from '../hooks/useContentURLParams';
import useValidateContentURLParams from '../hooks/useValidateContentURLParams';
import Spinner from '../components/Spinner/Spinner';
import NoSearchResultsMessage from '../components/NoSearchResultsMessage/NoSearchResultsMessage';
import { SEARCH_SORT_OPTIONS } from '../types/sort.types';
import DesktopSearchResultsContainer from '../components/DesktopSearchResultsContainer/DesktopSearchResultsContainer';
import MobileSearchResultsContainer from '../components/MobileSearchResultsContainer/MobileSearchResultsContainer';

/**
 * Search results page
 */
const SearchResultsPage = () => {
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const searchResults = useSearchResults();
  const [
    isQuerySearchParamValid,
    isSortSearchParamValid,
    isTagSearchParamsValid,
  ] = useValidateContentURLParams();
  const {
    searchParams: { querySearchParam },
  } = useContentURLParams();

  // no query
  if (!isQuerySearchParamValid)
    return <Navigate to={PATHS.home} replace={true} />;

  // invalid sort or tags
  if (!isSortSearchParamValid || !isTagSearchParamsValid)
    return (
      <Navigate
        to={`${PATHS.searchResults}?${SEARCHPARAM_KEYS.QUERY}=${querySearchParam}&${SEARCHPARAM_KEYS.SORT}=${SEARCH_SORT_OPTIONS.RELEVANCE}`}
        replace={true}
      />
    );

  if (!searchResults) return <Spinner.BelowHeader />;

  // no results
  if (searchResults && !searchResults.length) return <NoSearchResultsMessage />;

  return (
    <>
      {isDesktop ? (
        <DesktopSearchResultsContainer {...{ searchResults }} />
      ) : (
        <MobileSearchResultsContainer {...{ searchResults }} />
      )}
    </>
  );
};

export default SearchResultsPage;
