import cn from 'classnames';
import Icons from '../Icons/Icons';

interface PinButtonProps {
  isDisabled: boolean;
  isPinned: boolean;
  _id: string;
  classNames?: string;
}

/**
 * Pin component for pinning Favorites
 */
const PinButton = ({
  isDisabled,
  isPinned,
  _id,
  classNames,
}: PinButtonProps) => (
  <button
    className={cn(classNames, isDisabled && 'cursor-progress')}
    data-id={`pin${_id}`}
    title={isPinned ? 'Remove pin' : 'Pin to top'}
    disabled={isDisabled}
  >
    {isPinned ? (
      <Icons.PinFilled
        data-testid='pin-filled'
        className={cn(
          isDisabled
            ? 'fill-brand-grey stroke-brand-grey'
            : 'fill-brand-green stroke-brand-green'
        )}
      />
    ) : (
      <Icons.PinEmpty
        data-testid='pin-empty'
        className={cn(
          isDisabled
            ? 'fill-brand-grey stroke-brand-grey'
            : 'stroke-brand-black'
        )}
      />
    )}
  </button>
);
export default PinButton;
