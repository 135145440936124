import { lazy } from 'react';

const CookiesErrorMessage = lazy(
  () => import('../CookiesErrorMessage/CookiesErrorMessage')
);

const CookiesCheck = ({ children }: { children: JSX.Element }) =>
  navigator.cookieEnabled ? children : <CookiesErrorMessage />;

export default CookiesCheck;
