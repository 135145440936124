import { Dispatch, SetStateAction, useState } from 'react';
import { PathMatch, useMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useContentURLParams from '../../hooks/useContentURLParams';
import {
  selectIsFilterByTagsTakeoverOpen,
  toggleIsFilterByTagsTakeoverOpen,
} from '../../state/widgets/widgets.slice';
import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import { SORT_AND_FILTER_TITLES, PATHS } from '../../utils/constants';
import BodyLock from '../BodyLock/BodyLock';
import Dropdown from '../Dropdown/Dropdown';
import FilterByTagsButton from '../FilterByTagsButton/FilterByTagsButton';
import FilterByTagsTakeover from '../FilterByTagsTakeover/FilterByTagsTakeover';
import ScreenTakeover from '../ScreenTakeover/ScreenTakeover';
import ClearFiltersButton from '../ClearFiltersButton/ClearFiltersButton';
import {
  SEARCH_SORT_OPTIONS,
  SEARCH_SORT_BY_OPTIONS,
  BROWSE_AND_FAVORITES_SORT_BY_OPTIONS,
} from '../../types/sort.types';

interface MobileSortAndFilterTakeoverProps {
  handleCloseScreenTakeover: () => void;
  content: WithObjectId<Content>[];
  isDropdownDisabled: boolean;
}

/**
 * Mobile takeover for sorting and filtering in Search Results, Browse and Favorites pages
 */
const MobileSortAndFilterTakeover = ({
  handleCloseScreenTakeover,
  content,
  isDropdownDisabled,
}: MobileSortAndFilterTakeoverProps) => {
  const isFilterByTagsTakeoverOpen = useAppSelector(
    selectIsFilterByTagsTakeoverOpen
  );
  const {
    setters: { setTagAndSortParams, clearTagAndSortParams },
    searchParams: { sortSearchParam, tagSearchParams },
  } = useContentURLParams();
  const isSearchResultsMatch = useMatch(PATHS.searchResults);
  const [selectedSortOption, setSelectedSortOption] = useState(
    (sortSearchParam as SEARCH_SORT_OPTIONS) ||
      (isSearchResultsMatch
        ? SEARCH_SORT_OPTIONS.RELEVANCE
        : SEARCH_SORT_OPTIONS.AZ)
  );
  const [stagedSelectedTags, setStagedSelectedTags] = useState(tagSearchParams);

  const applyButtonTitle = SORT_AND_FILTER_TITLES.APPLY;

  /**
   * Apply sort and filter handler
   */
  const handleApplyClick = () => {
    setTagAndSortParams(stagedSelectedTags, selectedSortOption);
    handleCloseScreenTakeover();
  };

  return (
    <>
      <ScreenTakeover
        handleCloseScreenTakeover={handleCloseScreenTakeover}
        title={SORT_AND_FILTER_TITLES.FILTER_BY}
        zIndex='z-20'
        classNames='flex flex-col'
      >
        <MobileSortAndFilterTakeover.Controls
          setSelectedSortOption={setSelectedSortOption}
          selectedSortOption={selectedSortOption}
          isSearchResultsMatch={isSearchResultsMatch}
          isDropdownDisabled={isDropdownDisabled}
          stagedSelectedTags={stagedSelectedTags}
        />
        {/* Apply and Clear filters buttons */}
        <MobileSortAndFilterTakeover.Buttons
          {...{
            handleApplyClick,
            applyButtonTitle,
            clearTagAndSortParams,
            handleCloseScreenTakeover,
          }}
        />
      </ScreenTakeover>
      {isFilterByTagsTakeoverOpen && (
        <FilterByTagsTakeover
          content={content}
          setStagedSelectedTags={setStagedSelectedTags}
        />
      )}
      <BodyLock />
    </>
  );
};

export default MobileSortAndFilterTakeover;

interface MobileTakeoverControlsProps {
  setSelectedSortOption: Dispatch<SetStateAction<SEARCH_SORT_OPTIONS>>;
  selectedSortOption: SEARCH_SORT_OPTIONS;
  isSearchResultsMatch: PathMatch<string> | null;
  isDropdownDisabled: boolean;
  stagedSelectedTags: string[];
}

const MobileTakeoverControls = ({
  setSelectedSortOption,
  selectedSortOption,
  isSearchResultsMatch,
  isDropdownDisabled,
  stagedSelectedTags,
}: MobileTakeoverControlsProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className='mb-4'>
      <Dropdown
        handleOptionChange={option => setSelectedSortOption(option)}
        title={SORT_AND_FILTER_TITLES.SORT_BY}
        value={selectedSortOption}
        options={
          isSearchResultsMatch
            ? SEARCH_SORT_BY_OPTIONS
            : BROWSE_AND_FAVORITES_SORT_BY_OPTIONS
        }
        isDisabled={isDropdownDisabled}
      />
      <FilterByTagsButton
        handleOpenFilterByTagsTakeover={() =>
          dispatch(toggleIsFilterByTagsTakeoverOpen(true))
        }
        isFilterButtonActive={!!stagedSelectedTags.length}
      />
    </div>
  );
};

interface MobileTakeoverButtonsProps {
  handleApplyClick: () => void;
  applyButtonTitle: string;
  clearTagAndSortParams: () => void;
  handleCloseScreenTakeover: () => void;
}

/**
 * Apply filters button for `ScreenTakeover`
 */
const MobileTakeoverButtons = ({
  handleApplyClick,
  applyButtonTitle,
  clearTagAndSortParams,
  handleCloseScreenTakeover,
}: MobileTakeoverButtonsProps) => (
  <div className='mt-auto flex w-full gap-4'>
    <button
      title={applyButtonTitle}
      onClick={handleApplyClick}
      className='h-14 w-1/2 grow rounded-full bg-brand-green font-medium text-brand-white'
    >
      {applyButtonTitle}
    </button>
    <ClearFiltersButton
      handleClearClick={() => {
        clearTagAndSortParams();
        handleCloseScreenTakeover();
      }}
      title={SORT_AND_FILTER_TITLES.CLEAR_FILTERS}
    />
  </div>
);

MobileSortAndFilterTakeover.Controls = MobileTakeoverControls;
MobileSortAndFilterTakeover.Buttons = MobileTakeoverButtons;
