import useContentURLParams from '../../hooks/useContentURLParams';

/**
 * "No search results for ..."
 */
const NoSearchResultsMessage = () => {
  const {
    searchParams: { querySearchParam },
  } = useContentURLParams();
  return <h1 className='mb-16'>No search results for "{querySearchParam}"</h1>;
};

export default NoSearchResultsMessage;
