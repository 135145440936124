import { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

import encode from '../../utils/encode';
import Icons from '../Icons/Icons';

interface FolderProps extends PropsWithChildren {
  name: string;
  itemCount: number;
}

/**
 * A generic folder
 */
const Folder = ({ name, itemCount }: FolderProps) => {
  const location = useLocation();
  return (
    <Link
      className='rounded-2xl bg-brand-white p-4 shadow-sm transition-shadow hover:shadow-lg'
      to={`${location.pathname}/${encode(name)}${location.search}`}
    >
      <div className='flex items-center'>
        <div className='mr-4 flex items-center justify-center rounded-lg bg-brand-tint-grey-1 p-4'>
          <Icons.FolderIcon className='stroke-brand-shade-grey' />
        </div>
        <div className='mr-4 grow'>
          <p className='mb-0.5 line-clamp-1 font-semibold'>{name}</p>
          <p className='text-sm font-normal text-brand-shade-grey'>
            {itemCount} {`item${itemCount === 1 ? '' : 's'}`}
          </p>
        </div>
        <Icons.ChevronRight className='stroke-brand-shade-grey' />
      </div>
    </Link>
  );
};

export default Folder;
