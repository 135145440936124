import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EPOCH, STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import { fetchApplicationStateUpdates } from '../user/user.slice';
import { SYSLOG_SEVERITY } from '../../types/syslog.types';
import configAPI from './config.api';

export interface NavFeatureFlags {
  adminPortal: boolean;
  aiChat: boolean;
  dsaInsights: boolean;
  settings: boolean;
}

export interface SearchWidgetsFeatureFlagsConfig {
  keywords: boolean;
  nGrams: boolean;
  contentEngagement: boolean;
  parentFolders: boolean;
}

interface AdminPortalContentFeatureFlagsConfig {
  searchWidgets: SearchWidgetsFeatureFlagsConfig;
}

export interface ContentFeatureFlags {
  images: boolean;
  banner: boolean;
  adminPortal: AdminPortalContentFeatureFlagsConfig;
}

interface AIChatContent {
  information: string;
  disclaimer: string;
  limits: {
    userCharInputLimit: number;
    messagesLimit: number;
  };
  llmTimeout: number; // only used for API backend not UI
}

export interface BannerContent {
  severity: SYSLOG_SEVERITY;
  message: string;
  isDismissible: boolean;
  updatedAt: string; // ISO string
}

export interface Config {
  featureFlags: {
    navigation: NavFeatureFlags;
    content: ContentFeatureFlags;
  };
  content: {
    aiChat: AIChatContent;
    banner: BannerContent;
  };
  releaseDate: string;
  forceRefresh: boolean;
}

export interface ConfigState {
  status: STATE_STATUSES;
  data: Config;
}

export const initialState: ConfigState = {
  status: STATE_STATUSES.INITIAL,
  data: {
    featureFlags: {
      navigation: {
        adminPortal: false,
        aiChat: false,
        dsaInsights: false,
        settings: false,
      },
      content: {
        images: false,
        banner: false,
        adminPortal: {
          searchWidgets: {
            keywords: false,
            nGrams: false,
            contentEngagement: false,
            parentFolders: false,
          },
        },
      },
    },
    content: {
      aiChat: {
        information: '',
        disclaimer: '',
        limits: {
          userCharInputLimit: 0,
          messagesLimit: 0,
        },
        llmTimeout: 0, // only used for API backend not UI
      },
      banner: {
        severity: SYSLOG_SEVERITY.INFO,
        message: '',
        isDismissible: false,
        updatedAt: EPOCH,
      },
    },
    releaseDate: EPOCH,
    forceRefresh: false,
  },
};

export const fetchConfig = createAsyncThunk(
  'config/fetchConfig',
  async (_, thunkAPI) => {
    const response = await configAPI.fetchConfig(thunkAPI.signal);
    return response.data;
  }
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConfig.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.status = STATE_STATUSES.FULFILLED;
        state.data = action.payload;
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        if (!action.meta.aborted) {
          // update status for `error.slice`
          state.status = STATE_STATUSES.REJECTED;
        }
      })
      //update based on application state updates interval request
      .addCase(fetchApplicationStateUpdates.fulfilled, (state, action) => {
        const { config } = action.payload;

        if (config) {
          state.data = config;
        }
      });
  },
});

export const configReducer = configSlice.reducer;

export const selectConfigStatus = (state: RootState) => state.config.status;

export const selectConfig = (state: RootState) => state.config.data;
