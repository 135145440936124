import { useAppSelector } from '../../hooks/redux';
import { selectConfig } from '../../state/config/config.slice';
import ContentCards from '../ContentCards/ContentCards';
import ContentCardsWithoutImage from '../ContentCardsWithoutImage/ContentCardsWithoutImage';
import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import useContentURLParams from '../../hooks/useContentURLParams';
import useHandleFavoriteOrPinOrAppOrContentClick from '../../hooks/useHandleFavoriteOrPinOrAppOrContentClick';

export const cardsContainerTitle = 'cards-container';

export interface ContentCardsProps {
  content: WithObjectId<Content>[];
}
/**
 * ContentCardsContainer component
 * @note conditionally renders `ContentCards` or `ContentCardsWithoutImage` based on feature flag
 */
const ContentCardsContainer = ({ content }: ContentCardsProps) => {
  const {
    searchParams: { querySearchParam },
  } = useContentURLParams();

  const {
    featureFlags: { content: contentFeatureFlags },
  } = useAppSelector(selectConfig);

  const handleClickContentOrAppOrFavorite =
    useHandleFavoriteOrPinOrAppOrContentClick();

  return (
    <section
      onClick={e => handleClickContentOrAppOrFavorite(e, querySearchParam)}
      data-testid={cardsContainerTitle}
      className='mx-auto grid max-w-sm gap-4 sm:max-w-none sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-6 2xl:gap-8'
    >
      {contentFeatureFlags.images ? (
        <ContentCards content={content} />
      ) : (
        <ContentCardsWithoutImage content={content} />
      )}
    </section>
  );
};

export default ContentCardsContainer;
