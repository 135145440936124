import { SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useMediaQuery from '../../hooks/useMediaQuery';
import {
  selectIsNotificationsOpen,
  toggleIsNotificationsOpen,
} from '../../state/widgets/widgets.slice';
import {
  EVENTS,
  MEDIA_QUERIES,
  NOTIFICATIONS,
  TUTORIAL_TARGETS,
} from '../../utils/constants';
import Icons from '../Icons/Icons';
import DesktopNotificationsDropdown from '../DesktopNotificationsDropdown/DesktopNotificationsDropdown';
import { selectUser } from '../../state/user/user.slice';

const iconAlertClasses =
  'absolute right-3 top-1.5 h-3 w-3 fill-brand-red lg:right-7';

/**
 * Notifications button for menu
 */
const NotificationsButton = () => {
  const dispatch = useAppDispatch();
  const { unreadNotifications } = useAppSelector(selectUser);
  const isNotificationsOpen = useAppSelector(selectIsNotificationsOpen);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);

  const handleButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    const ev = new Event(EVENTS.CLICK);
    window.dispatchEvent(ev);

    dispatch(toggleIsNotificationsOpen(!isNotificationsOpen));
  };

  return (
    <div className='relative'>
      <button
        title={NOTIFICATIONS}
        id={TUTORIAL_TARGETS.NOTIFICATIONS_BTN}
        className='relative flex items-center justify-self-end p-[--layout-padding] lg:px-[--lg-layout-padding]'
        onClick={handleButtonClick}
      >
        <Icons.NotificationsIcon className='fill-brand-black' />
        {unreadNotifications.length > 0 && (
          <>
            <Icons.NotificationsIconAlert
              className={`${iconAlertClasses} animate-ping`}
            />
            <Icons.NotificationsIconAlert className={iconAlertClasses} />
          </>
        )}
      </button>
      {isDesktop && isNotificationsOpen && <DesktopNotificationsDropdown />}
    </div>
  );
};

export default NotificationsButton;
