import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import {
  SPINNER_SIZES,
  EVENTS,
  REPLACE,
  LOOKER_EVENTS,
  LOOKER_SESSION_PARAM,
} from '../../utils/constants';
import encode from '../../utils/encode';
import api from '../../http';
import { getIsUserLoggedIn, login } from '../../auth';
import ContentItem from '../ContentItem/ContentItem';

/**
 * LookerContentItemAuthWrapper component
 */
const LookerContentItemAuthWrapper = ({
  content,
}: {
  content: WithObjectId<Content>;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pagePropChangeCount = useRef(0); // to track number of change of page properties
  const [showSpinner, setShowSpinner] = useState(true);
  const [showLookerButton, setShowLookerButton] = useState(false);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | null>(null);
  const [originalSearchQuery] = useState(window.location.search);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [isHiddenImageListenerError, setIsHiddenImageListenerError] =
    useState(false);

  /**
   * If user is not logged in to Microsoft, redirect to login
   */
  useEffect(() => {
    const redirectIfNotLoggedIn = async () => {
      const isUserLoggedIn = getIsUserLoggedIn();
      if (!isUserLoggedIn) await login();
    };
    redirectIfNotLoggedIn();
  }, [isHiddenImageListenerError]);

  // `setTimeout` to show Looker login button
  useEffect(() => {
    // if timeout has expired, set show Looker button to true and show spinner to false
    const newTimeoutID: NodeJS.Timeout = setTimeout(async () => {
      if (!originalSearchQuery.includes(LOOKER_SESSION_PARAM)) {
        setShowLookerButton(true);
        setShowSpinner(false);
      }
    }, 120_000);
    setTimeoutID(newTimeoutID);
    // resetting the pagePropChangeCount
    pagePropChangeCount.current = 0;
    return () => {
      clearTimeout(newTimeoutID);
    };
  }, [originalSearchQuery]);

  // handle iframe messaging
  useEffect(() => {
    const controller = new AbortController();

    // event listener callback
    const handleIframeMessage = (e: MessageEvent) => {
      const isFromIframe = e.source === iframeRef.current?.contentWindow;
      const isFromLooker = e.origin === process.env.REACT_APP_LOOKER_URL;

      if (isFromIframe && isFromLooker) {
        const data = JSON.parse(e.data);
        //upon receipt of first message from Looker, remove spinner
        if (data?.type) {
          if (data.type === LOOKER_EVENTS.PAGE_PROPERTIES_CHANGED) {
            pagePropChangeCount.current += 1;
            // triggering add view api only when page:properties:changed for first time
            pagePropChangeCount.current === 1 &&
              api.addView(content._id, controller.signal);
          }

          setShowSpinner(false);
          setShowLookerButton(false);
          timeoutID !== null && clearTimeout(timeoutID);
        }
      }
    };

    window.addEventListener(EVENTS.MESSAGE, handleIframeMessage);
    return () => {
      window.removeEventListener(EVENTS.MESSAGE, handleIframeMessage);
      controller.abort();
    };
  }, [timeoutID, content._id]);

  // swallow search query and update `pathname`
  useEffect(() => {
    if (location.search.includes(LOOKER_SESSION_PARAM)) {
      navigate(`${location.pathname}-${encode(content.title)}`, REPLACE);
    }
  }, [content.title, location.pathname, location.search, navigate]);

  return (
    <div className='relative flex h-full items-center justify-center'>
      {showSpinner && !showLookerButton && (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      )}

      {!showSpinner && showLookerButton && (
        <LoginToLookerButton contentId={content._id} />
      )}

      <ContentItem
        {...{
          embedLink: `${process.env.REACT_APP_LOOKER_URL}/embed/dashboards/${content.idFromSource}?embed_domain=${window.location.origin}&allow_login_screen=true`,
          contentId: content._id,
          iframeRef,
          isHidden: showSpinner || showLookerButton,
        }}
      />

      <LookerHiddenImageListener
        {...{
          idFromSource: content.idFromSource,
          setIsHiddenImageListenerError,
        }}
      />
    </div>
  );
};

/**
 * Login to Looker button
 */
const LoginToLookerButton = ({ contentId }: { contentId: string }) => (
  <a
    className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-brand-green px-6 py-4 font-semibold text-brand-white'
    href={`${process.env.REACT_APP_LOOKER_URL}/embed/dashboards-next/${process.env.REACT_APP_LOOKER_DASH_ID}?allow_login_screen=true&Return+ID=${contentId}`}
  >
    Login to Looker
  </a>
);

interface LookerHiddenImageListenerProps {
  idFromSource: string;
  setIsHiddenImageListenerError: Dispatch<SetStateAction<boolean>>;
}

/**
 * Listen for error to determine if user is logged into Looker
 */
const LookerHiddenImageListener = ({
  idFromSource,
  setIsHiddenImageListenerError,
}: LookerHiddenImageListenerProps) => (
  <img
    src={`${process.env.REACT_APP_LOOKER_URL}/api/internal/core/4.0/content_thumbnail/dashboard/${idFromSource}`}
    alt=''
    onError={() => setIsHiddenImageListenerError(true)}
    className='hidden'
  />
);

export default LookerContentItemAuthWrapper;
