import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ROLES, STATE_STATUSES } from '../../utils/constants';
import { FAQsData } from '../../types/faqs.types';
import { RootState } from '../store';
import faqsAPI from './faqs.api';

export interface FAQ {
  question: string;
  answer: string;
  roles: ROLES[];
}

export interface FAQsState {
  status: STATE_STATUSES;
  data: FAQsData['faqs'];
}

export const initialState: FAQsState = {
  status: STATE_STATUSES.INITIAL,
  data: [],
};

export const fetchFAQs = createAsyncThunk(
  'faqs/fetchFAQs',
  async (_, thunkAPI) => {
    const response = await faqsAPI.fetchFAQs(thunkAPI.signal);
    return response.data;
  }
);

export const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFAQs.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchFAQs.fulfilled, (state, action) => {
        return {
          status: STATE_STATUSES.FULFILLED,
          data: action.payload.faqs,
        };
      })
      .addCase(fetchFAQs.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = STATE_STATUSES.REJECTED;
        }
      });
  },
});
export const faqsReducer = faqsSlice.reducer;

export const selectFAQs = (state: RootState) => state.faqs.data;
export const selectIsFAQsFetching = (state: RootState) =>
  [STATE_STATUSES.INITIAL, STATE_STATUSES.PENDING].includes(state.faqs.status);
export const selectIsFAQsFulfilled = (state: RootState) =>
  state.faqs.status === STATE_STATUSES.FULFILLED;
export const selectIsFAQsRejected = (state: RootState) =>
  state.faqs.status === STATE_STATUSES.REJECTED;
