import { hubAPI } from '../../http';
import { Statuses } from './statuses.slice';

const fetchStatuses = (signal: AbortSignal) =>
  hubAPI.get<Statuses>('/api/statuses', { signal });

const statusesAPI = {
  fetchStatuses,
};

export default statusesAPI;
