import Tag from '../../Tag/Tag';

interface SelectedTagsProps {
  selectedTags: string[];
  toggleIsSelected: (tag: string) => void;
}

/**
 * Selected tags for `FilterByTagsTakeover`
 */
const SelectedTags = ({
  selectedTags,
  toggleIsSelected,
}: SelectedTagsProps) => {
  return (
    <div className='mb-2 flex flex-wrap gap-1'>
      {selectedTags.map(tag => (
        <Tag
          key={tag}
          tag={tag}
          classNames='bg-brand-green text-brand-white'
          onClick={() => toggleIsSelected(tag)}
        />
      ))}
    </div>
  );
};

export default SelectedTags;
