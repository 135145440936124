/**
 * Displays number of items (e.g., for search results page)
 */
const ItemsCount = ({ count }: { count: number }) => {
  return (
    <p>
      {count} {count === 1 ? 'item' : 'items'}
    </p>
  );
};

export default ItemsCount;
