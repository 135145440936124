import cn from 'classnames';

interface ClearFiltersButtonProps {
  handleClearClick: () => void;
  title: string;
  isForSortAndFilterContainer?: boolean;
}

/**
 * Clear filters button for `ScreenTakeover` and `DesktopSortAndFilterContainer`
 */
const ClearFiltersButton = ({
  handleClearClick,
  title,
  isForSortAndFilterContainer = false,
}: ClearFiltersButtonProps) => (
  <button
    title={title}
    onClick={handleClearClick}
    className={cn(
      'font-medium',
      isForSortAndFilterContainer
        ? 'border-none text-brand-red'
        : 'w-1/2 grow rounded-full border-2'
    )}
  >
    {title}
  </button>
);

export default ClearFiltersButton;
