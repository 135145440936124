import { useEffect } from 'react';
import cn from 'classnames';
import {
  fetchFAQs,
  selectFAQs,
  selectIsFAQsFetching,
  selectIsFAQsFulfilled,
  selectIsFAQsRejected,
} from '../../state/faq/faqs.slice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { FAQS_HEADINGS } from '../../utils/constants';
import { FAQsData } from '../../types/faqs.types';
import Accordion from '../Accordion/Accordion';
import Spinner from '../Spinner/Spinner';

import styles from './FAQs.module.css';

/**
 * FAQs container component
 */
const FAQs = () => {
  const dispatch = useAppDispatch();
  const faqs = useAppSelector(selectFAQs);
  const isFetching = useAppSelector(selectIsFAQsFetching);
  const isFulfilled = useAppSelector(selectIsFAQsFulfilled);
  const isRejected = useAppSelector(selectIsFAQsRejected);

  useEffect(() => {
    if (isFulfilled) return;

    const fetchFAQsPromise = dispatch(fetchFAQs());

    return () => fetchFAQsPromise.abort();
  }, [dispatch, isFulfilled]);

  if (isFetching) {
    return <Spinner.BelowHeader />;
  } else if (isRejected) {
    return <h1>{FAQS_HEADINGS.REJECTED}</h1>;
  }

  return faqs.length ? (
    <>
      <h1 className='mb-8'>{FAQS_HEADINGS.HEADING}</h1>
      <FAQs.List faqs={faqs} />
    </>
  ) : (
    <h1>{FAQS_HEADINGS.NO_FAQS}</h1>
  );
};

/**
 * FAQs list for `FAQsPage`
 */
const FAQsList = ({ faqs }: FAQsData) => {
  return (
    <div className={cn('flex flex-col gap-4', styles.faqsContainer)}>
      {faqs.map(({ question, answer, _id }, i) => (
        <Accordion
          key={_id}
          title={question}
          body={
            <div
              className={styles.accordionPanelBody}
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          }
          index={i}
        />
      ))}
    </div>
  );
};

FAQs.List = FAQsList;

export default FAQs;
