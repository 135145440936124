import { SyntheticEvent, useCallback } from 'react';
import {
  addFavorite,
  addPinnedFavorite,
  removeFavorite,
  removePinnedFavorite,
  selectUserDataMaps,
} from '../state/user/user.slice';
import { useAppDispatch, useAppSelector } from './redux';

/**
 * Hook for adding or removing a user's favorite or pinned favorite
 */
const useToggleFavoriteOrPin = () => {
  const dispatch = useAppDispatch();
  const { allFavoritesMap, pinnedFavoritesMap } =
    useAppSelector(selectUserDataMaps);

  const handleFavoriteOrPin = useCallback(
    (e: SyntheticEvent) => {
      const target = (e.target as HTMLElement).closest(
        '[data-id]'
      ) as HTMLButtonElement;

      if (!target || target.disabled) return;

      const contentID = target.dataset.id as string;

      if (contentID.includes('pin')) {
        const pinnedID = contentID.replace('pin', '');

        dispatch(
          pinnedFavoritesMap[pinnedID]
            ? removePinnedFavorite(pinnedID)
            : addPinnedFavorite(pinnedID)
        );

        return;
      }

      dispatch(
        allFavoritesMap[contentID]
          ? removeFavorite(contentID)
          : addFavorite(contentID)
      );
    },
    [allFavoritesMap, pinnedFavoritesMap, dispatch]
  );

  return handleFavoriteOrPin;
};

export default useToggleFavoriteOrPin;
