import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import AppInsights from '../../applicationInsights';
import { isProd } from '../../utils/isEnv';

/**
 * Initializes Azure Application Insights or axe
 */
const AnalyticsInit = ({ children }: { children: ReactElement }) => {
  isProd()
    ? AppInsights.init()
    : import('@axe-core/react').then(pkg => pkg.default(React, ReactDOM, 1000));

  return children;
};
export default AnalyticsInit;
