import { SyntheticEvent, useEffect, useRef } from 'react';
import cn from 'classnames';
import Icons from '../Icons/Icons';
import { DROPDOWN_MENU, EVENTS } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import curriedHandleUpAndDownArrowKeys from '../../utils/curriedHandleUpAndDownArrowKeys';
import BodyLock from '../BodyLock/BodyLock';
import {
  selectIsDropdownMenuOpen,
  toggleIsDropdownMenuOpen,
} from '../../state/widgets/widgets.slice';
import {
  SEARCH_SORT_BY_OPTIONS,
  SEARCH_SORT_OPTIONS,
} from '../../types/sort.types';

interface DropdownProps {
  handleOptionChange: (value: SEARCH_SORT_OPTIONS) => void;
  title: string;
  value: SEARCH_SORT_OPTIONS;
  options: typeof SEARCH_SORT_BY_OPTIONS;
  isDisabled: boolean;
}

/**
 * Dropdown for `DesktopSortAndFilterContainer` and `MobileSortAndFilterTakeover`
 */
const Dropdown = ({
  handleOptionChange,
  title,
  value,
  options,
  isDisabled,
}: DropdownProps) => {
  const dispatch = useAppDispatch();
  const ulRef = useRef<HTMLUListElement | null>(null);
  const isDropdownMenuOpen = useAppSelector(selectIsDropdownMenuOpen);

  const toggleDropDown = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(toggleIsDropdownMenuOpen(!isDropdownMenuOpen));
  };

  const selectedItem = (val: SEARCH_SORT_OPTIONS) => {
    const selected = options.find(op => op.value === val);
    return selected?.title;
  };

  const optionChange = (e: SyntheticEvent) => {
    const target = (e.target as HTMLElement).closest('li');
    const selelectedOption = target?.dataset.value as SEARCH_SORT_OPTIONS;
    selelectedOption && handleOptionChange(selelectedOption);
  };

  /**
   * Handle blur
   */
  useEffect(() => {
    const handleBlur = () => {
      // if dropdown is blurred in desktop, close
      dispatch(toggleIsDropdownMenuOpen(false));
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [dispatch]);

  useEffect(() => {
    const handleOnEnter = (selelectedOption: string) => {
      handleOptionChange(selelectedOption as SEARCH_SORT_OPTIONS);
      dispatch(toggleIsDropdownMenuOpen(!isDropdownMenuOpen));
    };

    const handleUpAndDownArrowKeys = curriedHandleUpAndDownArrowKeys(
      ulRef,
      null,
      false,
      handleOnEnter,
      'li'
    );
    window.addEventListener(EVENTS.KEYUP, handleUpAndDownArrowKeys);
    return () =>
      window.removeEventListener(EVENTS.KEYUP, handleUpAndDownArrowKeys);
  }, [isDropdownMenuOpen, dispatch, handleOptionChange]);

  return (
    <div className='relative'>
      <button
        onClick={toggleDropDown}
        title={title}
        className={cn(
          'mb-4 flex h-20 w-full flex-col justify-center rounded-3xl bg-brand-tint-grey-1 p-4 text-brand-green lg:mb-0 lg:h-full lg:w-44 lg:bg-brand-white lg:shadow',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        disabled={isDisabled}
      >
        <label
          id={DROPDOWN_MENU}
          className={cn(
            'text-brand-black',
            isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
        >
          {title}
        </label>
        <div className='flex w-full items-center justify-between'>
          <p>{selectedItem(value)}</p>
          {isDropdownMenuOpen ? (
            <Icons.ChevronUp className='h-3.5 w-3 fill-brand-shade-grey' />
          ) : (
            <Icons.ChevronDown className='h-3.5 w-3 fill-brand-shade-grey' />
          )}
        </div>
      </button>
      {isDropdownMenuOpen && (
        <>
          <ul
            onClick={optionChange}
            className='absolute top-[5.3rem] z-10 w-full overflow-y-auto rounded-3xl bg-brand-tint-grey-1 py-2 text-brand-green lg:w-44 lg:bg-brand-white lg:shadow'
            role='listbox'
            ref={ulRef}
            aria-labelledby={DROPDOWN_MENU}
          >
            {options.map(option => (
              <li
                tabIndex={0}
                role='option'
                aria-selected={selectedItem(value) === option.title}
                key={option.value}
                data-value={option.value}
                className={cn(
                  'cursor-pointer px-4 py-2 hover:bg-brand-tint-grey-2 lg:hover:bg-brand-tint-grey-1',
                  {
                    'bg-brand-tint-grey-1': value === option.value,
                  }
                )}
              >
                {option.title}
              </li>
            ))}
          </ul>
          <BodyLock />
        </>
      )}
    </div>
  );
};

export default Dropdown;
