import { PropsWithChildren, lazy } from 'react';
import { useMatch } from 'react-router-dom';
import cn from 'classnames';
import { MEDIA_QUERIES, PATHS, ROLES } from '../../utils/constants';
import useMediaQuery from '../../hooks/useMediaQuery';
import NavSlider from '../NavSlider/NavSlider';
import Toasts from '../Toasts/Toasts';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../state/user/user.slice';
import DesktopHeader from '../DesktopHeader/DesktopHeader';
import MobileHeader from '../MobileHeader/MobileHeader';
import MobileNotificationsDrawer from '../MobileNotificationsDrawer/MobileNotificationsDrawer';
import { selectConfig } from '../../state/config/config.slice';
import Spinner from '../Spinner/Spinner';

const MobileChatsDrawer = lazy(
  () => import('../AIChat/MobileChatsDrawer/MobileChatsDrawer')
);

/**
 * Global layout
 *
 * - There may be some exceptions like `/styleguide` and "no reporting users"
 */
const Layout = ({ children }: PropsWithChildren) => {
  const user = useAppSelector(selectUser);
  const config = useAppSelector(selectConfig);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const isContentMatch = useMatch(PATHS.content);
  const isAIChatMatch = useMatch(PATHS.aiChat);
  const hasAccess = user.role !== ROLES.NO_REPORTING;

  const Header = isDesktop ? DesktopHeader : MobileHeader;
  const isPathMatch = isContentMatch || isAIChatMatch;
  const bannerConfig = config.content.banner;
  const shouldShowBanner =
    config.featureFlags.content.banner &&
    new Date(bannerConfig.updatedAt) > new Date(user.banner.dateAcknowledged);

  if (isPathMatch) {
    Spinner.BelowHeader.classNames = shouldShowBanner
      ? heightCNs.isPathMatch.withBanner
      : heightCNs.isPathMatch.withoutBanner;
  } else {
    Spinner.BelowHeader.classNames = shouldShowBanner
      ? heightCNs.isNotPathMatch.withBanner
      : heightCNs.isNotPathMatch.withoutBanner;
  }

  return (
    <Layout.Container>
      {hasAccess && <Header {...{ shouldShowBanner, bannerConfig }} />}
      {hasAccess && <NavSlider />}
      <Layout.Main
        classNames={cn(
          !isPathMatch && Layout.classNames,
          isPathMatch &&
            (shouldShowBanner
              ? heightCNs.isPathMatch.withBanner
              : heightCNs.isPathMatch.withoutBanner)
        )}
      >
        {children}
      </Layout.Main>
      {hasAccess && !isDesktop && <MobileNotificationsDrawer />}
      {hasAccess && !isDesktop && isAIChatMatch && <MobileChatsDrawer />}
      {hasAccess && <Toasts />}
    </Layout.Container>
  );
};
export default Layout;

const LayoutContainer = ({ children }: PropsWithChildren) => (
  <div className='mx-auto min-h-dvh'>{children}</div>
);

interface LayoutMainProps extends PropsWithChildren {
  classNames?: string;
}

const LayoutMain = ({ children, classNames }: LayoutMainProps) => (
  <main className={cn('mx-auto', classNames)}>{children}</main>
);

Layout.Container = LayoutContainer;
Layout.Main = LayoutMain;
Layout.classNames =
  'p-[--layout-padding] lg:p-[--lg-layout-padding] 2xl:container';

const heightCNs = {
  isPathMatch: {
    withBanner:
      'h-[calc(100dvh-var(--header-mobile-height)-var(--site-banner-height))] lg:h-[calc(100vh-var(--header-desktop-height)-var(--site-banner-height))]',
    withoutBanner:
      'h-[calc(100dvh-var(--header-mobile-height))] lg:h-[calc(100vh-var(--header-desktop-height))]',
  },
  isNotPathMatch: {
    withBanner:
      'h-[calc(100vh-var(--header-mobile-height)-var(--site-banner-height)-var(--layout-padding)*2)] lg:h-[calc(100vh-var(--header-desktop-height)-var(--site-banner-height)-var(--lg-layout-padding)*2)]',
    withoutBanner:
      'h-[calc(100vh-var(--header-mobile-height)-var(--layout-padding)*2)] lg:h-[calc(100vh-var(--header-desktop-height)-var(--lg-layout-padding)*2)]',
  },
};
