import useContentURLParams from '../../hooks/useContentURLParams';

/**
 * "Search results for ..."
 */
const SearchResultsMessage = () => {
  const {
    searchParams: { querySearchParam },
  } = useContentURLParams();
  return <h1 className='mb-16'>Search results for "{querySearchParam}"</h1>;
};

export default SearchResultsMessage;
