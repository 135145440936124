import { useEffect } from 'react';
import { ReactComponent as NoActivity } from '../images/notifications-no-activity.svg';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { selectUser } from '../state/user/user.slice';
import { NOTIFICATIONS, NO_NEW_ACTIVITY } from '../utils/constants';
import NotificationCards from '../components/NotificationCards/NotificationCards';
import { dequeueAllToasts } from '../state/toast/toast.slice';
import MarkAllAsReadOrUnreadButton from '../components/MarkAllAsReadOrUnreadButton/MarkAllAsReadOrUnreadButton';

/**
 * Notifications page
 */
const NotificationsPage = () => {
  const dispatch = useAppDispatch();
  const { unreadNotifications, readNotifications } = useAppSelector(selectUser);

  // on mount, clear toasts
  useEffect(() => {
    dispatch(dequeueAllToasts());
  }, [dispatch]);

  const hasNotifications = !!(
    unreadNotifications.length || readNotifications.length
  );

  return (
    <>
      <div className='mx-auto max-w-screen-md'>
        <div className='mb-4 flex flex-wrap items-end justify-between lg:mb-8'>
          <h1 className='mr-4'>{NOTIFICATIONS}</h1>
          {/* Mark all as read or unread button */}
          {hasNotifications && <MarkAllAsReadOrUnreadButton />}
        </div>

        {hasNotifications ? (
          <ol className='flex flex-col gap-4'>
            <NotificationCards
              {...{ unreadNotifications, readNotifications }}
            />
          </ol>
        ) : (
          <div className='p-4'>
            <NoActivity className='mx-auto mb-8 max-w-screen-sm' />
            <p className='text-center text-2xl font-medium text-brand-grey'>
              {NO_NEW_ACTIVITY}
            </p>
          </div>
        )}
      </div>
    </>
  );
};
export default NotificationsPage;
