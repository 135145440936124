import axios from 'axios';
import * as auth from '../../auth';
import { hubAPI } from '../../http';
import { ObjectIdString } from '../../types';
import {
  ApplicationStateUpdatesResponse,
  NotificationsDoc,
  User,
} from '../../types/user.types';

/**
 * Get user
 */
const fetchUser = (signal: AbortSignal) =>
  hubAPI.get<User>('/api/users/me', { signal });

/**
 * Add user favorite
 */
const addFavorite = (favorite: string, signal: AbortSignal) =>
  hubAPI.patch<Pick<User, 'favorites'>>(
    '/api/users/me/add-favorite',
    {
      favorite,
    },
    { signal }
  );

/**
 * Remove user favorite
 */
const removeFavorite = (favorite: string, signal: AbortSignal) =>
  hubAPI.patch<Pick<User, 'favorites' | 'pinnedFavorites'>>(
    '/api/users/me/remove-favorite',
    {
      favorite,
    },
    {
      signal,
    }
  );

/**
 * Add pinned user favorite
 */
const addPinnedFavorite = (pinnedFavorite: string, signal: AbortSignal) =>
  hubAPI.patch<Pick<User, 'favorites' | 'pinnedFavorites'>>(
    '/api/users/me/add-pinned-favorite',
    {
      pinnedFavorite,
    },
    { signal }
  );

/**
 * Remove pinned user favorite
 */
const removePinnedFavorite = (pinnedFavorite: string, signal: AbortSignal) =>
  hubAPI.patch<Pick<User, 'favorites' | 'pinnedFavorites'>>(
    '/api/users/me/remove-pinned-favorite',
    {
      pinnedFavorite,
    },
    {
      signal,
    }
  );

/**
 * Check for new notifications
 */
const fetchUnreadNotifications = (signal: AbortSignal) =>
  hubAPI.get<NotificationsDoc>('/api/notifications', { signal });

/**
 * Mark notification(s) as read
 */
const addReadNotifications = (
  notifications: ObjectIdString[],
  signal: AbortSignal
) =>
  hubAPI.patch<NotificationsDoc>(
    '/api/users/me/read-notifications',
    { notifications },
    { signal }
  );

/**
 * Mark notification(s) as unread
 */
const removeReadNotifications = (
  notifications: ObjectIdString[],
  signal: AbortSignal
) =>
  hubAPI.patch<NotificationsDoc>(
    '/api/users/me/unread-notifications',
    { notifications },
    { signal }
  );

/**
 * Patch `isTutorialComplete`
 */
const patchIsTutorialComplete = (
  isTutorialComplete: boolean,
  signal: AbortSignal
) =>
  hubAPI.patch<void>(
    '/api/users/me/tutorial',
    { isTutorialComplete },
    { signal }
  );

/**
 * Check for application state updates
 */
const fetchApplicationStateUpdates = (signal: AbortSignal) =>
  hubAPI.get<ApplicationStateUpdatesResponse>(
    '/api/application-state-updates',
    { signal }
  );

/**
 * Patch `banner.dateAcknowledged`
 */
const dismissBanner = (date: Date, signal: AbortSignal) =>
  hubAPI.patch<void>(
    '/api/users/me/banner',
    {
      dateAcknowledged: date,
    },
    { signal }
  );

/**
 * Fetch headshot image
 *
 * size parameters can be found here:
 * https://learn.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0&tabs=javascript#path-parameters
 */
const fetchHeadshot = async (size: string, signal: AbortSignal) => {
  const account = auth.getAccount();
  const accessToken = await auth.getAccessToken(account);

  if (!accessToken) {
    return;
  }

  try {
    const { data } = await axios.get<Blob>(
      `https://graph.microsoft.com/v1.0/me/photos/${size}/$value`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob',
        signal,
      }
    );
    return data;
  } catch (error) {
    // Do nothing if a user does not have a profile photo
  }
};

const userAPI = {
  fetchUser,
  addFavorite,
  removeFavorite,
  addPinnedFavorite,
  removePinnedFavorite,
  fetchUnreadNotifications,
  fetchApplicationStateUpdates,
  addReadNotifications,
  removeReadNotifications,
  patchIsTutorialComplete,
  dismissBanner,
  fetchHeadshot,
};

export default userAPI;
