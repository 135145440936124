import Fuse from 'fuse.js';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { selectContentArray } from '../../state/content/content.slice';
import {
  CONTENT_TYPES,
  DEBOUNCE_TIMEOUT,
  EVENTS,
  fuseQueryAndTagsOptions,
  NO_SUGGESTIONS_FOUND,
  PATH_SEGMENTS,
  SEARCHING,
} from '../../utils/constants';
import curriedHandleUpAndDownArrowKeys from '../../utils/curriedHandleUpAndDownArrowKeys';
import useHandleFavoriteOrPinOrAppOrContentClick from '../../hooks/useHandleFavoriteOrPinOrAppOrContentClick';
import debounce from '../../utils/debounce';
import encode from '../../utils/encode';
import BodyLock from '../BodyLock/BodyLock';
import Icons from '../Icons/Icons';
import getContentTypeIcon from '../../utils/getContentTypeIcon';
import getJoinedFolderPath from '../../utils/getJoinedFolderPath';

interface Suggestion {
  _id: string;
  type: string;
  title: string;
  folderPath: string[];
}

interface SearchSuggestionsProps {
  inputValue: string;
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

const suggestionLinkClassNames = 'block p-2';

// get link props based on content type
const getLinkProps = (type: string, _id: string, title: string) => {
  if (type === CONTENT_TYPES.APP)
    return {
      'data-appid': _id,
      className: suggestionLinkClassNames,
      to: '#',
    };
  return {
    'data-contentid': _id,
    className: suggestionLinkClassNames,
    to: `${PATH_SEGMENTS.content}/${encode(`${_id}-${title}`)}`,
  };
};

/**
 * Search suggestions dropdown for Searchbar
 */
const SearchSuggestions = ({
  inputValue,
  inputRef,
}: SearchSuggestionsProps) => {
  const contentArray = useAppSelector(selectContentArray);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [altSuggestion, setAltSuggestion] = useState(SEARCHING);
  const ulRef = useRef<HTMLUListElement | null>(null);
  const handleFavoriteOrPinOrAppOrContentClick =
    useHandleFavoriteOrPinOrAppOrContentClick();

  useEffect(() => {
    const mapSearchResultsToSuggestions = () => {
      const fuseResults = new Fuse(contentArray, fuseQueryAndTagsOptions)
        .search(inputValue)
        .map(result => ({
          _id: result.item._id,
          title: result.item.title,
          folderPath: result.item.folderPath,
          type: result.item.type.name,
        }));

      if (fuseResults.length > 0) setSuggestions(fuseResults);
      else {
        setSuggestions([]);
        setAltSuggestion(NO_SUGGESTIONS_FOUND);
      }
    };

    const getDebouncedSuggestions = debounce(
      mapSearchResultsToSuggestions,
      DEBOUNCE_TIMEOUT
    );

    inputValue.length >= 2 && getDebouncedSuggestions();

    return () => getDebouncedSuggestions.cancel();
  }, [contentArray, inputValue]);

  useEffect(() => {
    const handleUpAndDownArrowKeys = curriedHandleUpAndDownArrowKeys(
      ulRef,
      inputRef,
      true,
      null,
      'a'
    );
    window.addEventListener(EVENTS.KEYUP, handleUpAndDownArrowKeys);
    return () =>
      window.removeEventListener(EVENTS.KEYUP, handleUpAndDownArrowKeys);
  }, [inputRef]);

  return (
    <div className='relative z-10'>
      <div onClick={e => handleFavoriteOrPinOrAppOrContentClick(e, inputValue)}>
        <ul
          ref={ulRef}
          className='absolute max-h-44 w-full overflow-y-auto rounded-2xl bg-brand-white py-2 shadow-lg md:max-h-64'
        >
          {suggestions.length ? (
            suggestions.map(({ _id, type, title, folderPath }, i) => {
              const joinedFolderPath = getJoinedFolderPath(folderPath);
              return (
                <li
                  key={i}
                  className='w-full focus-within:bg-brand-tint-grey-1 hover:bg-brand-tint-grey-1'
                >
                  <Link {...getLinkProps(type, _id, title)}>
                    <div className='relative' title={title}>
                      {getContentTypeIcon(
                        type,
                        'absolute top-1.5 h-3 w-3 stroke-brand-shade-grey'
                      )}
                      <p className='ml-5 line-clamp-1'>{title}</p>
                    </div>
                    <div className='relative' title={joinedFolderPath}>
                      <Icons.FolderIcon className='absolute top-px h-3 w-3 stroke-brand-shade-grey' />
                      <p className='ml-5 line-clamp-1 text-xs text-brand-shade-grey'>
                        {joinedFolderPath}
                      </p>
                    </div>
                  </Link>
                </li>
              );
            })
          ) : (
            <li className='p-2'>{altSuggestion}</li>
          )}
        </ul>
      </div>
      <BodyLock />
    </div>
  );
};

export default SearchSuggestions;
