import { WithObjectId } from '../../types';
import { INotification, NotificationsDoc } from '../../types/user.types';
import NotificationCard from '../NotificationCard/NotificationCard';

interface NotificationCardsProps extends NotificationsDoc {}
type WithIsRead<T> = T & { isRead: boolean };

/**
 * List of `NotificationCard` components
 */
const NotificationCards = ({
  unreadNotifications,
  readNotifications,
}: NotificationCardsProps) => {
  // assign `isRead` property to each notification, then sort descending by `createdAt`
  const allNotifications = (
    [unreadNotifications, readNotifications] as WithIsRead<
      WithObjectId<INotification>
    >[][]
  )
    .reduce((acc, cur, i) => {
      cur.forEach(notification =>
        acc.push({ ...notification, isRead: i === 1 })
      );
      return acc;
    }, [])
    .sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );

  // edge case where there are notifications with the same `createdAt`:
  // sort elements with same `createdAt` by `title` ascending
  for (let i = 0; i < allNotifications.length - 1; i++) {
    let j;
    let areSameValue = false;

    for (j = i + 1; j < allNotifications.length; j++) {
      if (allNotifications[i].createdAt === allNotifications[j].createdAt) {
        areSameValue = true;
      } else {
        break;
      }
    }

    if (areSameValue) {
      const sliced = allNotifications
        .slice(i, j)
        .sort((a, b) => a.title.localeCompare(b.title));
      allNotifications.splice(i, sliced.length, ...sliced);
      i += sliced.length - 1;
    }
  }

  return (
    <>
      {allNotifications.map(notification => (
        <NotificationCard
          key={notification._id}
          {...notification}
          isRead={notification.isRead}
        />
      ))}
    </>
  );
};

export default NotificationCards;
