import { AuthError } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

export const HUB_ERROR_INSTRUCTIONS = 'hub-error-instructions';
export const ADMIN_PORTAL_ERROR_INSTRUCTIONS =
  'admin-portal-error-instructions';

interface OopsProps {
  error?: AuthError | null; // `AuthError` is used when there is an error thrown during signin using MSAL.
  errorMessage?: string; // Passed in from global state or `LocalErrorBoundary`
}

const Oops = ({ error, errorMessage }: OopsProps) => {
  const { pathname } = useLocation();

  return (
    <div className='flex flex-col items-center p-4'>
      <h1 className='mb-2 text-center'>
        Oops!
        <br />
        We ran into an error.
      </h1>
      <p className='mb-4 inline-block rounded-md bg-brand-tint-red p-2 text-center font-mono'>
        {error?.errorMessage ?? errorMessage}
      </p>
      {pathname === PATHS.adminPortal ? (
        <AdminPortalErrorInstructions />
      ) : (
        <HubErrorInstructions />
      )}
    </div>
  );
};

const HubErrorInstructions = () => (
  <div data-testid={HUB_ERROR_INSTRUCTIONS}>
    <p className='mb-2'>Please follow these steps using Chrome:</p>
    <ol className='mb-4 list-decimal'>
      <li>Clear all browsing data.</li>
      <li>Log out of Microsoft.</li>
      <li>Close your browser.</li>
      <li>Log back in.</li>
    </ol>
    <p className='max-w-sm'>
      <span className='font-bold'>Note:</span> You must enable third-party
      cookies and disable extensions that block ads or tracking.
    </p>
  </div>
);

const AdminPortalErrorInstructions = () => (
  <p data-testid={ADMIN_PORTAL_ERROR_INSTRUCTIONS}>
    Please contact the developer team.
  </p>
);

export default Oops;
