import { hubAPI } from '../../http';
import { FAQsData } from '../../types/faqs.types';

const fetchFAQs = (signal: AbortSignal) =>
  hubAPI.get<FAQsData>('/api/faqs', { signal });

const faqsAPI = {
  fetchFAQs,
};

export default faqsAPI;
