import { SyntheticEvent, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectToasts, markToastAsRead } from '../../state/toast/toast.slice';
import Toast from '../Toast/Toast';
import { DATA_DISMISS_BTN } from '../../utils/constants';

const DATA_TOAST = '[data-toast]';
const containerCNs = {
  scrollable: ['pl-4', 'py-4', 'pr-2'],
  notScrollable: ['p-4'],
};
const scrollContainerScrollableCNs = ['thin-scrollbar', 'pr-2'];

/**
 * Toasts container
 */
const Toasts = () => {
  const toasts = useAppSelector(selectToasts);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  // scroll classNames
  useEffect(() => {
    if (!toasts.length || !containerRef.current) return;

    const container = containerRef.current;
    const scrollContainer = container.querySelector('ol');

    if (!scrollContainer) return;

    const isScrollable =
      scrollContainer.scrollHeight > scrollContainer.clientHeight;

    if (isScrollable) {
      container.classList.remove(...containerCNs.notScrollable);
      container.classList.add(...containerCNs.scrollable);
      scrollContainer.classList.add(...scrollContainerScrollableCNs);
    } else {
      container.classList.remove(...containerCNs.scrollable);
      container.classList.add(...containerCNs.notScrollable);
      scrollContainer.classList.remove(...scrollContainerScrollableCNs);
    }
  }, [toasts]);

  if (!toasts.length) return null;

  // dismiss one or all based on click target
  const handleMarkToast = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const dismissBtn = target.closest(DATA_DISMISS_BTN);
    const toast = target.closest(DATA_TOAST);

    // user clicked on container
    if (!dismissBtn && !toast) return;

    e.stopPropagation();

    // if dismiss button, clear one;
    // otherwise, `useEffect` on `NotificationsPage` will clear all
    if (dismissBtn) {
      dispatch(
        markToastAsRead(
          (dismissBtn as HTMLButtonElement).dataset.dismissbtn as string
        )
      );
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={handleMarkToast}
      className='pointer-events-none fixed inset-0 z-30 flex justify-end'
    >
      <div className='flex w-full flex-col items-end justify-end sm:w-3/5 md:w-1/2 lg:max-w-lg'>
        <ol className='pointer-events-auto grid w-full gap-4 overflow-y-scroll'>
          {toasts.map(toast => (
            <li key={toast._id}>
              <Toast {...toast} />
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Toasts;
