import axios from 'axios';
import { CANCELED_ERROR } from './constants';

/**
 * Determine if is Error or AxiosError (except for 'CanceledError')
 */
const isError = (error: unknown) => {
  const isAxiosError = axios.isAxiosError(error);
  return (
    (!isAxiosError && error instanceof Error) ||
    (isAxiosError && error.name !== CANCELED_ERROR)
  );
};

export default isError;
