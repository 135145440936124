import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ObjectIdString } from '../../types';
import { STATE_STATUSES } from '../../utils/constants';
import { AdminAnalyticsFavorite } from '../../types/adminAnalyticsFavorites.types';
import {
  AdminAnalyticsSearchBaseType,
  AdminAnalyticsSearchNGram,
  AdminAnalyticsSearchFilter,
  AdminAnalyticsSearchContentEngagement,
  AdminAnalyticsSearchParentFolder,
} from '../../types/adminAnalyticsSearch.types';
import adminAnalyticsAPI from './adminAnalytics.api';

export interface AdminAnalyticsState {
  status: {
    favorites: STATE_STATUSES;
    search: {
      keywords: STATE_STATUSES;
      nGrams: STATE_STATUSES;
      contentEngagement: STATE_STATUSES;
      parentFolders: STATE_STATUSES;
    };
  };
  data: {
    favorites: AdminAnalyticsFavorite[];
    favoritesMap: Record<ObjectIdString, AdminAnalyticsFavorite>;
    search: {
      keywords: AdminAnalyticsSearchBaseType[];
      nGrams: AdminAnalyticsSearchNGram[];
      contentEngagement: AdminAnalyticsSearchContentEngagement[];
      parentFolders: AdminAnalyticsSearchParentFolder[];
    };
  };
}

export const initialState: AdminAnalyticsState = {
  status: {
    favorites: STATE_STATUSES.INITIAL,
    search: {
      keywords: STATE_STATUSES.INITIAL,
      nGrams: STATE_STATUSES.INITIAL,
      contentEngagement: STATE_STATUSES.INITIAL,
      parentFolders: STATE_STATUSES.INITIAL,
    },
  },
  data: {
    favorites: [],
    favoritesMap: {},
    search: {
      keywords: [],
      nGrams: [],
      contentEngagement: [],
      parentFolders: [],
    },
  },
};

export const fetchAdminAnalyticsFavorites = createAsyncThunk(
  'adminAnalytics/fetchAdminAnalyticsFavorites',
  async (_, thunkAPI) => {
    const response = await adminAnalyticsAPI.fetchAdminAnalyticsFavorites(
      thunkAPI.signal
    );
    return response.data;
  }
);

export const fetchAdminAnalyticsSearchKeywords = createAsyncThunk(
  'adminAnalytics/fetchAdminAnalyticsSearchKeywords',
  async (filters: AdminAnalyticsSearchFilter, thunkAPI) => {
    const response = await adminAnalyticsAPI.fetchAdminAnalyticsSearchKeywords(
      filters,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const fetchAdminAnalyticsSearchNGrams = createAsyncThunk(
  'adminAnalytics/fetchAdminAnalyticsSearchNGrams',
  async (filters: AdminAnalyticsSearchFilter, thunkAPI) => {
    const response = await adminAnalyticsAPI.fetchAdminAnalyticsSearchNGrams(
      filters,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const fetchAdminAnalyticsSearchContentEngagement = createAsyncThunk(
  'adminAnalytics/fetchAdminAnalyticsSearchContentEngagement',
  async (filters: AdminAnalyticsSearchFilter, thunkAPI) => {
    const response =
      await adminAnalyticsAPI.fetchAdminAnalyticsSearchContentEngagement(
        filters,
        thunkAPI.signal
      );
    return response.data;
  }
);

export const fetchAdminAnalyticsSearchParentFolders = createAsyncThunk(
  'adminAnalytics/fetchAdminAnalyticsSearchParentFolders',
  async (filters: AdminAnalyticsSearchFilter, thunkAPI) => {
    const response =
      await adminAnalyticsAPI.fetchAdminAnalyticsSearchParentFolders(
        filters,
        thunkAPI.signal
      );
    return response.data;
  }
);

export const adminAnalyticsSlice = createSlice({
  name: 'adminAnalytics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAdminAnalyticsFavorites.pending, state => {
        state.status.favorites = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAdminAnalyticsFavorites.fulfilled, (state, action) => {
        const favoritesMap = getFavoritesMaps(action.payload.favorites);

        state.status.favorites = STATE_STATUSES.FULFILLED;
        state.data = {
          ...state.data,
          favorites: action.payload.favorites,
          favoritesMap,
        };
      })
      .addCase(fetchAdminAnalyticsFavorites.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.favorites = STATE_STATUSES.REJECTED;
        }
      })
      .addCase(fetchAdminAnalyticsSearchKeywords.pending, state => {
        state.status.search.keywords = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAdminAnalyticsSearchKeywords.fulfilled, (state, action) => {
        state.status.search.keywords = STATE_STATUSES.FULFILLED;
        state.data.search.keywords = action.payload.keywords;
      })
      .addCase(fetchAdminAnalyticsSearchKeywords.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.search.keywords = STATE_STATUSES.REJECTED;
        }
      })
      .addCase(fetchAdminAnalyticsSearchNGrams.pending, state => {
        state.status.search.nGrams = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAdminAnalyticsSearchNGrams.fulfilled, (state, action) => {
        state.status.search.nGrams = STATE_STATUSES.FULFILLED;
        state.data.search.nGrams = action.payload.nGrams;
      })
      .addCase(fetchAdminAnalyticsSearchNGrams.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status.search.nGrams = STATE_STATUSES.REJECTED;
        }
      })
      .addCase(fetchAdminAnalyticsSearchContentEngagement.pending, state => {
        state.status.search.contentEngagement = STATE_STATUSES.PENDING;
      })
      .addCase(
        fetchAdminAnalyticsSearchContentEngagement.fulfilled,
        (state, action) => {
          state.status.search.contentEngagement = STATE_STATUSES.FULFILLED;
          state.data.search.contentEngagement =
            action.payload.contentEngagement;
        }
      )
      .addCase(
        fetchAdminAnalyticsSearchContentEngagement.rejected,
        (state, action) => {
          if (!action.meta.aborted) {
            state.status.search.contentEngagement = STATE_STATUSES.REJECTED;
          }
        }
      )
      .addCase(fetchAdminAnalyticsSearchParentFolders.pending, state => {
        state.status.search.parentFolders = STATE_STATUSES.PENDING;
      })
      .addCase(
        fetchAdminAnalyticsSearchParentFolders.fulfilled,
        (state, action) => {
          state.status.search.parentFolders = STATE_STATUSES.FULFILLED;
          state.data.search.parentFolders = action.payload.parentFolders;
        }
      )
      .addCase(
        fetchAdminAnalyticsSearchParentFolders.rejected,
        (state, action) => {
          if (!action.meta.aborted) {
            state.status.search.parentFolders = STATE_STATUSES.REJECTED;
          }
        }
      );
  },
});

const getFavoritesMaps = (favorites: AdminAnalyticsFavorite[]) => {
  const favoritesMap: typeof initialState.data.favoritesMap = {};

  favorites.forEach(item => {
    favoritesMap[item._id] = item;
  });

  return favoritesMap;
};

export const adminAnalyticsReducer = adminAnalyticsSlice.reducer;

export const selectAdminFavorites = (state: RootState) =>
  state.adminAnalytics.data.favorites;

export const selectAdminFavoritesStatus = (state: RootState) =>
  state.adminAnalytics.status.favorites;

export const selectAdminFavoritesMap = (state: RootState) =>
  state.adminAnalytics.data.favoritesMap;

export const selectAdminAnalyticsSearchKeywords = (state: RootState) =>
  state.adminAnalytics.data.search.keywords;

export const selectAdminAnalyticsSearchKeywordsStatus = (state: RootState) =>
  state.adminAnalytics.status.search.keywords;

export const selectAdminAnalyticsSearchNGrams = (state: RootState) =>
  state.adminAnalytics.data.search.nGrams;

export const selectAdminAnalyticsSearchNGramsStatus = (state: RootState) =>
  state.adminAnalytics.status.search.nGrams;

export const selectAdminAnalyticsSearchContentEngagement = (state: RootState) =>
  state.adminAnalytics.data.search.contentEngagement;

export const selectAdminAnalyticsSearchContentEngagementStatus = (
  state: RootState
) => state.adminAnalytics.status.search.contentEngagement;

export const selectAdminAnalyticsSearchParentFolders = (state: RootState) =>
  state.adminAnalytics.data.search.parentFolders;

export const selectAdminAnalyticsSearchParentFoldersStatus = (
  state: RootState
) => state.adminAnalytics.status.search.parentFolders;
