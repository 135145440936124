import { useEffect, useRef } from 'react';
import { UPDATE_ALERT_MESSAGE } from '../utils/constants';
import { selectConfig } from '../state/config/config.slice';
import { useAppDispatch, useAppSelector } from './redux';

/**
 * Inform user they should refresh if they have not refreshed since the release date or force refresh
 */
const useInformUserOfUpdatesOrForceRefresh = () => {
  const dispatch = useAppDispatch();
  const { releaseDate, forceRefresh } = useAppSelector(selectConfig);
  const previousReleaseRef = useRef(releaseDate);

  useEffect(() => {
    const hasReleaseDateDiff = previousReleaseRef.current !== releaseDate;

    if (!hasReleaseDateDiff) return;

    if (forceRefresh) {
      window.location.reload();
    } else {
      if (window.confirm(UPDATE_ALERT_MESSAGE)) {
        window.location.reload();
      } else {
        previousReleaseRef.current = releaseDate;
      }
    }
  }, [dispatch, forceRefresh, releaseDate]);
};

export default useInformUserOfUpdatesOrForceRefresh;
