import { useMatch, useSearchParams } from 'react-router-dom';
import { PATHS, SEARCHPARAM_KEYS, REPLACE } from '../utils/constants';
import { SEARCH_SORT_OPTIONS } from '../types/sort.types';

const useContentURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const querySearchParam = searchParams.get(SEARCHPARAM_KEYS.QUERY);
  const tagSearchParams = searchParams.getAll(SEARCHPARAM_KEYS.TAG);
  const sortSearchParam = searchParams.get(SEARCHPARAM_KEYS.SORT);
  const isSearchResultsMatch = useMatch(PATHS.searchResults);

  const setTagSearchParams = (newTags: string[]) =>
    setSearchParams(prev => {
      const updated = new URLSearchParams(prev.toString());
      updated.delete(SEARCHPARAM_KEYS.TAG);
      newTags.forEach(tag => updated.append(SEARCHPARAM_KEYS.TAG, tag));
      return updated;
    }, REPLACE);

  const setSortSearchParam = (newSort: string) =>
    setSearchParams(prev => {
      const updated = new URLSearchParams(prev.toString());
      updated.set(SEARCHPARAM_KEYS.SORT, newSort);
      return updated;
    }, REPLACE);

  const setTagAndSortParams = (newTags: string[], newSort: string) =>
    setSearchParams(prev => {
      const updated = new URLSearchParams(prev.toString());
      updated.delete(SEARCHPARAM_KEYS.TAG);
      newTags.forEach(tag => updated.append(SEARCHPARAM_KEYS.TAG, tag));
      updated.set(SEARCHPARAM_KEYS.SORT, newSort);
      return updated;
    }, REPLACE);

  const clearTagAndSortParams = () =>
    setSearchParams(prev => {
      const updated = new URLSearchParams(prev.toString());
      updated.delete(SEARCHPARAM_KEYS.TAG);
      if (isSearchResultsMatch) {
        updated.set(SEARCHPARAM_KEYS.SORT, SEARCH_SORT_OPTIONS.RELEVANCE);
      } else {
        updated.set(SEARCHPARAM_KEYS.SORT, SEARCH_SORT_OPTIONS.AZ);
      }

      return updated;
    }, REPLACE);

  return {
    searchParams: {
      querySearchParam,
      sortSearchParam,
      tagSearchParams,
      searchParams,
    },
    setters: {
      setSortSearchParam,
      setTagSearchParams,
      setTagAndSortParams,
      clearTagAndSortParams,
      setSearchParams,
    },
  };
};

export default useContentURLParams;
