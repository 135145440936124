import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import dsaTeamsAPI from './dsaTeams.api';

export interface POC {
  name: string;
  title: string;
  email: string;
  html: string;
}

export interface DSATeam {
  name: string;
  poc: POC;
}

export interface DSATeamsState {
  status: STATE_STATUSES;
  data: DSATeam[];
}

export const initialState: DSATeamsState = {
  status: STATE_STATUSES.INITIAL,
  data: [],
};

export const fetchDSATeams = createAsyncThunk(
  'dsaTeams/fetchDSATeams',
  async (_, thunkAPI) => {
    const response = await dsaTeamsAPI.fetchDSATeams(thunkAPI.signal);
    return response.data;
  }
);

export const dsaTeamsSlice = createSlice({
  name: 'dsaTeams',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDSATeams.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchDSATeams.fulfilled, (state, action) => {
        return {
          status: STATE_STATUSES.FULFILLED,
          data: action.payload.dsaTeams,
        };
      })
      .addCase(fetchDSATeams.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = STATE_STATUSES.REJECTED;
        }
      });
  },
});
export const dsaTeamsReducer = dsaTeamsSlice.reducer;

export const selectDSATeams = (state: RootState) => state.dsaTeams.data;
export const selectIsDSATeamsFetching = (state: RootState) =>
  [STATE_STATUSES.INITIAL, STATE_STATUSES.PENDING].includes(
    state.dsaTeams.status
  );
export const selectIsDSATeamsFulfilled = (state: RootState) =>
  state.dsaTeams.status === STATE_STATUSES.FULFILLED;
export const selectIsDSATeamsRejected = (state: RootState) =>
  state.dsaTeams.status === STATE_STATUSES.REJECTED;
