import { useEffect } from 'react';
import { closeWidgets } from '../state/widgets/widgets.slice';
import { EVENTS, KEYS } from '../utils/constants';
import { useAppDispatch } from './redux';

const useGlobalEscape = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const closeWidgetsCB = (e: KeyboardEvent) => {
      if (e.key === KEYS.ESCAPE) {
        dispatch(closeWidgets());
      }
    };

    window.addEventListener(EVENTS.KEYDOWN, closeWidgetsCB);

    return () => {
      window.removeEventListener(EVENTS.KEYDOWN, closeWidgetsCB);
    };
  }, [dispatch]);
};

export default useGlobalEscape;
