import { EMPTY_STATE_MESSAGES } from '../../utils/constants';

/* Message for empty Card */
const EmptyCardStateMessage = ({
  message,
}: {
  message: EMPTY_STATE_MESSAGES;
}) => {
  return (
    <p className='flex h-80 flex-col justify-center text-center'>{message}</p>
  );
};

export default EmptyCardStateMessage;
