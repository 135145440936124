import { useEffect, useState } from 'react';
import { EVENTS } from '../utils/constants';

/**
 * If scrollHeight (height of an element's scrollable content)
 * minus scrollTop (distance from the element's top to its topmost visible content)
 * is <= clientHeight (element's height + padding),
 * that means that the user is scrolled to the bottom of the scroll container.
 * Also considers elastic scroll containers for mobile devices.
 */
export const getEventListenerCallback =
  (
    setIsScrolledToBottom: React.Dispatch<React.SetStateAction<boolean>>,
    scrollContainer: Element
  ) =>
  () =>
    setIsScrolledToBottom(
      scrollContainer.scrollHeight - scrollContainer.scrollTop <=
        scrollContainer.clientHeight
    );

/**
 * Determine if user is scrolled to bottom of scroll container in HomePage cards
 */
const useIsScrolledToBottom = (elementId: string) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const scrollContainer = document.getElementById(elementId) as Element;
    if (!scrollContainer) return;

    const callback = getEventListenerCallback(
      setIsScrolledToBottom,
      scrollContainer
    );

    callback();

    scrollContainer.addEventListener(EVENTS.SCROLL, callback);

    return () => scrollContainer.removeEventListener(EVENTS.SCROLL, callback);
  }, [elementId]);

  return isScrolledToBottom;
};

export default useIsScrolledToBottom;
