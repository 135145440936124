export const LOOKER_HIDDEN_IFRAME = 'looker-hidden-iframe';

/**
 * LookerHiddenIframeListener component
 * @note attempts to establish a Looker session for the user
 */
const LookerHiddenIframeListener = () => (
  <iframe
    src={`${process.env.REACT_APP_LOOKER_URL}/embed/dashboards-next/${process.env.REACT_APP_LOOKER_DASH_ID}?allow_login_screen=true`}
    title={LOOKER_HIDDEN_IFRAME}
    className='hidden'
  />
);

export default LookerHiddenIframeListener;
