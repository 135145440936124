import { useEffect } from 'react';

export const BODY_LOCK = 'body-lock';
const BODY_LOCK_CACHE = { current: 0 };

/**
 * Lock body to `overflow-y-hidden`
 *
 * @see BodyLock.tsx
 */
const useBodyLock = () => {
  useEffect(() => {
    BODY_LOCK_CACHE.current++;
    document.body.classList.add(BODY_LOCK);

    return () => {
      // decrement
      BODY_LOCK_CACHE.current--;

      // if zero, unlock body
      if (BODY_LOCK_CACHE.current <= 0) {
        document.body.classList.remove(BODY_LOCK);
      }
    };
  }, []);
};

export default useBodyLock;
