import { SyntheticEvent } from 'react';
import api from '../http';
import { selectContentMap } from '../state/content/content.slice';
import useToggleFavoriteOrPin from './useToggleFavoriteOrPin';
import { useAppSelector } from './redux';

const useHandleFavoriteOrPinOrAppOrContentClick = () => {
  const handleFavoriteOrPin = useToggleFavoriteOrPin();
  const contentMap = useAppSelector(selectContentMap);

  // define handler
  const handleFavoriteOrPinOrAppOrContentClick = (
    e: SyntheticEvent,
    searchQuery?: string | null
  ) => {
    const target = e.target as HTMLElement;
    const contentId =
      target.closest<HTMLAnchorElement>('[data-contentid]')?.dataset.contentid;
    const favOrPinButton = target.closest<HTMLButtonElement>('[data-id]');
    const appId = target.closest<HTMLDivElement>('[data-appid]')?.dataset.appid;

    if (!favOrPinButton && !appId && !contentId) return;

    // trigger handleFavoriteOrPin only if favorite or pin button is clicked
    if (favOrPinButton) return handleFavoriteOrPin(e);

    // open the app link in new tab only if app is clicked
    appId && window.open(contentMap[appId].idFromSource, '_blank');

    const searchItemId = contentId ?? appId;
    searchItemId && searchQuery && api.addSearchData(searchQuery, searchItemId);
  };

  return handleFavoriteOrPinOrAppOrContentClick;
};

export default useHandleFavoriteOrPinOrAppOrContentClick;
