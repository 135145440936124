import { KEYS } from './constants';

const tabTrapCurried = (containerQuerySelector: string) => {
  const tabTrap = (e: KeyboardEvent) => {
    const container = document.querySelector(containerQuerySelector);

    const focusableElementsString =
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
    const focusableElements = container?.querySelectorAll(
      focusableElementsString
    ) as NodeListOf<HTMLElement>;

    const focusableElementsArray = Array.prototype.slice.call<
      NodeListOf<HTMLElement>,
      never[],
      HTMLElement[]
    >(focusableElements);

    const firstTabStop = focusableElementsArray[0];
    const lastTabStop =
      focusableElementsArray[focusableElementsArray.length - 1];

    // Check for TAB key press
    if (e.key === KEYS.TAB) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstTabStop) {
          e.preventDefault();
          lastTabStop.focus();
        }

        // TAB
      } else {
        if (document.activeElement === lastTabStop) {
          e.preventDefault();
          firstTabStop.focus();
        }
      }
    }
  };

  return tabTrap;
};

export default tabTrapCurried;
