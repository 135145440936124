import { RefObject, useEffect, useMemo, useState } from 'react';

/**
 * Determines if a ref has intersected with the viewport
 */
const useHasIntersected = (refObj: RefObject<HTMLDivElement | null>) => {
  const [hasIntersected, setHasIntersected] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setHasIntersected(true);
      },
      { rootMargin: '25%' }
    );
  }, []);

  useEffect(() => {
    if (!refObj.current) return;

    const ref = refObj.current;

    // observe ref
    observer.observe(ref);

    // cleanup
    return () => {
      observer.unobserve(ref);
    };
  }, [observer, refObj]);

  return hasIntersected;
};

export default useHasIntersected;
