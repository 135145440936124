import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectUser,
  selectReadNotificationsInFlight,
  selectUnreadNotificationsInFlight,
  addReadNotifications,
  removeReadNotifications,
} from '../../state/user/user.slice';
import { MARK_ALL_AS_READ, MARK_ALL_AS_UNREAD } from '../../utils/constants';

/**
 * Mark all as read or unread button component
 */
const MarkAllAsReadOrUnreadButton = () => {
  const dispatch = useAppDispatch();
  const { unreadNotifications, readNotifications } = useAppSelector(selectUser);
  const readNotificationsInFlight = useAppSelector(
    selectReadNotificationsInFlight
  );
  const unreadNotificationsInFlight = useAppSelector(
    selectUnreadNotificationsInFlight
  );

  const hasUnreadNotifications = !!unreadNotifications.length;

  const isReadOrUnreadNotificationInFlight = !!(
    Object.keys(readNotificationsInFlight).length ||
    Object.keys(unreadNotificationsInFlight).length
  );

  // update all notifications as read
  const markAllAsRead = () => {
    dispatch(addReadNotifications(unreadNotifications.map(({ _id }) => _id)));
  };

  // update all notifications as unread
  const markAllAsUnread = () => {
    dispatch(removeReadNotifications(readNotifications.map(({ _id }) => _id)));
  };

  return (
    <button
      className={cn(
        'py-1 text-xs text-brand-shade-grey underline lg:px-3',
        isReadOrUnreadNotificationInFlight && 'cursor-progress'
      )}
      onClick={hasUnreadNotifications ? markAllAsRead : markAllAsUnread}
      disabled={isReadOrUnreadNotificationInFlight}
    >
      {hasUnreadNotifications ? MARK_ALL_AS_READ : MARK_ALL_AS_UNREAD}
    </button>
  );
};

export default MarkAllAsReadOrUnreadButton;
