import { ReactComponent as Home } from '../../images/green-home-icon.svg';
import { ReactComponent as Favorites } from '../../images/green-favorites-icon.svg';
import { ReactComponent as DSAInsights } from '../../images/green-dsa-insights-icon.svg';
import { ReactComponent as DSATeams } from '../../images/green-org-explorer-icon.svg';
import { ReactComponent as Notifications } from '../../images/green-notifications-icon.svg';
import { ReactComponent as Browse } from '../../images/green-browse-icon.svg';
import { ReactComponent as FAQ } from '../../images/green-faq-icon.svg';
import { ReactComponent as Tutorial } from '../../images/green-tutorial-icon.svg';
import { ReactComponent as AdminPortal } from '../../images/green-admin-portal-icon.svg';
import { ReactComponent as Settings } from '../../images/green-settings-icon.svg';
import { ReactComponent as Logout } from '../../images/green-logout-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../images/notifications-icon.svg';
import { ReactComponent as NotificationsIconAlert } from '../../images/notifications-icon-alert.svg';
import { ReactComponent as HRBlockIcon } from '../../images/hr-block-icon.svg';
import { ReactComponent as NoActivity } from '../../images/notifications-no-activity.svg';
import { ReactComponent as Star } from '../../images/star.svg';
import { ReactComponent as FolderIcon } from '../../images/folder.svg';
import { ReactComponent as ChevronRight } from '../../images/chevron-right-icon.svg';
import { ReactComponent as ChevronLeft } from '../../images/chevron-left-icon.svg';
import { ReactComponent as ChevronDown } from '../../images/chevron-down-icon.svg';
import { ReactComponent as ChevronUp } from '../../images/chevron-up-icon.svg';
import { ReactComponent as App } from '../../images/app-text.svg';
import { ReactComponent as Dashboard } from '../../images/dashboard-text.svg';
import { ReactComponent as Video } from '../../images/video-text.svg';
import { ReactComponent as SearchIcon } from '../../images/search-icon.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right-icon.svg';
import { ReactComponent as MailIcon } from '../../images/mail-icon.svg';
import { ReactComponent as Chat } from '../../images/chat.svg';
import { ReactComponent as HamburgerMenu } from '../../images/hamburger-menu.svg';
import { ReactComponent as Close } from '../../images/close.svg';
import { ReactComponent as Trash } from '../../images/trash-icon.svg';
import { ReactComponent as Edit } from '../../images/edit-icon.svg';
import { ReactComponent as MessageIcon } from '../../images/message-icon.svg';
import { ReactComponent as AddDoc } from '../../images/add-document-icon.svg';
import { ReactComponent as PinEmpty } from '../../images/pin-empty.svg';
import { ReactComponent as PinFilled } from '../../images/pin-filled.svg';
import { ReactComponent as CheckboxTick } from '../../images/checkbox-tick.svg';
import { ReactComponent as CheckboxTickCircle } from '../../images/checkbox-tick-circle.svg';
import { ReactComponent as IssueOrInfo } from '../../images/issue-or-info.svg';
import { ReactComponent as RefreshIcon } from '../../images/icon-refresh.svg';
import { ReactComponent as ThumbsUp } from '../../images/thumbs-up-icon.svg';
import { ReactComponent as ThumbsDown } from '../../images/thumbs-down-icon.svg';

const Icons = {
  Home,
  Favorites,
  DSAInsights,
  DSATeams,
  Notifications,
  Browse,
  FAQ,
  Tutorial,
  AdminPortal,
  Settings,
  Logout,
  NotificationsIcon,
  NotificationsIconAlert,
  HRBlockIcon,
  NoActivity,
  Star,
  FolderIcon,
  App,
  Dashboard,
  Video,
  SearchIcon,
  ArrowRightIcon,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ChevronLeft,
  MailIcon,
  Chat,
  HamburgerMenu,
  Close,
  Trash,
  Edit,
  MessageIcon,
  AddDoc,
  PinEmpty,
  PinFilled,
  CheckboxTick,
  CheckboxTickCircle,
  IssueOrInfo,
  RefreshIcon,
  ThumbsUp,
  ThumbsDown,
};

export default Icons;
