import { hubAPI } from '../../http';
import { Home } from './home.slice';

const fetchHome = (signal: AbortSignal) =>
  hubAPI.get<Home>('/api/home/init', { signal });

const homeAPI = {
  fetchHome,
};

export default homeAPI;
