import { useLocation, useSearchParams } from 'react-router-dom';
import { MEDIA_QUERIES } from '../../utils/constants';
import { useAppSelector } from '../../hooks/redux';
import useCurrentFolder from '../../hooks/useCurrentFolder';
import useMediaQuery from '../../hooks/useMediaQuery';
import { selectContentArray } from '../../state/content/content.slice';
import getNestedContent from '../../utils/getNestedContent';
import DesktopBrowseContainer from '../../components/DesktopBrowseContainer/DesktopBrowseContainer';
import MobileBrowseContainer from '../../components/MobileBrowseContainer/MobileBrowseContainer';
import { BrowseContainerProps } from './types';

/**
 * Browse page for '/browse' route and nested paths
 */
const BrowsePage = () => {
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { breadcrumbs, folders, content } = useCurrentFolder();
  const contentArray = useAppSelector(selectContentArray);

  if (!breadcrumbs) return null;

  const props: BrowseContainerProps = {
    pathname,
    searchParams,
    breadcrumbs,
    folders,
    content,
    nestedContent: getNestedContent(pathname, contentArray),
  };

  return (
    <>
      {isDesktop ? (
        <DesktopBrowseContainer {...props} />
      ) : (
        <MobileBrowseContainer {...props} />
      )}
    </>
  );
};

export default BrowsePage;
