import {
  SORT_BY_LABELS,
  SORT_OPTIONS,
  SortBy,
  SortByMeta,
} from '../types/sort.types';

/**
 * Sorting for Browse, Search Results and Favorites routes
 */
const SORTBY: Record<SortBy, SortByMeta> = {
  az: {
    label: SORT_BY_LABELS.AZ,
    value: SORT_OPTIONS.AZ,
    sort: (a, b) => a.title.localeCompare(b.title),
  },
  za: {
    label: SORT_BY_LABELS.ZA,
    value: SORT_OPTIONS.ZA,
    sort: (a, b) => b.title.localeCompare(a.title),
  },
  newest: {
    label: SORT_BY_LABELS.NEWEST,
    value: SORT_OPTIONS.NEWEST,
    sort: (a, b) =>
      new Date(b.dateAdded).valueOf() - new Date(a.dateAdded).valueOf(),
  },
  oldest: {
    label: SORT_BY_LABELS.OLDEST,
    value: SORT_OPTIONS.OLDEST,
    sort: (a, b) =>
      new Date(a.dateAdded).valueOf() - new Date(b.dateAdded).valueOf(),
  },
};

export default SORTBY;
