import { useParams, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import { selectContentMap } from '../state/content/content.slice';
import { PATHS } from '../utils/constants';
import LookerContentItemAuthWrapper from '../components/LookerContentItemAuthWrapper/LookerContentItemAuthWrapper';
import MSStreamContentItem from '../components/MSStreamContentItem/MSStreamContentItem';

/**
 * Content page
 */
const ContentPage = () => {
  const { slug } = useParams();
  const contentMap = useAppSelector(selectContentMap);

  // if no slug, navigate to browse route
  if (!slug) return <Navigate to={PATHS.browse} />;

  const slugArray = slug.split('-');
  const contentId = slugArray[0];
  const content = contentMap[contentId];

  // if no longer exists, navigate to browse route
  if (!content) return <Navigate to={PATHS.browse} />;

  if (content.source.name === 'microsoftstream')
    return (
      <MSStreamContentItem
        {...{ contentId, idFromSource: content.idFromSource }}
      />
    );

  return <LookerContentItemAuthWrapper {...{ content }} />;
};

export default ContentPage;
