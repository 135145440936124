import { useMatch } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import {
  selectFavoritesInFlight,
  selectPinnedFavoritesInFlight,
  selectUserDataMaps,
} from '../../state/user/user.slice';
import encode from '../../utils/encode';
import { PATHS } from '../../utils/constants';
import getJoinedFolderPath from '../../utils/getJoinedFolderPath';
import ContentCardWithoutImage from '../ContentCardWithoutImage/ContentCardWithoutImage';
import { ContentCardsProps } from '../ContentCardsContainer/ContentCardsContainer';

/**
 * Container for `ContentCardWithoutImage` components
 */
const ContentCardsWithoutImage = ({ content }: ContentCardsProps) => {
  const { allFavoritesMap } = useAppSelector(selectUserDataMaps);
  const favoritesInFlight = useAppSelector(selectFavoritesInFlight);
  const pinnedFavoritesInFlight = useAppSelector(selectPinnedFavoritesInFlight);
  const isBrowseMatch = useMatch(PATHS.browse);

  return (
    <>
      {content.map(item => (
        <ContentCardWithoutImage
          key={item._id}
          title={item.title}
          type={item.type.name}
          to={`/content/${item._id}-${encode(item.title)}`}
          isFavorite={!!allFavoritesMap[item._id]}
          isFavoriteButtonDisabled={
            !!favoritesInFlight[item._id] || !!pinnedFavoritesInFlight[item._id]
          }
          _id={item._id}
          {...(!isBrowseMatch && {
            folderPath: getJoinedFolderPath(item.folderPath),
          })}
        />
      ))}
    </>
  );
};

export default ContentCardsWithoutImage;
