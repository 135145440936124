import { useMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useContentURLParams from '../../hooks/useContentURLParams';
import {
  selectIsFilterByTagsTakeoverOpen,
  toggleIsFilterByTagsTakeoverOpen,
} from '../../state/widgets/widgets.slice';
import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import { PATHS, SORT_AND_FILTER_TITLES } from '../../utils/constants';
import ClearFiltersButton from '../ClearFiltersButton/ClearFiltersButton';
import Dropdown from '../Dropdown/Dropdown';
import FilterByTagsButton from '../FilterByTagsButton/FilterByTagsButton';
import FilterByTagsTakeover from '../FilterByTagsTakeover/FilterByTagsTakeover';
import {
  SEARCH_SORT_OPTIONS,
  SEARCH_SORT_BY_OPTIONS,
  BROWSE_AND_FAVORITES_SORT_BY_OPTIONS,
} from '../../types/sort.types';

interface DesktopSortAndFilterContainerProps {
  content: WithObjectId<Content>[];
  isDropdownDisabled: boolean;
}

/**
 * Container for applying filters and sorting in Search Results, Browse and Favorites pages
 */
const DesktopSortAndFilterContainer = ({
  content,
  isDropdownDisabled,
}: DesktopSortAndFilterContainerProps) => {
  const dispatch = useAppDispatch();
  const isFilterByTagsTakeoverOpen = useAppSelector(
    selectIsFilterByTagsTakeoverOpen
  );
  const {
    setters: { setSortSearchParam, clearTagAndSortParams },
    searchParams: { sortSearchParam },
  } = useContentURLParams();
  const isSearchResultsMatch = useMatch(PATHS.searchResults);

  return (
    <div className='flex justify-self-end lg:h-20'>
      <Dropdown
        handleOptionChange={option => setSortSearchParam(option)}
        title={SORT_AND_FILTER_TITLES.SORT_BY}
        value={
          (sortSearchParam as SEARCH_SORT_OPTIONS) ||
          (isSearchResultsMatch
            ? SEARCH_SORT_OPTIONS.RELEVANCE
            : SEARCH_SORT_OPTIONS.AZ)
        }
        options={
          isSearchResultsMatch
            ? SEARCH_SORT_BY_OPTIONS
            : BROWSE_AND_FAVORITES_SORT_BY_OPTIONS
        }
        isDisabled={isDropdownDisabled}
      />
      <FilterByTagsButton
        handleOpenFilterByTagsTakeover={() =>
          dispatch(toggleIsFilterByTagsTakeoverOpen(true))
        }
      />
      <ClearFiltersButton
        handleClearClick={() => {
          clearTagAndSortParams();
        }}
        title={SORT_AND_FILTER_TITLES.CLEAR_FILTERS}
        isForSortAndFilterContainer={true}
      />
      {isFilterByTagsTakeoverOpen && <FilterByTagsTakeover content={content} />}
    </div>
  );
};

export default DesktopSortAndFilterContainer;
