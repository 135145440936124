import { useState } from 'react';
import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import MobileFilterButton from '../MobileFilterButton/MobileFilterButton';
import ItemsCount from '../ItemsCount/ItemsCount';
import MobileSortAndFilterTakeover from '../MobileSortAndFilterTakeover/MobileSortAndFilterTakeover';
import SearchResultsMessage from '../SearchResultsMessage/SearchResultsMessage';
import ContentCardsContainer from '../ContentCardsContainer/ContentCardsContainer';

/**
 * Search results for mobile
 */
const MobileSearchResultsContainer = ({
  searchResults,
}: {
  searchResults: WithObjectId<Content>[];
}) => {
  const [
    isMobileSortAndFilterTakeoverOpen,
    setIsMobileSortAndFilterTakeoverOpen,
  ] = useState(false);

  return (
    <>
      <SearchResultsMessage />
      <div className='mb-4 grid grid-cols-2 items-end'>
        <ItemsCount count={searchResults.length} />
        <MobileFilterButton
          handleOpenScreenTakeover={() =>
            setIsMobileSortAndFilterTakeoverOpen(true)
          }
        />
      </div>

      {/* content */}
      <ContentCardsContainer content={searchResults} />

      {isMobileSortAndFilterTakeoverOpen && (
        <MobileSortAndFilterTakeover
          handleCloseScreenTakeover={() =>
            setIsMobileSortAndFilterTakeoverOpen(false)
          }
          content={searchResults}
          isDropdownDisabled={false}
        />
      )}
    </>
  );
};

export default MobileSearchResultsContainer;
