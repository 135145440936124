import { useMatch } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import {
  selectFavoritesInFlight,
  selectPinnedFavoritesInFlight,
  selectUserDataMaps,
} from '../../state/user/user.slice';
import encode from '../../utils/encode';
import dashboard from '../../images/dashboard.png';
import video from '../../images/video.png';
import app from '../../images/app.png';
import ContentCard from '../ContentCard/ContentCard';
import { CONTENT_TYPES, PATHS } from '../../utils/constants';
import getJoinedFolderPath from '../../utils/getJoinedFolderPath';
import { ContentCardsProps } from '../ContentCardsContainer/ContentCardsContainer';

/**
 * Helper
 */
const getProps = (name: string) => {
  // assume dashboard
  const props = {
    isDashboard: true,
    icon: dashboard,
  };

  if (name === CONTENT_TYPES.DASHBOARD) {
    return props;
  }

  props.isDashboard = false;
  props.icon = name === CONTENT_TYPES.VIDEO ? video : app;
  return props;
};

/**
 * Container for `ContentCard` components
 */
const ContentCards = ({ content }: ContentCardsProps) => {
  const { allFavoritesMap } = useAppSelector(selectUserDataMaps);
  const favoritesInFlight = useAppSelector(selectFavoritesInFlight);
  const pinnedFavoritesInFlight = useAppSelector(selectPinnedFavoritesInFlight);
  const isBrowseMatch = useMatch(PATHS.browse);

  return (
    <>
      {content.map(item => {
        const { isDashboard, icon } = getProps(item.type.name);

        return (
          <ContentCard
            key={item._id}
            title={item.title}
            type={item.type.name}
            to={`/content/${item._id}-${encode(item.title)}`}
            isFavorite={!!allFavoritesMap[item._id]}
            isFavoriteButtonDisabled={
              !!favoritesInFlight[item._id] ||
              !!pinnedFavoritesInFlight[item._id]
            }
            _id={item._id}
            isDashboard={isDashboard}
            icon={icon}
            idFromSource={item.idFromSource}
            {...(!isBrowseMatch && {
              folderPath: getJoinedFolderPath(item.folderPath),
            })}
          />
        );
      })}
    </>
  );
};

export default ContentCards;
