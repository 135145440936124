import { useEffect, useState } from 'react';

/**
 * Match a media query
 *
 * @see MEDIA_QUERIES (in constants.ts)
 */
const useMediaQuery = (mediaQuery: string) => {
  const [isMatch, setIsMatch] = useState(window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const handleChange = () => setIsMatch(mql.matches);
    const mql = window.matchMedia(mediaQuery);

    setIsMatch(mql.matches);
    mql.addEventListener('change', handleChange);

    return () => mql.removeEventListener('change', handleChange);
  }, [mediaQuery]);

  return isMatch;
};
export default useMediaQuery;
