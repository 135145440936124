import cn from 'classnames';
import { SPINNER_CLASSES, SPINNER_SIZES } from '../../utils/constants';
import { useAppSelector } from '../../hooks/redux';
import { selectConfig } from '../../state/config/config.slice';

interface SpinnerProps {
  size: SPINNER_SIZES;
  fullScreen?: boolean;
  maskBgColor?: string;
  centerBgColor?: string;
}

/**
 * App spinner
 */
const Spinner = ({
  size,
  fullScreen,
  maskBgColor,
  centerBgColor,
}: SpinnerProps) => {
  const spinner = (
    <div
      role='presentation'
      className={cn(
        'relative animate-spin rounded-full border-4 border-x-brand-tint-grey-2 border-b-brand-tint-grey-2 border-t-brand-green',
        {
          [SPINNER_CLASSES.LARGE]: size === SPINNER_SIZES.LARGE,
          [SPINNER_CLASSES.MEDIUM]: size === SPINNER_SIZES.MEDIUM,
          [SPINNER_CLASSES.SMALL]: size === SPINNER_SIZES.SMALL,
        },
        centerBgColor
      )}
    />
  );

  return fullScreen ? (
    <div
      data-testid='spinner-masking-div'
      className={cn(
        'fixed inset-0 z-50 flex items-center justify-center',
        maskBgColor ?? 'bg-brand-white'
      )}
    >
      {spinner}
    </div>
  ) : (
    spinner
  );
};

export default Spinner;

/**
 * Spinner for below header
 */
const SpinnerBelowHeader = () => {
  // side effect to rerender and apply class names from `Layout` (if still spinning)
  useAppSelector(selectConfig);

  return (
    <div
      className={`flex items-center justify-center ${SpinnerBelowHeader.classNames}`}
    >
      <Spinner size={SPINNER_SIZES.MEDIUM} />
    </div>
  );
};

SpinnerBelowHeader.classNames = '';

/**
 * Large full screen spinner (used often)
 */
const SpinnerLargeFullScreen = () => (
  <Spinner fullScreen size={SPINNER_SIZES.LARGE} />
);

Spinner.BelowHeader = SpinnerBelowHeader;
Spinner.LargeFullScreen = SpinnerLargeFullScreen;
