import { useMatch } from 'react-router-dom';
import SORTBY from '../utils/sortBy';
import { selectTagsMap } from '../state/content/content.slice';
import { PATHS } from '../utils/constants';
import { SEARCH_SORT_OPTIONS, SORT_OPTIONS } from '../types/sort.types';
import { useAppSelector } from './redux';
import useContentURLParams from './useContentURLParams';

/**
 * Returns tuple indicating if URL params are valid
 *
 * @returns {boolean[]} `[isQuerySearchParamValid, isSortSearchParamValid, isTagSearchParamsValid]`
 * @note on Search results page, sort can be `relevance`, `az`, `za`, `newest`, or `oldest`
 * @note on Browse and Favorites pages, sort cannot be `relevance`; it can only be `az`, `za`, `newest`, or `oldest`
 */
const useValidateContentURLParams = () => {
  const tagsMap = useAppSelector(selectTagsMap);
  const {
    searchParams: { querySearchParam, sortSearchParam, tagSearchParams },
  } = useContentURLParams();
  const isSearchResultsMatch = useMatch(PATHS.searchResults);

  let isSortSearchParamValid: boolean;

  if (isSearchResultsMatch)
    //on Search results page, sort can be `relevance`
    isSortSearchParamValid =
      sortSearchParam === SEARCH_SORT_OPTIONS.RELEVANCE ||
      !!SORTBY[sortSearchParam as SORT_OPTIONS];
  //else sort cannot be `relevance`
  else isSortSearchParamValid = !!SORTBY[sortSearchParam as SORT_OPTIONS];

  return [
    !!querySearchParam,
    isSortSearchParamValid,
    tagSearchParams.every(tag => tagsMap[tag]),
  ];
};

export default useValidateContentURLParams;
