import cn from 'classnames';
import { useEffect, useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import useContentURLParams from '../../hooks/useContentURLParams';
import {
  FILTER_BY_TAGS_BUTTON,
  MEDIA_QUERIES,
  SORT_AND_FILTER_TITLES,
} from '../../utils/constants';

interface FilterByTagsButtonProps {
  handleOpenFilterByTagsTakeover: () => void;
  isFilterButtonActive?: boolean;
}
/**
 * Filter by tags button for `DesktopSortAndFilterContainer` and `MobileSortAndFilterTakeover`
 */
const FilterByTagsButton = ({
  handleOpenFilterByTagsTakeover,
  isFilterButtonActive,
}: FilterByTagsButtonProps) => {
  const {
    searchParams: { tagSearchParams },
  } = useContentURLParams();
  const [isActive, setIsActive] = useState(false);
  const isDesktop = useMediaQuery(MEDIA_QUERIES.DESKTOP);

  useEffect(() => {
    if (isDesktop) {
      //if desktop, button is active if there are tag search params
      setIsActive(!!tagSearchParams.length);
    } else {
      //if mobile, button is active if there are staged selected tags in `MobileSortAndFilterTakeover`
      //or if there are tag search params
      setIsActive(!!(isFilterButtonActive || tagSearchParams.length));
    }
  }, [isDesktop, isFilterButtonActive, tagSearchParams.length]);

  return (
    <button
      id={FILTER_BY_TAGS_BUTTON}
      title={SORT_AND_FILTER_TITLES.FILTER_BY_TAGS}
      onClick={handleOpenFilterByTagsTakeover}
      className={cn(
        'flex h-20 flex-col justify-center rounded-3xl px-6 py-3 text-start lg:h-auto lg:w-auto lg:shadow',
        isActive ? 'bg-brand-green' : 'bg-brand-tint-grey-1 lg:bg-brand-white',
        isDesktop ? 'mx-4 grow' : 'w-full'
      )}
    >
      <p className={cn(isActive && 'text-brand-white')}>
        {SORT_AND_FILTER_TITLES.FILTER_BY_TAGS}
      </p>
    </button>
  );
};

export default FilterByTagsButton;
