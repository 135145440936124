import alphaSortByProperty from '../../../utils/alphaSortByProperty';
import { PATH_SEGMENTS } from '../../../utils/constants';
import encode from '../../../utils/encode';
import { CurrentFolder, Folder, FolderMeta } from '../types';

/**
 * Disallow list
 */
export const skippedFolderNames = ['content', 'name'];

/**
 * Traverse into folder structure to get contents of current folder.
 * Also creates breadcrumb trail.
 */
const getCurrentFolder = (
  folderStructure: Folder,
  pathname: string
): CurrentFolder | null => {
  let currentFolder = folderStructure;
  const breadcrumbs: string[] = [folderStructure.name];
  const pathArr = pathname
    .slice(PATH_SEGMENTS.browse.length)
    .split('/')
    .filter(Boolean);

  // traverse into current folder
  try {
    let encodedFolderName: string | undefined;
    for (let i = 0; i < pathArr.length; i++) {
      encodedFolderName = encode(pathArr[i]);
      currentFolder = currentFolder[encodedFolderName];
      breadcrumbs.push(currentFolder.name);
    }
  } catch (error) {
    // e.g., if someone tries to directly navigate to a route that doesn't exist
    return null;
  }

  // get subfolders and their item counts
  const folders: FolderMeta[] = Object.keys(currentFolder)
    .filter(folderName => !skippedFolderNames.includes(folderName))
    .map(folderName => {
      const subFolders = Object.keys(currentFolder[folderName]).filter(
        subFolderName => !skippedFolderNames.includes(subFolderName)
      );

      return {
        name: currentFolder[folderName].name,
        itemCount: subFolders.length + currentFolder[folderName].content.length,
      };
    });

  return {
    breadcrumbs,
    folders: alphaSortByProperty(folders, 'name'),
    content: currentFolder.content,
  };
};

export default getCurrentFolder;
