import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CurrentFolder } from '../pages/BrowsePage/types';
import createFolderStructure from '../pages/BrowsePage/utils/createFolderStructure';
import getCurrentFolder from '../pages/BrowsePage/utils/getCurrentFolder';
import getCurrentFolderTags from '../pages/BrowsePage/utils/getCurrentFolderTags';
import getSortedContentArray from '../pages/BrowsePage/utils/getSortedContentArray';
import SORTBY from '../utils/sortBy';
import {
  selectContentArray,
  selectTagsMap,
} from '../state/content/content.slice';
import { PATH_SEGMENTS, REPLACE, SEARCHPARAM_KEYS } from '../utils/constants';
import filterContentArrayByTags from '../utils/filterContentArrayByTags';
import { SORT_OPTIONS } from '../types/sort.types';
import { useAppSelector } from './redux';

/**
 * Hook to get current folder contents (subfolders and content), breadcrumbs, and tags
 */
const useCurrentFolder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tagsMap = useAppSelector(selectTagsMap);
  const contentArray = useAppSelector(selectContentArray);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tags, setTags] = useState<string[]>([]);
  const [{ breadcrumbs, folders, content }, setCurrentFolder] = useState(
    {} as CurrentFolder
  );

  useEffect(() => {
    // sort param
    const sortSearchParam = searchParams.get(
      SEARCHPARAM_KEYS.SORT
    ) as SORT_OPTIONS;

    // tag params
    const tagSearchParams = searchParams.getAll(SEARCHPARAM_KEYS.TAG);

    // redirect if sort param or tag params are not valid
    const shouldRedirect =
      !SORTBY[sortSearchParam] || !tagSearchParams.every(tag => tagsMap[tag]);
    if (shouldRedirect) {
      return setSearchParams(
        { [SEARCHPARAM_KEYS.SORT]: SORT_OPTIONS.AZ },
        REPLACE
      );
    }

    // get sorted content
    const sortedContentArray = getSortedContentArray(
      sortSearchParam,
      contentArray
    );

    // filter sorted content
    const filteredContentArray = filterContentArrayByTags(
      sortedContentArray,
      tagSearchParams
    );

    // set folder structure
    const folderStructure = createFolderStructure(filteredContentArray);

    // get current folder
    const curFolder = getCurrentFolder(folderStructure, location.pathname);
    // if folder doesn't exist, then navigate back to browse
    if (!curFolder) {
      return navigate(`${PATH_SEGMENTS.browse}`, REPLACE);
    }

    // get current folder's tags
    const curFolderTags = getCurrentFolderTags(
      filteredContentArray,
      curFolder.breadcrumbs.slice(1).join(',')
    );

    setCurrentFolder(curFolder);
    setTags(curFolderTags);
  }, [
    contentArray,
    tagsMap,
    searchParams,
    setSearchParams,
    location.pathname,
    navigate,
  ]);

  return { breadcrumbs, tags, folders, content };
};

export default useCurrentFolder;
