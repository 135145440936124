import { WithObjectId } from '../../types';
import { Content } from '../../types/content.types';
import ItemsCount from '../ItemsCount/ItemsCount';
import SearchResultsMessage from '../SearchResultsMessage/SearchResultsMessage';
import DesktopSortAndFilterContainer from '../DesktopSortAndFilterContainer/DesktopSortAndFilterContainer';
import ContentCardsContainer from '../ContentCardsContainer/ContentCardsContainer';

const DesktopSearchResultsContainer = ({
  searchResults,
}: {
  searchResults: WithObjectId<Content>[];
}) => (
  <>
    <SearchResultsMessage />
    <div className='mb-4 grid grid-cols-2 items-end'>
      <ItemsCount count={searchResults.length} />
      <DesktopSortAndFilterContainer
        content={searchResults}
        isDropdownDisabled={false}
      />
    </div>

    {/* content */}
    <ContentCardsContainer content={searchResults} />
  </>
);

export default DesktopSearchResultsContainer;
