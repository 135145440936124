import { Link } from 'react-router-dom';
import cn from 'classnames';
import { CONTENT_TYPES } from '../../utils/constants';
import { preventDefaultOnNestedClick } from '../../utils/preventDefaultOnNestedClick';

export const contentCardClassNames =
  'overflow-hidden rounded-2xl bg-brand-white shadow-sm transition-shadow hover:shadow-lg';

export interface ContentCardWrapperProps {
  _id: string;
  type: string;
  to: string;
  children: React.ReactNode;
}

const ContentCardWrapper = ({
  _id,
  type,
  to,
  children,
}: ContentCardWrapperProps) => {
  return type === CONTENT_TYPES.APP ? (
    <div
      className={cn(contentCardClassNames, 'cursor-pointer')}
      data-appid={_id}
      data-testid='app-card'
      tabIndex={0}
      role='link'
    >
      {children}
    </div>
  ) : (
    <Link
      to={to}
      className={contentCardClassNames}
      onClick={preventDefaultOnNestedClick('[data-id]')}
      data-contentid={_id}
    >
      {children}
    </Link>
  );
};

export default ContentCardWrapper;
