import FAQs from '../components/FAQs/FAQs';

/**
 * FAQs page
 */
const FAQsPage = () => (
  <div className='mx-auto max-w-screen-md'>
    <FAQs />
  </div>
);

export default FAQsPage;
