import { FormEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import Icons from '../Icons/Icons';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import {
  EVENTS,
  FORM,
  KEYS,
  PATHS,
  SEARCHPARAM_KEYS,
  SEARCH_INPUT,
} from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectIsSearchSuggestionsOpen,
  toggleIsSearchSuggestionsOpen,
} from '../../state/widgets/widgets.slice';
import { SEARCH_SORT_OPTIONS } from '../../types/sort.types';

/**
 * Header searchbar
 */
const Searchbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isSearchSuggestionsOpen = useAppSelector(selectIsSearchSuggestionsOpen);

  // on navigation, clear input (to close search suggestions)
  useEffect(() => {
    setInputValue('');
  }, [pathname]);

  // focus on error
  useEffect(() => {
    hasError && inputRef.current?.focus();
  }, [hasError]);

  // toggle open and close search suggestions
  useEffect(() => {
    if (
      inputValue.length >= 2 &&
      document.activeElement === inputRef.current &&
      !isSearchSuggestionsOpen
    )
      dispatch(toggleIsSearchSuggestionsOpen(true));
    else if (inputValue.length < 2 && isSearchSuggestionsOpen)
      dispatch(toggleIsSearchSuggestionsOpen(false));
  }, [dispatch, inputValue, isSearchSuggestionsOpen]);

  // handle escape key press and click outside of form
  useEffect(() => {
    const blurInputOnEscapePress = (e: KeyboardEvent) => {
      if (e.key === KEYS.ESCAPE) {
        setInputValue('');
        inputRef.current?.blur();
      }
    };

    const handleClick = (e: MouseEvent) => {
      if ((e.target as HTMLElement).closest?.(FORM) === formRef.current) return;
      setInputValue('');
      setHasError(false);
    };

    window.addEventListener(EVENTS.KEYDOWN, blurInputOnEscapePress);
    window.addEventListener(EVENTS.CLICK, handleClick);

    return () => {
      window.removeEventListener(EVENTS.KEYDOWN, blurInputOnEscapePress);
      window.removeEventListener(EVENTS.CLICK, handleClick);
    };
  }, []);

  // form submit handler
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const trimmedInputValue = inputValue.trim();
    if (trimmedInputValue) {
      navigate(
        `${PATHS.searchResults}?${SEARCHPARAM_KEYS.QUERY}=${encodeURIComponent(
          trimmedInputValue
        )}&${SEARCHPARAM_KEYS.SORT}=${SEARCH_SORT_OPTIONS.RELEVANCE}`
      );
      inputRef.current?.blur();
    } else setHasError(true);
    setInputValue('');
  };

  const handleBlur = (e: SyntheticEvent<HTMLFormElement, FocusEvent>) => {
    // blur by tab or an element's `.blur` method
    const blurByTabOrMethod =
      e.nativeEvent.relatedTarget &&
      !(e.nativeEvent.relatedTarget as Element)?.closest?.(FORM);

    if (blurByTabOrMethod) {
      hasError && setHasError(false);
      setInputValue('');
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='relative'
      ref={formRef}
      onBlur={handleBlur}
    >
      <Icons.SearchIcon className='absolute left-8 top-1/2 -translate-x-1/2 -translate-y-1/2' />
      <input
        autoComplete='off'
        id={SEARCH_INPUT}
        ref={inputRef}
        role='searchbox'
        placeholder='Search for information'
        onChange={({ target }) => {
          hasError && setHasError(false);
          setInputValue(target.value);
        }}
        value={inputValue}
        className={cn(
          'w-full max-w-80 rounded-3xl border border-brand-grey bg-brand-tint-grey-1 px-14 py-2 xl:w-96 2xl:w-[32rem]',
          hasError && 'border-brand-red bg-brand-tint-red outline-brand-red'
        )}
      />
      <button
        ref={buttonRef}
        type='submit'
        title='Search'
        className='absolute right-8 top-1/2 -translate-y-1/2 translate-x-1/2 p-2'
      >
        <Icons.ArrowRightIcon />
      </button>
      {isSearchSuggestionsOpen && (
        <SearchSuggestions {...{ inputValue, inputRef }} />
      )}
    </form>
  );
};

export default Searchbar;
